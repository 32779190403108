import { Page } from '@/components';
import type { Address, Carmen } from '@/types';
import { type Client } from '@/types';

import { Form } from './_Form';

interface Props {
  client: Client;
  carmen: Carmen;
  api_key: string;
  address: Address;
}

export default function Edit({ client, carmen, api_key, address }: Props) {
  return (
    <Page title="Update Patient">
      <Form
        client={client}
        action={`/provider/clients/${client.id}`}
        carmen={carmen}
        apiKey={api_key}
        address={address}
      />
    </Page>
  );
}
