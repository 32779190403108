import { Select, Stack, TextInput } from '@mantine/core';
import type { Libraries } from '@react-google-maps/api';
import { useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, PlacesAutocomplete } from '@/components';
import type { CollectionSite } from '@/types';
const libraries: Libraries = ['places'];
import { COUNTRY } from '@/constants';

interface AddressDetails {
  locality: string;
  lat: number;
  lng: number;
  street: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
}

interface Props {
  collectionSite: CollectionSite;
  carmen: Record<
    string,
    {
      code: string;
      name: string;
    }[]
  >;
  api_key: string;
  errors: Record<string, string>;
  onChange: (value: CollectionSite) => void;
}

export function CollectionSiteAddress({
  collectionSite,
  carmen,
  api_key,
  errors,
  onChange,
}: Props) {
  const { t } = useTranslation();
  const { isLoaded } = useJsApiLoader({
    id: 'google-maps-script',
    googleMapsApiKey: api_key,
    libraries,
  });
  const [formState, setFormState] = useState<CollectionSite>(collectionSite);
  useEffect(() => {
    setFormState(collectionSite);
  }, [collectionSite]);

  const update = (field: keyof CollectionSite, value: string | number) => {
    const updatedState = {
      ...formState,
      [field]: value,
    };
    setFormState(updatedState);
    onChange(updatedState);
  };

  const handlePlaceChange = (details: AddressDetails) => {
    const updatedState = {
      ...formState,
      latitude: details.lat,
      longitude: details.lng,
      street1: details.street,
      street2: details.locality,
      city: details.city,
      postal_zip: details.postalCode,
    };
    setFormState(updatedState);
    onChange(updatedState);
  };

  return (
    <Stack>
      <Field label={t('collection_site.column.search_address')}>
        {isLoaded && <PlacesAutocomplete onChange={handlePlaceChange} />}
      </Field>
      <Field label={t('collection_site.column.street_address')} required>
        <Stack>
          <TextInput
            required
            value={formState.street1}
            onChange={e => {
              update('street1', e.target.value);
            }}
            error={errors.street1}
            placeholder="Street and number, P.O. box, c/o."
          />
          <TextInput
            value={formState.street2}
            onChange={e => {
              update('street2', e.target.value);
            }}
            error={errors.street2}
            placeholder="Apartment, suite, unit, building, floor, etc."
          />
        </Stack>
      </Field>
      <Field label={t('collection_site.column.city')} required>
        <TextInput
          required
          value={formState.city}
          onChange={e => {
            update('city', e.target.value);
          }}
          error={errors.city}
        />
      </Field>
      <Field label={t('collection_site.column.country')} required>
        <Select
          required
          name="country"
          value={formState.country}
          onChange={value => {
            update('country', value as string);
          }}
          data={COUNTRY}
          error={errors.country}
        />
      </Field>
      <Field label={t('collection_site.column.province_state')} required>
        <Select
          required
          name="province_state"
          value={formState.province_state}
          onChange={value => {
            update('province_state', value as string);
          }}
          data={
            carmen[formState.country]
              ? carmen[formState.country].map(item => ({
                  label: item.name,
                  value: item.code,
                }))
              : []
          }
          error={errors.province_state}
        />
      </Field>
      <Field label={t('collection_site.column.postal_zip_code')} required>
        <TextInput
          required
          value={formState.postal_zip}
          onChange={e => {
            update('postal_zip', e.target.value);
          }}
          error={errors.postal_zip}
        />
      </Field>
      <Field label={t('collection_site.column.latitude')}>
        <TextInput
          readOnly
          value={formState.latitude}
          onChange={e => {
            update('latitude', parseFloat(e.target.value));
          }}
          error={errors.latitude}
        />
      </Field>
      <Field label={t('collection_site.column.longitude')}>
        <TextInput
          readOnly
          value={formState.longitude}
          onChange={e => {
            update('longitude', parseFloat(e.target.value));
          }}
          error={errors.longitude}
        />
      </Field>
    </Stack>
  );
}
