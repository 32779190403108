import { Anchor } from '@mantine/core';

interface Props {
  value?: string;
}

export function FileField({ value }: Props) {
  return value != null && value !== '' ? (
    <Anchor href={value} download>
      Download
    </Anchor>
  ) : (
    <span>—</span>
  );
}
