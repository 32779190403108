import { Text, UnstyledButton } from '@mantine/core';
import { useState } from 'react';

interface Props {
  text: string;
  delimiter: string;
}

export function Reveal({ text, delimiter }: Props) {
  const texts = text.split(delimiter);

  const [reveal, setReveal] = useState(false);

  const toggle = () => {
    setReveal(!reveal);
  };

  return (
    <>
      {texts
        .filter((_, i) => i === 0 || reveal)
        .map((text, i) => (
          <Text key={i}>{text}</Text>
        ))}
      {texts.length > 1 && (
        <UnstyledButton onClick={toggle}>
          Show {reveal ? 'less' : 'more'}
        </UnstyledButton>
      )}
    </>
  );
}
