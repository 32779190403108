import type { PageProps } from '@inertiajs/core';
import { usePage } from '@inertiajs/react';

interface Pagination {
  from: number;
  to: number;
  prev_page: number | null;
  next_page: number | null;
  total_count: number;
}

interface Props extends PageProps {
  pagination?: Pagination;
}

export function usePagination(): Pagination | null {
  const page = usePage<Props>();

  if (page.props.pagination != null) {
    return page.props.pagination;
  }

  return null;
}
