import { Modal } from '@mantine/core';
import { useEffect, useState } from 'react';

import type { Biofunction } from '@/types';

import { BiofunctionCollapsibleCards } from './_BiofunctionCollapsibleCards';
import { BiomarkerDetails } from './_BiomarkerDetails';

interface Props {
  data: Biofunction;
  reportId: number;
}

export function BiofunctionDetailsMobile({ data, reportId }: Props) {
  const [open, setOpen] = useState(false);
  const [selectedBiomarkerId, setSelectedBiomarkerId] = useState<number | null>(
    null,
  );

  useEffect(() => {
    if (selectedBiomarkerId !== null) {
      setOpen(true);
    }
  }, [selectedBiomarkerId]);

  const handleBiomarkerSelect = (id: number) => {
    setSelectedBiomarkerId(id);
  };

  return (
    <>
      <BiofunctionCollapsibleCards
        data={data}
        handleSelect={(id: number) => handleBiomarkerSelect(id)}
        selected={selectedBiomarkerId}
      />

      <Modal opened={open} onClose={() => setOpen(false)}>
        {selectedBiomarkerId && (
          <BiomarkerDetails id={selectedBiomarkerId} reportId={reportId} />
        )}
      </Modal>
    </>
  );
}
