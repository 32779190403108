export const getBiomarkerRisk = (color: string): string => {
  switch (color) {
    case 'green':
      return 'Low risk';
    case 'yellow':
      return 'Medium risk';
    case 'red':
      return 'High risk';
    default:
      return 'N/A';
  }
};
