import { Link } from '@inertiajs/react';
import { Anchor, Badge, Button, Card, Menu } from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaCheck,
  FaEllipsisV,
  FaExclamationTriangle,
  FaRegEye,
} from 'react-icons/fa';
import { RiAddLine } from 'react-icons/ri';

import { DataGrid, Page, Pagination, Toolbar } from '@/components';
import { type Assay, type Client, type Report } from '@/types';

import { InviteClients } from './_InviteClients';

interface Props {
  clients: (Client & {
    assays?: (Assay & {
      report: Report;
    })[];
    current_report?: Report;
  })[];
}

export default function Index({ clients }: Props) {
  const { t } = useTranslation();

  const searchCols = [
    'first_name',
    'last_name',
    'email',
    'site_description',
    'identifier',
  ];

  const sortCols = ['first_name', 'last_name', 'identifier', 'email'];
  const [opened, setOpened] = useState(false);

  return (
    <Page
      title="Patients"
      actions={
        <Button
          leftSection={<RiAddLine />}
          onClick={() => {
            setOpened(true);
          }}
        >
          {t('common.action.invite')} {t('patient.singular')}
        </Button>
      }
    >
      <InviteClients
        opened={opened}
        close={() => {
          setOpened(false);
        }}
      />
      <Card>
        <Card.Section>
          <Toolbar sortCols={sortCols} searchCols={searchCols} />
          <DataGrid
            data={clients}
            columns={[
              {
                header: t('client.column.identifier'),
                cell: ({ row }) => (
                  <Anchor
                    component={Link}
                    href={`/provider/clients/${row.original.id}`}
                  >
                    {row.original.identifier}
                  </Anchor>
                ),
              },
              {
                header: t('user.column.first_name'),
                accessorFn: row => row.first_name,
              },
              {
                header: t('user.column.last_name'),
                accessorFn: row => row.last_name,
              },
              {
                header: t('user.column.email'),
                accessorFn: row => row.email,
              },
              {
                header: t('user.column.invitation_accepted'),
                cell: ({ row }) => (
                  <>
                    {row.original.invitation_accepted_at != null ? (
                      <div>
                        <FaCheck style={{ color: 'lightblue' }} size={25} />
                      </div>
                    ) : (
                      <div style={{ color: 'orange' }}>
                        <FaExclamationTriangle style={{ marginRight: '5px' }} />
                        Pending
                      </div>
                    )}
                  </>
                ),
              },
              {
                header: t('questionnaire.singular'),
                cell: ({ row }) => (
                  <Badge
                    color={
                      row.original.primary_questionnaire != null
                        ? 'green'
                        : 'yellow'
                    }
                  >
                    {row.original.primary_questionnaire != null
                      ? 'Complete'
                      : 'Incomplete'}
                  </Badge>
                ),
              },
              {
                header: t('client.column.share_with_provider'),
                cell: ({ row }) => (
                  <Badge
                    color={
                      row.original.share_with_provider ? 'green' : 'yellow'
                    }
                  >
                    {row.original.share_with_provider
                      ? 'Consented'
                      : 'Not Consented'}
                  </Badge>
                ),
              },
              {
                header: t('report.column.released_to_client_at'),
                accessorFn: row => row.current_report?.released_to_client_at,
              },
              {
                header: t('report.column.latest_report_status'),
                cell: ({ row }) => {
                  const hasProviderReview = row.original.assays?.some(
                    assay => assay.report?.state === 'provider_review',
                  );
                  const hasReleasedReport = row.original.assays?.some(
                    assay => assay.report?.state === 'released',
                  );
                  if (hasProviderReview === true) {
                    return (
                      <div style={{ color: 'orange' }}>
                        <FaExclamationTriangle style={{ marginRight: '5px' }} />
                        {t('client.column.report_held')}
                      </div>
                    );
                  } else if (hasReleasedReport === true) {
                    return <FaCheck style={{ color: 'lightblue' }} size={25} />;
                  } else {
                    return '---';
                  }
                },
              },
              {
                id: 'actions',
                cell: ({ row }) => (
                  <Menu>
                    <Menu.Target>
                      <span>
                        <FaEllipsisV />
                      </span>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        leftSection={<FaRegEye />}
                        component={Link}
                        href={`/provider/clients/${row.original.id}`}
                      >
                        {t('common.action.show')}
                      </Menu.Item>
                      {row.original.invitation_accepted_at === null && (
                        <Menu.Item
                          component={Link}
                          as="button"
                          href={`/provider/clients/${row.original.id}/invites`}
                          method="post"
                          onClick={e => {
                            if (
                              !confirm(
                                t('provider.confirm.resend_invite', {
                                  name: `${row.original.first_name} ${row.original.last_name}`,
                                }),
                              )
                            ) {
                              e.preventDefault();
                            }
                          }}
                        >
                          {t('user.action.resend_invitation')}
                        </Menu.Item>
                      )}
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
          <Pagination />
        </Card.Section>
      </Card>
    </Page>
  );
}
