import { sortBy } from 'lodash/fp';

import type { HealthArea } from '@/types';

export const sortHealthAreas = (sort: string, list: HealthArea[]) => {
  switch (sort) {
    case 'alphabetically':
      return sortBy([row => row.name.toLowerCase()], list);
    case 'highest_score':
      return list.toReversed();
    default:
      return list;
  }
};
