import { Link, useForm } from '@inertiajs/react';
import {
  Button,
  Card,
  Group,
  Input,
  MultiSelect,
  Radio,
  Stack,
  Text,
} from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import type { Provider, Site } from '@/types';
import { formatDate } from '@/utils';

import { PrivilegeSetInput } from './_PrivilegeSetInput';

interface Props {
  provider?: Provider;
  sites: Site[];
}

export function Form({ provider, sites }: Props) {
  const { t } = useTranslation();

  const { data, setData, submit, transform, errors } = useForm(provider);

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    submit(
      data.id != null ? 'patch' : 'post',
      `/provider/providers/${data.id != null ? data.id : ''}`,
    );
  };

  transform(({ provider_sites: providerSites, ...data }) => ({
    provider_sites_attributes: providerSites,
    ...data,
  }));

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section>
          <Field label={t('site.plural')}>
            <MultiSelect
              required
              value={
                data.provider_sites != null
                  ? data.provider_sites
                      .filter(site => site._destroy == null)
                      .map(site => site.site_id.toString())
                  : []
              }
              hidePickedOptions
              onChange={value => {
                setData('provider_sites', [
                  ...(data.provider_sites?.reduce(
                    (sites, { _destroy, ...site }) => {
                      if (
                        value.includes(site.site_id.toString()) &&
                        _destroy != null
                      ) {
                        return [...sites, site];
                      } else if (
                        !value.includes(site.site_id.toString()) &&
                        site.id != null
                      ) {
                        return [...sites, { ...site, _destroy: true }];
                      } else if (!value.includes(site.site_id.toString())) {
                        return sites;
                      }
                      return [...sites, { ...site, _destroy }];
                    },
                    [],
                  ) ?? []),
                  ...(value.reduce((sites, siteId) => {
                    if (
                      data.provider_sites == null ||
                      !data.provider_sites?.some(
                        site => site.site_id.toString() === siteId,
                      )
                    ) {
                      return [...sites, { site_id: siteId }];
                    }
                    return sites;
                  }, []) ?? []),
                ]);
              }}
              data={sites.map(site => ({
                value: site.id.toString(),
                label: site.description,
              }))}
            />
          </Field>
          <Field label={t('user.column.email')}>
            <Input
              required
              name="email"
              value={data.email != null ? data.email : ''}
              onChange={e => {
                setData('email', e.target.value);
              }}
              error={errors.email}
            />
          </Field>
          <Field label={t('user.column.first_name')}>
            <Input
              required
              name="first_name"
              value={data.first_name != null ? data.first_name : ''}
              onChange={e => {
                setData('first_name', e.target.value);
              }}
              error={errors.first_name}
            />
          </Field>
          <Field label={t('user.column.last_name')}>
            <Input
              required
              name="last_name"
              value={data.last_name != null ? data.last_name : ''}
              onChange={e => {
                setData('last_name', e.target.value);
              }}
              error={errors.last_name}
            />
          </Field>
          {data.id != null && (
            <Field label={t('client.column.invitation')}>
              {data.invitation_accepted_at !== null ? (
                <Text>
                  Accepted on {formatDate(data.invitation_accepted_at)}
                </Text>
              ) : (
                <>
                  {data.invitation_sent_at !== null && (
                    <Text>
                      Invitation sent on {formatDate(data.invitation_sent_at)}
                    </Text>
                  )}
                  <Button
                    component={Link}
                    as="button"
                    method="post"
                    href={`/provider/provider_invites?provider_id=${data.id}`}
                    onClick={e => {
                      if (
                        !confirm(
                          t('provider.confirm.resend_invite', {
                            name: `${data.first_name} ${data.last_name}`,
                          }),
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                  >
                    {t('user.action.resend_invitation')}
                  </Button>
                </>
              )}
            </Field>
          )}
          <Field label={t('user.column.role')}>
            <Radio.Group
              name="role"
              value={data.role}
              onChange={value => {
                setData('role', value);
              }}
              error={errors.role}
            >
              <Stack>
                {['administrator', 'practitioner', 'coach'].map(role => (
                  <Radio
                    key={role}
                    value={role}
                    label={
                      <div>
                        <Text fw={600}>{t(`provider.role.${role}.label`)}</Text>
                        <Text size="sm">
                          {t(`provider.role.${role}.description`)}
                        </Text>
                      </div>
                    }
                  />
                ))}
                <Radio
                  value="staff"
                  label={
                    <div>
                      <Text fw={600}>Restricted Access</Text>
                      <Text size="sm">
                        {t('provider.role.staff.description')}
                      </Text>
                    </div>
                  }
                />
              </Stack>
            </Radio.Group>
          </Field>
          {data.role === 'staff' && (
            <Field label={t('privilege_set.singular')} error={errors.role}>
              <PrivilegeSetInput
                value={data.privilege_set_attributes}
                onChange={({ name, value }) => {
                  setData({
                    ...data,
                    privilege_set_attributes: {
                      ...data.privilege_set_attributes,
                      [name]: value,
                    },
                  });
                }}
                options={[
                  'change_assays',
                  'change_clients',
                  'change_providers',
                  'change_site',
                  'change_health_profiles',
                  'change_reports',
                ]}
              />
            </Field>
          )}
        </Card.Section>
        <Card.Section>
          <Group justify="end" p="md">
            <Link href="/provider/providers">
              <Button color="black">{t('common.action.cancel')}</Button>
            </Link>
            <Button type="submit">{t('common.action.save')}</Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
