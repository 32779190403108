import { Link } from '@inertiajs/react';
import { Button, Card, Menu } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaEllipsisV } from 'react-icons/fa';

import { DataGrid, Page, Pagination, Toolbar } from '@/components';
import type { Address, KitBatch, Site } from '@/types';
import { formatDate } from '@/utils';

interface Props {
  kit_batches: (KitBatch & {
    site: Site & {
      addresses: Address[];
    };
  })[];
}

export default function Index(props: Props) {
  const { t } = useTranslation();

  const searchCols = ['shipped_by', 'assembled_by', 'component_batch_name'];

  const ADDRESS_TYPE = {
    SHIPPING: 'shipping',
  };

  return (
    <Page
      title="Kit Batches"
      actions={
        <Button
          component={Link}
          href={`/admin/component_batches/new?batch_type=${'kit_batch'}`}
        >
          Create Kit Batch
        </Button>
      }
    >
      <Card>
        <Card.Section>
          <Toolbar searchCols={searchCols} />
          <DataGrid
            data={props.kit_batches}
            columns={[
              {
                header: t('kit_batch.column.name'),
                accessorFn: row => row.name,
              },
              {
                header: t('kit_batch.column.date_assembled'),
                accessorFn: row => formatDate(row.date_assembled),
              },
              {
                header: t('kit_batch.column.expiry_date'),
                accessorFn: row => formatDate(row.expiry_date),
              },
              {
                header: t('kit_batch.column.created_by'),
                accessorFn: row => row.assembled_by,
              },
              {
                header: t('kit_batch.column.shipped_by'),
                accessorFn: row => row.shipped_by,
              },
              {
                header: t('site.column.shipping_address'),
                accessorFn: row =>
                  row.site?.addresses?.find(
                    address => address.address_type === ADDRESS_TYPE.SHIPPING,
                  )?.full_address,
              },
              {
                id: 'actions',
                cell: ({ row }) => (
                  <Menu>
                    <Menu.Target>
                      <span>
                        <FaEllipsisV />
                      </span>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        component={Link}
                        href={`/admin/component_batches/${row.original.id}/edit?batch_type=${row.original.batch_type}`}
                      >
                        {t('common.action.edit')}
                      </Menu.Item>
                      <Menu.Item
                        component={Link}
                        href={`/admin/component_batches/${row.original.id}?batch_type=${row.original.batch_type}`}
                      >
                        {t('common.action.show')}
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
          <Pagination />
        </Card.Section>
      </Card>
    </Page>
  );
}
