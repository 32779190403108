import { useForm as useInertiaForm } from '@inertiajs/react';
import { get, getOr, set } from 'lodash/fp';

import { type Paths, type PathValue } from '@/types';

export const useForm = <T extends object>(initialValues?: Partial<T>) => {
  const form = useInertiaForm(initialValues);

  const setPath = <P extends Paths<T>>(path: P, value: PathValue<T, P>) =>
    form.setData(data => set(path, value, data));

  const getPath = <P extends Paths<T>>(
    path: Paths<T>,
    defaultValue?: PathValue<T, P>,
  ) => getOr(defaultValue, path, form.data) ?? defaultValue;

  const getError = (path: Paths<T>) => get(path, form.errors);

  return {
    ...form,
    setPath,
    getPath,
    getError,
  };
};
