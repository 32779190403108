import { Group, Stack, Text, Title } from '@mantine/core';
import type { PropsWithChildren, ReactNode } from 'react';

interface Props extends PropsWithChildren {
  title: string;
  subtitle?: string;
  actions?: ReactNode;
}

export function Page({ title, subtitle, actions, children }: Props) {
  return (
    <Stack gap="xl">
      <Group justify="space-between">
        <Stack gap="sm">
          <Title size="h2">{title}</Title>
          {subtitle && (
            <Text c="dimmed" size="md">
              {subtitle}
            </Text>
          )}
        </Stack>
        {actions}
      </Group>
      {children}
    </Stack>
  );
}
