import { Card, Container, Stack, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { RiDownloadLine } from 'react-icons/ri';

import { Empty, ListAction } from '@/components';
import { client_tests_index_path } from '@/routes';
import type { ReportSummary } from '@/types';
import { formatDate } from '@/utils';

interface Props {
  report_summaries: ReportSummary[];
}

export default function Index({ report_summaries: reportSummaries }: Props) {
  const { t } = useTranslation();

  return (
    <Container size="sm">
      <Stack gap="xl">
        <Stack gap="xs">
          <Title size="h2">{t('report_summary.headline')}</Title>
          <Text>{t('report_summary.strapline')}</Text>
        </Stack>
        {reportSummaries.length > 0 ? (
          <Card>
            <Card.Section>
              {reportSummaries.map(reportSummary => (
                <ListAction
                  key={reportSummary.id}
                  rightSection={<RiDownloadLine />}
                  component="a"
                  href={reportSummary.summary_url}
                  download
                >
                  <Text>
                    {formatDate(reportSummary.created_at, 'MMMM YYYY')}
                  </Text>
                </ListAction>
              ))}
            </Card.Section>
          </Card>
        ) : (
          <Empty
            headline={t('report.no_data.headline')}
            strapline={t('report.no_data.strapline')}
            href={client_tests_index_path()}
          />
        )}
      </Stack>
    </Container>
  );
}
