import { Page } from '@/components';
import type { Address, Carmen } from '@/types';
import { type Client } from '@/types';

import { Form } from './_Form';

interface Props {
  client: Client;
  address: Address;
  carmen: Carmen;
  api_key: string;
}

export default function New({ client, carmen, api_key, address }: Props) {
  return (
    <Page title="Create Patient">
      <Form
        client={client}
        carmen={carmen}
        action="/provider/clients"
        apiKey={api_key}
        address={address}
      />
    </Page>
  );
}
