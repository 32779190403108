import { getMetaContent } from './getMetaContent';
type Headers = Record<string, string>;

export function getPostHeaders(): Headers {
  const csrfToken = getMetaContent('csrf-token');
  return {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': csrfToken ?? '',
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
}
