import { Box } from '@mantine/core';

import classes from './HealthAreaScore.module.css';

interface Props {
  color: string;
  score: number;
}

export function Score({ color, score }: Props) {
  return (
    <Box data-color={color} className={classes.root}>
      {Math.round(score)}
    </Box>
  );
}
