import { Anchor, Card, Stack, Table } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import type { CollectionSite, CollectionSiteGroup } from '@/types';

interface Props {
  collectionSiteGroup: CollectionSiteGroup & {
    collection_sites: CollectionSite[];
  };
}

export function Details({ collectionSiteGroup }: Props) {
  const { t } = useTranslation();
  return (
    <Stack gap="xl">
      <Card>
        <Card.Section>
          <Field label={t('collection_site_group.column.name')}>
            {collectionSiteGroup.name}
          </Field>
          <Field label={t('collection_site.plural')}>
            {collectionSiteGroup.collection_sites.length}
          </Field>
        </Card.Section>
      </Card>
      <Card>
        <Card.Section>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{t('collection_site.column.name')}</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {collectionSiteGroup.collection_sites.map(collectionSite => (
                <Table.Tr key={collectionSite.id}>
                  <Table.Td>
                    <Anchor
                      href={`/admin/collection_sites/${collectionSite.id}`}
                    >
                      {collectionSite.name}
                    </Anchor>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Card.Section>
      </Card>
    </Stack>
  );
}
