import { usePage } from '@inertiajs/react';
import { Alert } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaExclamationTriangle } from 'react-icons/fa';

interface PageProps {
  client: {
    id: number;
    first_name: string;
    assays: {
      id: number;
      report?: {
        id: number;
        state: string;
      };
    }[];
  };
}

export function ReportStatus() {
  const { client } = usePage<PageProps>().props;
  const { t } = useTranslation();
  const isReportPending = client.assays.some(
    assay => assay.report != null && assay.report.state === 'provider_review',
  );
  const icon = <FaExclamationTriangle size={25} />;
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {isReportPending && (
        <Alert variant="light" color="yellow" icon={icon}>
          {t('provider.alert.report_pending', { name: client.first_name })}
        </Alert>
      )}
    </div>
  );
}
