import type { Color } from '@/types';

export const getColor = (color: Color): string => {
  switch (color) {
    case 'green':
      return '#3FB6DC';
    case 'yellow':
      return '#FFC800';
    case 'red':
      return '#CF3616';
    default:
      return 'gray';
  }
};
