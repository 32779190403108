import { Alert } from '@mantine/core';

import { useFlash } from '@/hooks';

export function Flash() {
  const flash = useFlash();

  return (
    <>
      {flash?.error != null && <Alert color="red">{flash.error}</Alert>}
      {flash?.alert != null && <Alert color="yellow">{flash.alert}</Alert>}
      {flash?.notice != null && <Alert color="green">{flash.notice}</Alert>}
    </>
  );
}
