import { useAmplitudeKey, useUser } from '@/hooks';
import type { Provider } from '@/types';
import { trackEvent } from '@/utils';

type TrackClientEvent = (event: string, data: Record<string, string>) => void;

export const useTrackProviderEvent = (): TrackClientEvent => {
  const amplitudeKey = useAmplitudeKey();

  const provider = useUser<Provider>();

  return (event: string, data: Record<string, string> = {}) => {
    if (amplitudeKey != null && provider != null) {
      trackEvent(
        amplitudeKey,
        provider.email,
        {
          provider_ID: provider.id,
          name: `${provider.last_name} ${provider.first_name}`,
          role: provider.role ?? '',
          site: provider.site_description ?? '',
        },
        event,
        data,
      );
    }
  };
};
