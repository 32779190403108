import { Link } from '@inertiajs/react';
import { Button, Card, Menu } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaEllipsisV, FaExclamationTriangle } from 'react-icons/fa';

import { DataGrid, Page, Pagination, Toolbar } from '@/components';
import type { Vendor, VendorRepresentative } from '@/types';
import { formatDate } from '@/utils';

interface Props {
  vendor_representatives: (VendorRepresentative & {
    vendor?: Vendor;
  })[];
}

function Index(props: Props) {
  const { t } = useTranslation();

  const searchCols = ['first_name', 'last_name', 'email'];

  return (
    <Page
      title="Vendor Representatives"
      actions={
        <Button component={Link} href="/admin/vendor_representatives/new">
          Create Vendor Representative
        </Button>
      }
    >
      <Card>
        <Card.Section>
          <Toolbar searchCols={searchCols} />
          <DataGrid
            data={props.vendor_representatives}
            columns={[
              {
                header: t('common.column.id'),
                accessorFn: row => row.id,
              },
              {
                header: t('user.column.first_name'),
                accessorFn: row => row.first_name,
              },
              {
                header: t('user.column.last_name'),
                accessorFn: row => row.last_name,
              },
              {
                header: t('user.column.email'),
                accessorFn: row => row.email,
              },
              {
                header: t('vendor.singular'),
                accessorFn: row => row.vendor?.name,
              },
              {
                header: t('user.column.invitation_accepted'),
                cell: ({ row }) => (
                  <>
                    {row.original.invitation_accepted_at != null ? (
                      <div>
                        <FaCheck style={{ color: 'lightblue' }} size={25} />
                      </div>
                    ) : (
                      <div style={{ color: 'orange' }}>
                        <FaExclamationTriangle style={{ marginRight: '5px' }} />
                        Pending
                      </div>
                    )}
                  </>
                ),
              },
              {
                header: t('common.column.created_at'),
                accessorFn: row => formatDate(row.created_at),
              },
              {
                header: t('common.column.updated_at'),
                accessorFn: row => formatDate(row.updated_at),
              },
              {
                id: 'actions',
                cell: ({ row }) => (
                  <Menu>
                    <Menu.Target>
                      <span>
                        <FaEllipsisV />
                      </span>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        component={Link}
                        href={`/admin/vendor_representatives/${row.original.id}/edit`}
                      >
                        {t('common.action.edit')}
                      </Menu.Item>
                      <Menu.Item
                        component={Link}
                        method="delete"
                        as="button"
                        href={`/admin/vendor_representatives/${row.original.id}`}
                      >
                        {t('common.action.delete')}
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
          <Pagination />
        </Card.Section>
      </Card>
    </Page>
  );
}

export default Index;
