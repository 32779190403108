import { useForm } from '@inertiajs/react';
import { Button, Card, Group, Radio, Select, TextInput } from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, PrivilegeSetInput } from '@/components';
import { ADMIN_PRIVILEGE_SET, ADMIN_ROLE, USER_STATUS } from '@/constants';
import { usePermissions } from '@/hooks';
import type { Admin, PrivilegeSet } from '@/types';
import { nestedAttributes } from '@/utils';

interface Props {
  admin: Admin & {
    privilege_set: PrivilegeSet;
  };
}

export function Form({ admin }: Props) {
  const { t } = useTranslation();

  const { hasPrivilege } = usePermissions();

  const { data, setData, submit, transform, errors } = useForm(admin);

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    submit(
      admin.id != null ? 'patch' : 'post',
      `/admin/admins/${admin.id ?? ''}`,
    );
  };

  // Rename `privilege_set` to `privilege_set_attributes` before submitting.
  transform(nestedAttributes(['privilege_set']));

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section>
          <Field label={t('user.column.first_name')}>
            <TextInput
              required
              value={data.first_name}
              onChange={e => {
                setData('first_name', e.target.value);
              }}
              error={errors.first_name}
            />
          </Field>
          <Field label={t('user.column.last_name')}>
            <TextInput
              required
              value={data.last_name}
              onChange={e => {
                setData('last_name', e.target.value);
              }}
              error={errors.last_name}
            />
          </Field>
          <Field label={t('user.column.email')}>
            <TextInput
              required
              value={data.email}
              onChange={e => {
                setData('email', e.target.value);
              }}
              error={errors.email}
            />
          </Field>
          <Field label="Email Confirmation">
            <TextInput
              value={data.email_confirmation}
              onChange={e => {
                setData('email_confirmation', e.target.value);
              }}
              error={errors.email}
            />
          </Field>
          <Field label={t('user.column.status')}>
            <Radio.Group
              name="sex"
              value={data.status}
              onChange={value => {
                setData('status', value);
              }}
              error={errors.status}
            >
              <Group>
                {USER_STATUS.map(status => (
                  <Radio
                    key={status}
                    value={status}
                    label={t(`user.status.${status}`)}
                  />
                ))}
              </Group>
            </Radio.Group>
          </Field>
          <Field label={t('user.column.role')}>
            <Select
              name="role"
              value={data.role}
              onChange={value => {
                setData('role', value as string);
              }}
              data={ADMIN_ROLE.map(role => ({
                value: role,
                label: t(`admin.role.${role}`),
              }))}
              error={errors.role}
            />
          </Field>
          <Field label="Privilege Set">
            <PrivilegeSetInput
              value={data.privilege_set}
              onChange={value => {
                setData('privilege_set', value);
              }}
              data={ADMIN_PRIVILEGE_SET.filter(hasPrivilege).map(privilege => ({
                value: privilege,
                label: t(`admin.privilege.${privilege}`),
              }))}
            />
          </Field>
        </Card.Section>
        <Card.Section>
          <Group p="md" justify="end">
            <Button type="submit">{t('common.action.save')}</Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
