import { Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getBiofunctionDetails } from '@/api/client/getBiofunctionDetails';
import type { Biofunction } from '@/types';

import { BiofunctionDetailsMobile } from './_BiofunctionDetailsMobile';
import { BiofunctionDetailsWeb } from './_BiofunctionDetailsWeb';

interface Props {
  biofunction: Biofunction;
  reportId: number;
}

export function BiofunctionDetails({ biofunction, reportId }: Props) {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { t } = useTranslation();
  const [selected, setSelected] = useState<number | null>(null);

  const biofunctionDetails = useQuery(
    ['biofunctionDetails', biofunction.id],
    async () => await getBiofunctionDetails({ id: biofunction.id }),
    {
      initialData: undefined,
    },
  );

  const { data, isFetching, isSuccess } = biofunctionDetails;

  useEffect(() => {
    if (biofunctionDetails.data?.health_report_sample_concentrations?.length) {
      setSelected(
        biofunctionDetails.data.health_report_sample_concentrations[0].id,
      );
    }
  }, [data]);

  const handleSelect = (id: number) => {
    setSelected(id);
  };

  if (isFetching) {
    return (
      <Text ta="center" p="xl">
        {t('common.loading')}
      </Text>
    );
  }

  if (isSuccess) {
    return isMobile ? (
      <BiofunctionDetailsMobile data={data} reportId={reportId} />
    ) : (
      <BiofunctionDetailsWeb
        data={data}
        selected={selected}
        handleSelect={handleSelect}
        reportId={reportId}
      />
    );
  }

  return (
    <Text p="xl" ta="center">
      {t('biofunctions.not_found')}
    </Text>
  );
}
