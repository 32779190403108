import { Link } from '@inertiajs/react';
import { Button, Card, Container, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Back } from '@/components';
import {
  client_account_index_path,
  client_account_request_deletion_path,
} from '@/routes';

export default function Deletion() {
  const { t } = useTranslation();

  return (
    <Container size="xs">
      <Stack gap="xl">
        <Back
          label={t('account.delete.title')}
          to={client_account_index_path()}
        />
        <Card padding="xl">
          <Stack gap="lg">
            <Text>{t('account.delete.description')}</Text>
            <Button
              color="red"
              type="submit"
              component={Link}
              href={client_account_request_deletion_path()}
              method="post"
              as="button"
            >
              {t('account.delete.cta')}
            </Button>
          </Stack>
        </Card>
      </Stack>
    </Container>
  );
}
