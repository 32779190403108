import { Page } from '@/components';
import type { Address, Carmen, Client, Site } from '@/types';

import { Form } from './_Form';

interface Props {
  client: Client & {
    addresses: Address[];
  };
  sites: Site[];
  carmen: Carmen;
  address: Address[];
}

export default function New(props: Props) {
  return (
    <Page title="Create Client">
      <Form
        client={props.client}
        sites={props.sites}
        carmen={props.carmen}
        address={props.client.addresses}
      />
    </Page>
  );
}
