import axios from 'axios';

import type { Credentials, Measure, SampleConcentration } from '@/types';
import { getPostHeaders } from '@/utils';

interface Params {
  reportId: number;
  mycoId?: string;
}

export const getSampleConcentrations = async (
  { reportId, mycoId }: Params,
  { email, apiKey }: Credentials,
): Promise<
  (SampleConcentration & {
    biomarker_table: (Measure | null)[];
  })[]
> =>
  await axios
    .get(`${window.location.origin}/provider/api/sample_concentrations`, {
      headers: {
        ...getPostHeaders(),
        Authorization: `Basic ${btoa(`${email}:${apiKey}`)}`,
      },
      params: {
        report_id: reportId,
        health_area_myco_id: mycoId,
        fields: [
          'measure_name',
          'percentile',
          'range',
          'delta',
          'collection_date',
          'value',
          'unit',
          'level',
          'color',
          'indicated',
          'associated_health_areas',
          'score',
          'text_range',
          'normal_description',
          'nutrition_description',
          'biomarker_table',
        ].join(','),
      },
    })
    .then(
      ({ data }) =>
        data.sample_concentrations as (SampleConcentration & {
          biomarker_table: (Measure | null)[];
        })[],
    );
