import { Link } from '@inertiajs/react';
import {
  Anchor,
  Button,
  Card as MantineCard,
  Divider,
  Group,
  Text,
} from '@mantine/core';
import type { ReactNode } from 'react';
import { FaPen } from 'react-icons/fa';

interface Props {
  title: string;
  editLink?: string;
  buttonLabel?: string;
  buttonLink?: string;
  component: ReactNode;
}

export function Card({
  title,
  editLink,
  buttonLabel,
  buttonLink,
  component,
}: Props) {
  return (
    <MantineCard shadow="xs" padding={0} withBorder>
      <Group justify="space-between" p="md">
        <Text fw={700}>{title}</Text>
        {editLink != null && ( // Show edit icon only if editLink is provided
          <Anchor component={Link} href={editLink}>
            <FaPen size={16} />
          </Anchor>
        )}
        {buttonLink != null && ( // Show button only if buttonLink is provided
          <Button size="xs" component={Link} href={buttonLink}>
            {buttonLabel}
          </Button>
        )}
      </Group>
      <Divider />
      {component}
    </MantineCard>
  );
}
