import { useForm } from '@inertiajs/react';
import { Button, Card, Group, Text } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FiFileText, FiUpload } from 'react-icons/fi';

import { Field } from '@/components';
import type { Batch } from '@/types';

interface Props {
  batch?: Batch;
}

interface Form {
  batch: {
    vendor_report: File | null;
  };
}

export function VendorReport({ batch }: Props) {
  const { t } = useTranslation();

  const { data, setData, patch, processing, errors } = useForm<Form>({
    batch: {
      vendor_report: null,
    },
  });

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    if (batch?.id != null) {
      patch(`/vendor_representative/batches/${batch.id}`, {
        preserveScroll: page => page.props.errors != null,
      });
    }
  };

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section p="md" withBorder>
          {t('batch.column.vendor_report')}
        </Card.Section>
        <Card.Section>
          <Field
            label={t('batch.column.vendor_report')}
            hint={t('batch.hint.vendor_report')}
            error={errors.vendor_report}
          >
            <Dropzone
              name="vendor_report"
              onDrop={file => {
                setData('batch', { vendor_report: file[0] });
              }}
              disabled={processing}
              multiple={false}
            >
              <Group
                justify="center"
                gap="xl"
                mih={180}
                style={{ pointerEvents: 'none' }}
              >
                <Dropzone.Accept>
                  <FiUpload size={52} />
                </Dropzone.Accept>
                <Dropzone.Idle>
                  <FiFileText size={52} />
                </Dropzone.Idle>
                <div>
                  <Text size="xl">
                    Drag and drop the report file here or click to upload
                  </Text>
                  <Text size="sm" c="dimmed" inline mt={7}>
                    {data.batch.vendor_report?.name ??
                      batch.vendor_report_file_name}
                  </Text>
                </div>
              </Group>
            </Dropzone>
          </Field>
        </Card.Section>
        <Card.Section>
          <Group justify="end" p="md">
            <Button
              disabled={data.batch.vendor_report == null || processing}
              type="submit"
            >
              {t('common.action.upload')}
            </Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
