import { router } from '@inertiajs/react';
import { Select } from '@mantine/core';

import { useQueryParams } from '@/hooks';

export function State() {
  const queryParams = useQueryParams();

  return (
    <Select
      w={140}
      placeholder="State"
      value={queryParams.get('state')}
      onChange={value => {
        router.visit('', { data: { state: value } });
      }}
      data={[
        { value: '', label: 'All' },
        { value: 'in_progress', label: 'In Progress' },
        { value: 'released', label: 'Released' },
        { value: 'cancelled', label: 'Cancelled' },
      ]}
    />
  );
}
