import axios from 'axios';

import type { Credentials, History } from '@/types';
import { getPostHeaders } from '@/utils';

interface Params {
  clientId: number;
  mycoId: string;
}

export const getHealthAreaHistory = async (
  { clientId, mycoId }: Params,
  { email, apiKey }: Credentials,
): Promise<History[]> =>
  await axios
    .get(
      `${window.location.origin}/provider/api/clients/${clientId}/health_data_overview/${mycoId}`,
      {
        headers: {
          ...getPostHeaders(),
          Authorization: `Basic ${btoa(`${email}:${apiKey}`)}`,
        },
      },
    )
    .then(({ data }) => data.results);
