import { Page } from '@/components';
import type { Batch, Sample } from '@/types';

import { Details } from './_Details';
import { Samples } from './_Samples';

interface Props {
  batch: Batch;
  samples: Sample[];
}

export default function Show(props: Props) {
  return (
    <Page title="Batch Details">
      <Details batch={props.batch} />
      <Samples batch={props.batch} samples={props.samples} />
    </Page>
  );
}
