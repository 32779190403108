import { useForm } from '@inertiajs/react';
import {
  Autocomplete,
  Button,
  Card,
  FileInput,
  NumberInput,
  Select,
  Textarea,
  TextInput,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import {
  BATCH_AMOUNT_UNIT,
  BATCH_PROFILE,
  BATCH_VENDOR_ASSAY,
} from '@/constants';
import type { Batch, Vendor } from '@/types';
import { resetTime } from '@/utils';

interface Props {
  batch: Batch & {
    vendor?: Vendor;
  };
  vendors: Vendor[];
}

export function Form({ batch, vendors }: Props) {
  const { t } = useTranslation();

  const { data, setData, submit, processing, errors } = useForm({
    ...batch,
    batch_type: 'Submission',
  });

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    submit(
      data.id != null ? 'patch' : 'post',
      `/admin/batches/${data.id != null ? data.id : ''}`,
    );
  };

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section>
          <Field label={t('batch.column.name')} required>
            <TextInput
              name="name"
              value={data.name}
              onChange={e => {
                setData('name', e.target.value);
              }}
              error={errors.name}
              required
            />
          </Field>
          <Field label={t('vendor.singular')} required>
            <Autocomplete
              name="vendor_id"
              defaultValue={data.vendor?.name}
              onOptionSubmit={value => {
                setData('vendor_id', Number(value));
              }}
              error={errors.vendor_id}
              data={vendors.map(vendor => ({
                value: vendor.id.toString(),
                label: vendor.name,
              }))}
              required
            />
          </Field>
          <Field label={t('batch.column.vendor_batch_number')}>
            <TextInput
              name="vendor_batch_number"
              value={data.vendor_batch_number}
              onChange={e => {
                setData('vendor_batch_number', e.target.value);
              }}
              error={errors.vendor_batch_number}
            />
          </Field>
          <Field label={t('batch.column.estimated_shipping_date')} required>
            <DateInput
              name="estimated_shipping_date"
              value={
                data.estimated_shipping_date != null
                  ? new Date(data.estimated_shipping_date)
                  : null
              }
              onChange={value => {
                if (value instanceof Date) {
                  setData('estimated_shipping_date', resetTime(value));
                }
              }}
              error={errors.estimated_shipping_date}
              required
            />
          </Field>
          <Field label={t('batch.column.estimated_receiving_date')} required>
            <DateInput
              name="estimated_receiving_date"
              value={
                data.estimated_receiving_date != null
                  ? new Date(data.estimated_receiving_date)
                  : null
              }
              onChange={value => {
                if (value instanceof Date) {
                  setData('estimated_receiving_date', resetTime(value));
                }
              }}
              error={errors.estimated_receiving_date}
              required
            />
          </Field>
          <Field label={t('batch.column.description')}>
            <TextInput
              name="description"
              value={data.description}
              onChange={e => {
                setData('description', e.target.value);
              }}
              error={errors.description}
            />
          </Field>
          <Field label={t('batch.column.vendor_assay_id')} required>
            <Select
              name="vendor_assay_id"
              value={data.vendor_assay_id}
              onChange={value => {
                setData('vendor_assay_id', value as string);
              }}
              error={errors.vendor_assay_id}
              data={BATCH_VENDOR_ASSAY}
              required
            />
          </Field>
          <Field label={t('batch.column.profile')} required>
            <Select
              name="profile"
              value={data.profile}
              onChange={value => {
                setData('profile', value as string);
              }}
              error={errors.profile}
              data={BATCH_PROFILE}
              required
            />
          </Field>
          <Field label={t('batch.column.my_notes')}>
            <Textarea
              name="my_notes"
              value={data.my_notes}
              onChange={e => {
                setData('my_notes', e.target.value);
              }}
              error={errors.my_notes}
            />
          </Field>
          <Field label={t('batch.column.peptide_mix')}>
            <TextInput
              name="peptide_mix"
              value={data.peptide_mix}
              onChange={e => {
                setData('peptide_mix', e.target.value);
              }}
              error={errors.peptide_mix}
            />
          </Field>
          <Field label={t('batch.column.purchase_order')}>
            <TextInput
              name="purchase_order"
              value={data.purchase_order}
              onChange={e => {
                setData('purchase_order', e.target.value);
              }}
              error={errors.purchase_order}
            />
          </Field>
          <Field label={t('batch.column.container')}>
            <TextInput
              name="container"
              value={data.container}
              onChange={e => {
                setData('container', e.target.value);
              }}
              error={errors.container}
            />
          </Field>
          <Field label={t('batch.column.shipped_to_vendor_at')}>
            <DateInput
              name="shipped_to_vendor_at"
              value={
                data.shipped_to_vendor_at != null
                  ? new Date(data.shipped_to_vendor_at)
                  : null
              }
              onChange={value => {
                if (value instanceof Date) {
                  setData('shipped_to_vendor_at', resetTime(value));
                }
              }}
              error={errors.shipped_to_vendor_at}
            />
          </Field>
          <Field label={t('batch.column.amount')}>
            <NumberInput
              name="amount"
              value={data.amount}
              onChange={value => {
                setData('amount', Number(value));
              }}
              error={errors.amount}
            />
          </Field>
          <Field label={t('batch.column.amount_unit')} required>
            <Select
              name="amount_unit"
              value={data.amount_unit}
              onChange={value => {
                setData('amount_unit', value as string);
              }}
              error={errors.amount_unit}
              data={BATCH_AMOUNT_UNIT}
              required
            />
          </Field>
          <Field label={t('batch.column.aliquot_amount')}>
            <NumberInput
              name="aliquot_amount"
              value={data.aliquot_amount}
              onChange={value => {
                setData('aliquot_amount', Number(value));
              }}
              error={errors.aliquot_amount}
            />
          </Field>
          <Field label={t('batch.column.received_by_vendor_at')}>
            <DateInput
              name="received_by_vendor_at"
              value={
                data.received_by_vendor_at != null
                  ? new Date(data.received_by_vendor_at)
                  : null
              }
              onChange={value => {
                if (value instanceof Date) {
                  setData('received_by_vendor_at', resetTime(value));
                }
              }}
              error={errors.received_by_vendor_at}
            />
          </Field>
          <Field label={t('batch.column.data_uploaded_at')}>
            <DateInput
              name="data_uploaded_at"
              value={
                data.data_uploaded_at != null
                  ? new Date(data.data_uploaded_at)
                  : null
              }
              onChange={value => {
                if (value instanceof Date) {
                  setData('data_uploaded_at', resetTime(value));
                }
              }}
              error={errors.data_uploaded_at}
            />
          </Field>
          <Field label={t('batch.column.date_destroyed_at')}>
            <DateInput
              name="date_destroyed_at"
              value={
                data.date_destroyed_at != null
                  ? new Date(data.date_destroyed_at)
                  : null
              }
              onChange={date => {
                setData('date_destroyed_at', date?.toString());
              }}
              error={errors.data_uploaded_at}
            />
          </Field>
          <Field label={t('batch.column.disposal_record_data')}>
            <FileInput
              name="disposal_record_data"
              onChange={file => {
                setData('disposal_record_data', file);
              }}
              error={errors.disposal_record_data}
            />
          </Field>
        </Card.Section>
        <Card.Section p="md">
          <Button
            type="submit"
            disabled={processing}
          >{t`common.action.save`}</Button>
        </Card.Section>
      </Card>
    </form>
  );
}
