import { ScrollArea, Table, UnstyledButton } from '@mantine/core';
import type { ColumnDef, TableOptions } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { FaCaretDown, FaCaretRight, FaCaretUp } from 'react-icons/fa';

import classes from './DataGrid.module.css';

type Props<T> = Omit<TableOptions<T>, 'getCoreRowModel'>;

const defaultColumn: Partial<ColumnDef<unknown>> = {
  cell: ({ getValue }) => {
    const value = getValue();
    return value != null && value !== '' ? value : '—';
  },
};

export function DataGrid<T>(options: Props<T>) {
  const table = useReactTable({
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    ...options,
  });

  return (
    <ScrollArea>
      <Table>
        <Table.Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Table.Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Table.Th key={header.id}>
                  {table.options.enableSorting ?? false ? (
                    <UnstyledButton
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                      {{
                        asc: <FaCaretUp size={18} />,
                        desc: <FaCaretDown size={18} />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </UnstyledButton>
                  ) : (
                    <>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </>
                  )}
                </Table.Th>
              ))}
            </Table.Tr>
          ))}
        </Table.Thead>
        <Table.Tbody>
          {table.getRowModel().rows.map(row => (
            <Table.Tr
              className={row.getIsGrouped() ? classes.grouped : ''}
              key={row.id}
            >
              {row.getVisibleCells().map(cell => (
                <Table.Td key={cell.id}>
                  {cell.getIsGrouped() ? (
                    <UnstyledButton
                      {...{
                        onClick: row.getToggleExpandedHandler(),
                      }}
                    >
                      {row.getIsExpanded() ? (
                        <FaCaretDown size={18} />
                      ) : (
                        <FaCaretRight size={18} />
                      )}{' '}
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </UnstyledButton>
                  ) : cell.getIsAggregated() ? (
                    flexRender(
                      cell.column.columnDef.aggregatedCell ??
                        cell.column.columnDef.cell,
                      cell.getContext(),
                    )
                  ) : cell.getIsPlaceholder() ? null : (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )}
                </Table.Td>
              ))}
            </Table.Tr>
          ))}
          {table.getRowModel().rows.length === 0 && (
            <Table.Tr>
              <Table.Td align="center" colSpan={options.columns.length}>
                No Data
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </ScrollArea>
  );
}
