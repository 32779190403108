import { Link } from '@inertiajs/react';
import type { MantineColorScheme } from '@mantine/core';
import {
  Anchor,
  Card,
  Container,
  NativeSelect,
  Stack,
  Text,
  Title,
  useComputedColorScheme,
  useMantineColorScheme,
} from '@mantine/core';
import { startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { RiArrowRightSLine } from 'react-icons/ri';

import { ListAction } from '@/components';
import { APP_SUPPORT_EMAIL, APP_SUPPORT_URL, COLOR_SCHMES } from '@/constants';
import {
  client_account_data_sharing_path,
  client_account_deletion_path,
  client_account_edit_client_path,
  client_account_edit_health_profile_path,
} from '@/routes';

export default function Index() {
  const { t } = useTranslation();

  const { setColorScheme } = useMantineColorScheme();

  const colorScheme = useComputedColorScheme();

  const links = [
    {
      label: 'Personal information',
      url: client_account_edit_client_path(),
    },
    {
      label: 'Health profile',
      url: client_account_edit_health_profile_path(),
    },
    {
      label: 'Data sharing',
      url: client_account_data_sharing_path(),
    },
    {
      label: 'Change password',
      url: '/client/account/passwords/edit',
    },
    {
      label: 'Account deletion',
      url: client_account_deletion_path(),
    },
  ];

  return (
    <Container size="xs">
      <Stack gap="xl">
        <Stack gap="xs">
          <Title size="h2">Account</Title>
        </Stack>
        <Card>
          <Card.Section>
            {links.map(link => (
              <ListAction
                key={link.url}
                rightSection={<RiArrowRightSLine />}
                component={Link}
                href={link.url}
              >
                <Text>{link.label}</Text>
              </ListAction>
            ))}
            <ListAction
              rightSection={
                <NativeSelect
                  size="sm"
                  defaultValue={colorScheme}
                  data={COLOR_SCHMES.map(row => ({
                    value: row,
                    label: startCase(row),
                  }))}
                  onChange={e => {
                    setColorScheme(
                      (e.target.value as MantineColorScheme) ?? 'auto',
                    );
                  }}
                />
              }
            >
              <Text>Dark mode</Text>
            </ListAction>
          </Card.Section>
        </Card>
        <Card p="xl">
          <Stack>
            <Title order={3} size={20}>
              {t('onboarding.support.title')}
            </Title>
            <Text>
              {t('onboarding.support.contact')}{' '}
              <Anchor href={`mailto:${APP_SUPPORT_EMAIL}`}>
                {APP_SUPPORT_EMAIL}
              </Anchor>{' '}
              {t('onboarding.support.or_check')}{' '}
              <Anchor
                href={APP_SUPPORT_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('onboarding.support.faq')}
              </Anchor>
              .
            </Text>
          </Stack>
        </Card>
      </Stack>
    </Container>
  );
}
