import { usePage } from '@inertiajs/react';
import { Stack, Text } from '@mantine/core';

export default function NotFound() {
  const page = usePage();
  return (
    <div>
      <Stack>
        <Text ta="center" size="xl">
          404 Page Not Found
        </Text>
        <Text ta="center">
          The requested URL {page.url} was not found on this server.
        </Text>
      </Stack>
    </div>
  );
}
