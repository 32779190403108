import { Page } from '@/components';
import type { Assay, KitBatch, Site } from '@/types';

import { Form } from './_Form';

interface Props {
  kit_batch: KitBatch;
  sites: Site[];
  assays: Assay[];
  batch_type: string;
}

export default function New(props: Props) {
  return (
    <Page title="Update Kit Batch">
      <Form
        kitBatch={props.kit_batch}
        sites={props.sites}
        assays={props.assays}
        batchType={props.batch_type}
      />
    </Page>
  );
}
