import { Group } from '@mantine/core';

import { usePagination } from '@/hooks';

import classes from './Pagination.module.css';
import { PaginationLink } from './PaginationLink';

export function Pagination() {
  const pagination = usePagination();

  return pagination != null ? (
    <Group justify="space-between" p="md" className={classes.root}>
      <PaginationLink label="Previous" page={pagination.prev_page} />
      <div>
        {pagination?.from} -{' '}
        {pagination?.to > pagination?.total_count
          ? pagination?.total_count
          : pagination?.to}{' '}
        of {pagination?.total_count}
      </div>
      <PaginationLink label="Next" page={pagination.next_page} />
    </Group>
  ) : null;
}
