import type { ReactNode } from 'react';

import { Base } from './_Base';
import { Logo } from './_Logo';

interface Props {
  page: ReactNode;
  containerSize?: string;
}

export function Auth({ containerSize = 'xs', page }: Props) {
  return (
    <Base brand={<Logo />} containerSize={containerSize}>
      {page}
    </Base>
  );
}
