import { Link } from '@inertiajs/react';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Base } from './_Base';
import { Logo } from './_Logo';
import { UserMenu } from './_UserMenu';

interface Props {
  title?: string;
  actions?: ReactNode;
  page: ReactNode;
}

export function Admin({ page, ...props }: Props) {
  const { t } = useTranslation();

  return (
    <Base
      brand={
        <Link style={{ textDecoration: 'none', color: 'black' }} href="/admin">
          <Logo />
        </Link>
      }
      links={[
        {
          href: '#analysis',
          label: t('analysis.singular'),
          links: [
            {
              label: t('assay.plural'),
              href: '/admin/assays',
            },
            {
              label: t('batch.plural'),
              href: '/admin/batches',
              privilege: 'change_assays',
            },
          ],
        },
        {
          href: '#users',
          label: t('user.plural'),
          links: [
            {
              label: t('admin.plural'),
              href: '/admin/admins',
              privilege: 'change_admins',
            },
            {
              label: t('provider.plural'),
              href: '/admin/providers',
              privilege: 'change_sites',
            },
            {
              label: t('vendor_representative.plural'),
              href: '/admin/vendor_representatives',
              privilege: 'change_products',
            },
            {
              label: t('client.plural'),
              href: '/admin/clients',
              privilege: 'access_clients',
            },
          ],
        },
        {
          href: '#system',
          label: t('system.singular'),
          links: [
            {
              label: t('site.plural'),
              href: '/admin/sites',
              privilege: 'change_sites',
            },
            {
              label: t('company.plural'),
              href: '/admin/companies',
              privilege: 'change_sites',
            },
            {
              label: t('collection_site.plural'),
              href: '/admin/collection_sites',
              privilege: 'change_sites',
            },
            {
              label: t('collection_site_group.plural'),
              href: '/admin/collection_site_groups',
              privilege: 'change_sites',
            },
            {
              label: t('email_template.plural'),
              href: '/admin/email_templates',
              privilege: 'access_clients',
            },
            {
              label: t('kit_batch.plural'),
              href: '/admin/component_batches/kit_batch',
            },
            {
              label: t('product_bundle.plural'),
              href: '/admin/product_bundles',
              privilege: 'change_products',
            },
            {
              label: t('profile.plural'),
              href: '/admin/profiles',
            },
            {
              label: t('vendor.plural'),
              href: '/admin/vendors',
            },
          ],
        },
      ]}
      menu={<UserMenu root="/admins" />}
      {...props}
    >
      {page}
    </Base>
  );
}
