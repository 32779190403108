interface Predicate {
  measure_name: string;
}

export const sortByMeasureName = (a: Predicate, b: Predicate): number => {
  if (a.measure_name.toUpperCase() < b.measure_name.toUpperCase()) {
    return -1;
  }
  if (a.measure_name.toUpperCase() > b.measure_name.toUpperCase()) {
    return 1;
  }
  return 0;
};
