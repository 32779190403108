import { Link } from '@inertiajs/react';
import { Card, Menu } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaEllipsisV } from 'react-icons/fa';

import {
  BatchStateField,
  DataGrid,
  Page,
  Pagination,
  Toolbar,
} from '@/components';
import type { Batch } from '@/types';
import { formatDate } from '@/utils';

interface Props {
  batches: Batch[];
}

export default function Index(props: Props) {
  const { t } = useTranslation();

  return (
    <Page title={t('batch.plural')}>
      <Card>
        <Card.Section>
          <Toolbar />
          <DataGrid
            data={props.batches}
            columns={[
              {
                header: t('batch.column.vendor_batch_number'),
                accessorFn: row => row.vendor_batch_number,
              },
              {
                header: t('batch.column.name'),
                accessorFn: row => row.name,
              },
              {
                header: t('common.column.created_at'),
                accessorFn: row => formatDate(row.created_at),
              },
              {
                header: t('common.column.updated_at'),
                accessorFn: row => formatDate(row.updated_at),
              },
              {
                header: t('batch.column.state'),
                cell: ({ row }) => (
                  <BatchStateField value={row.original.state} />
                ),
              },
              {
                id: 'actions',
                cell: ({ row }) => (
                  <Menu>
                    <Menu.Target>
                      <span>
                        <FaEllipsisV />
                      </span>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        leftSection={<FaCheck />}
                        component={Link}
                        href={`/vendor_representative/batches/${row.original.id}`}
                      >
                        {t('batch.action.review')}
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
          <Pagination />
        </Card.Section>
      </Card>
    </Page>
  );
}
