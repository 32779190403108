import { Image } from '@mantine/core';
import { useEffect, useState } from 'react';

interface Props {
  iconId: string;
}

export function Icon({ iconId }: Props) {
  const [src, setSrc] = useState<string | null>(null);

  useEffect(() => {
    const importSvg = async () => {
      try {
        const module = await import(
          `@/assets/images/action_plan/${iconId}.svg`
        );
        setSrc(module.default);
      } catch (e) {
        console.error(`Error importing SVG for iconId: ${iconId}`, e);
        setSrc(null);
      }
    };
    importSvg();
  }, [iconId]);

  return <Image src={src} width={30} height={30} fit="contain" />;
}

export default Icon;
