import { useForm } from '@inertiajs/react';
import { Select } from '@mantine/core';
import { useEffect } from 'react';

import { useSite, useUser } from '@/hooks';
import type { Provider, Site } from '@/types';

export function Sites() {
  const currentSite = useSite();

  const provider = useUser<Provider & { sites: Site[] }>();

  const { data, setData, patch } = useForm({ site_id: currentSite?.id });

  const change = (value: string | null) => {
    if (value != null) {
      setData({
        site_id: Number(value),
      });
    }
  };

  const options = provider.sites.map(site => ({
    value: site.id.toString(),
    disabled: site.id === currentSite?.id,
    label: site.description,
  }));

  useEffect(() => {
    if (data.site_id !== currentSite?.id) {
      patch('/provider/site/change');
    }
  }, [data.site_id]);

  return (
    <Select
      size="sm"
      value={data.site_id?.toString()}
      onChange={change}
      data={options}
    />
  );
}
