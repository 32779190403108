import { usePage } from '@inertiajs/react';
import {
  ActionIcon,
  AppShell,
  Box,
  Container,
  Group,
  Text,
} from '@mantine/core';
import { Notifications, notifications } from '@mantine/notifications';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { RiMenuLine } from 'react-icons/ri';

import { useFlash, useUser } from '@/hooks';
import type { User } from '@/types';

import { Logo } from './_Logo';
import { NavBar } from './_NavBar';
import classes from './Client.module.css';

interface Props {
  page: ReactNode;
}

export function Client({ page }: Props) {
  const { url } = usePage();
  const user = useUser<User>();
  const [collapsed, setCollapsed] = useState(true);
  const flash = useFlash();

  useEffect(() => {
    if (flash?.alert != null) {
      notifications.show({
        message: flash.alert,
        color: 'yellow',
      });
    }
  }, [flash?.alert]);

  useEffect(() => {
    if (flash?.error != null) {
      notifications.show({
        message: flash.error,
        color: 'red',
      });
    }
  }, [flash?.error]);

  useEffect(() => {
    if (flash?.notice != null) {
      notifications.show({
        message: flash.notice,
        color: 'green',
      });
    }
  }, [flash?.notice]);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <AppShell
      className={classes.root}
      header={{ height: 60 }}
      navbar={{
        width: collapsed ? 100 : 250,
        breakpoint: 'sm',
        collapsed: { mobile: collapsed },
      }}
    >
      <Notifications />
      <AppShell.Header>
        <Container fluid>
          <Group justify="space-between" align="center">
            <Logo />
            <Group gap="xs" align="center">
              <Text fw={700} c="brand">
                {user?.first_name} {user?.last_name}
              </Text>
              <ActionIcon
                hiddenFrom="sm"
                variant="light"
                size="md"
                radius="xl"
                onClick={() => {
                  toggle();
                }}
              >
                <RiMenuLine />
              </ActionIcon>
            </Group>
          </Group>
        </Container>
      </AppShell.Header>
      <>
        <NavBar url={url} setCollapsed={setCollapsed} />
      </>
      <AppShell.Main>
        <Box py="xl" px={{ sm: 'xl' }}>
          {page}
        </Box>
      </AppShell.Main>
    </AppShell>
  );
}
