import { Box, Group, Stack, Text, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BiomarkerSummary, SampleConcentration } from '@/charts';
import { selectSampleConcentrationHistory } from '@/selectors';
import type { Biofunction } from '@/types';

import { BiomarkersListItem } from './_BiomarkersListItem';
import { CollapsibleCard } from './_CollapsibleCard';

interface Props {
  data: Biofunction;
  selected: number | null;
  handleSelect: (id: number) => void;
}

export function BiofunctionCollapsibleCards({
  data,
  selected,
  handleSelect,
}: Props) {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { t } = useTranslation();

  const [expandedCards, setExpandedCards] = useState({
    biomarkers: true,
    history: true,
    info: true,
  });

  const toggleCollapse = (card: keyof typeof expandedCards) => {
    setExpandedCards(prevState => ({
      ...prevState,
      [card]: !prevState[card],
    }));
  };

  return (
    <Stack gap="xs">
      <Title size="h4" lineClamp={1} tt="capitalize">
        {data.biofunction_name}
      </Title>
      <CollapsibleCard
        title={t('biomarker.plural')}
        onToggle={() => toggleCollapse('biomarkers')}
        isOpen={expandedCards.biomarkers}
        children={
          <div>
            {data.health_report_sample_concentrations?.map(
              (concentration, index) => (
                <BiomarkersListItem
                  onClick={() => handleSelect(concentration.id)}
                  key={index}
                  name={concentration.measure_name}
                  selected={concentration.id === selected}
                  children={
                    <Box w={isMobile ? '40%' : '50%'}>
                      <SampleConcentration
                        data={selectSampleConcentrationHistory([concentration])}
                      />
                    </Box>
                  }
                />
              ),
            )}
          </div>
        }
      />

      <CollapsibleCard
        title={t('biofunctions.pathway_history')}
        onToggle={() => toggleCollapse('history')}
        isOpen={expandedCards.history}
        children={
          <div>
            {data.history
              ?.slice()
              .sort(
                (a, b) =>
                  new Date(a.collection_date).getTime() -
                  new Date(b.collection_date).getTime(),
              )
              .map(history => (
                <Group
                  justify="flex-start"
                  px="lg"
                  key={history.collection_date}
                >
                  <Text tt="capitalize" w="30%" py="xs">
                    {dayjs(history.collection_date).format('MMM YYYY')}
                  </Text>
                  <Box w="30%">
                    <BiomarkerSummary data={history.all_measure_counts} />
                  </Box>
                </Group>
              ))}
          </div>
        }
      />

      <CollapsibleCard
        title={`${data.biofunction_name} Info`}
        onToggle={() => toggleCollapse('info')}
        isOpen={expandedCards.info}
        children={<Text px="lg">{data.general_description}</Text>}
      />
    </Stack>
  );
}
