import { Grid, Text } from '@mantine/core';
import type { PropsWithChildren } from 'react';

import classes from './Field.module.css';

interface Props extends PropsWithChildren {
  label?: string;
  required?: boolean;
  hint?: string;
  error?: string[] | string;
}

export function Field({ label, required, hint, error, children }: Props) {
  return (
    <Grid p="lg" className={classes.root}>
      <Grid.Col span={3}>
        <Text>
          {label}{' '}
          {required === true && (
            <Text span c="red">
              *
            </Text>
          )}{' '}
          {hint != null ? `(${hint})` : ''}
        </Text>
      </Grid.Col>
      <Grid.Col span={6}>
        {children != null && children !== '' ? children : '—'}
        {error != null && (
          <Text c="red">
            {label} {error}
          </Text>
        )}
      </Grid.Col>
    </Grid>
  );
}
