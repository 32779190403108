import './i18n';

import Appsignal from '@appsignal/javascript';
import { ErrorBoundary } from '@appsignal/react';
import { createInertiaApp } from '@inertiajs/react';
import { ColorSchemeScript, MantineProvider } from '@mantine/core';
import type { ReactElement } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';

import { theme } from '@/entrypoints/theme';
import { Admin, Auth, Client, Provider, Vendor } from '@/layouts';

declare global {
  interface Window {
    env: {
      APPSIGNAL_PUBLISHABLE_PUSH_API_KEY: string;
    };
  }
}

interface ModuleImportInterface {
  default: {
    layout?: (page: ReactElement) => ReactElement;
  };
}

const pages: Record<string, ModuleImportInterface> = import.meta.glob(
  '../pages/**/[!_]*.tsx',
  { eager: true },
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const appsignal = new Appsignal({
  key: window.env.APPSIGNAL_PUBLISHABLE_PUSH_API_KEY,
});

const FallbackComponent = () => (
  <div>Whoops, looks like something went wrong.</div>
);

const getLayout = ([namespace]: string[]) => {
  switch (namespace) {
    case 'Admin':
      return (page: ReactElement) => <Admin page={page} />;
    case 'Auth':
      return (page: ReactElement) => <Auth page={page} />;
    case 'Client':
      return (page: ReactElement) => <Client page={page} />;
    case 'Provider':
      return (page: ReactElement) => <Provider page={page} />;
    case 'Vendor':
      return (page: ReactElement) => <Vendor page={page} />;
  }
};

document.addEventListener('DOMContentLoaded', () => {
  createInertiaApp({
    resolve: name => {
      const page = pages[`../pages/${name}.tsx`];
      page.default.layout = page.default.layout || getLayout(name.split('/'));
      return page;
    },
    setup({ el, App, props }) {
      createRoot(el).render(
        <ErrorBoundary
          instance={appsignal}
          tags={{ tag: 'value' }}
          fallback={() => <FallbackComponent />}
        >
          <ColorSchemeScript defaultColorScheme="auto" />
          <MantineProvider theme={theme} defaultColorScheme="auto">
            <QueryClientProvider client={queryClient}>
              <App {...props} />
            </QueryClientProvider>
          </MantineProvider>
        </ErrorBoundary>,
      );
    },
  }).catch(err => {
    console.log(err);
  });
});
