import axios from 'axios';

interface Params {
  lat?: number;
  lng?: number;
  search_address?: string;
}

export interface CollectionSite {
  id: number;
  name: string;
  latitude: string;
  longitude: string;
  distance: number;
  booking_preference: string;
  opening_hours: string;
}

export const getCollectionSites = async (
  params: Params,
): Promise<CollectionSite[]> => {
  const response = axios
    .get(`${window.location.origin}/client/api/collection_sites`, { params })
    .then(res => res.data);
  return await response;
};
