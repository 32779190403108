import { Page } from '@/components';
import type { EmailTemplate } from '@/types';

import { Form } from './_Form';

interface Props {
  email_template: EmailTemplate;
}

export default function New(props: Props) {
  return (
    <Page title="Update Email Template">
      <Form emailTemplate={props.email_template} />
    </Page>
  );
}
