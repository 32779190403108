import { useForm } from '@inertiajs/react';
import { Button, Card, MultiSelect, Select, Stack } from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import type { Questionnaire, QuestionnaireOptions } from '@/types';

interface Props {
  action: string;
  healthProfile: Questionnaire;
  responseOptions: QuestionnaireOptions;
}

export function HealthProfileForm({
  action,
  healthProfile,
  responseOptions,
}: Props) {
  const { t } = useTranslation();

  const { data, setData, patch, errors } = useForm(healthProfile);

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    patch(action);
  };

  return (
    <form onSubmit={save}>
      <Card padding="xl">
        <Card.Section withBorder p="xl">
          <Stack gap="lg">
            <Stack gap="sm">
              <Select
                label={t('health_profile.column.alcohol_per_week')}
                description={t('health_profile.description.alcohol_per_week')}
                name="alchohol_per_week"
                value={data.alcohol_per_week}
                onChange={value => setData('alcohol_per_week', value ?? '')}
                data={responseOptions.alcohol_per_week}
                placeholder={t('common.placeholder.none')}
                error={errors.alcohol_per_week}
              />
              <MultiSelect
                label={t('health_profile.column.dietary_allergies')}
                onChange={value => setData('dietary_allergies', value)}
                value={data.dietary_allergies ?? []}
                data={responseOptions.dietary_allergies}
                placeholder={t('common.placeholder.select_an_option')}
                error={errors.dietary_allergies}
              />
              <MultiSelect
                label={t('health_profile.column.dietary_preferences')}
                onChange={value => setData('dietary_preferences', value)}
                value={data.dietary_preferences ?? []}
                data={responseOptions.dietary_preferences}
                placeholder={t('common.placeholder.select_an_option')}
                error={errors.dietary_preferences}
              />
              <Select
                label={t('health_profile.column.physical_activity')}
                name="stage_of_change"
                value={data.stage_of_change}
                onChange={value => setData('stage_of_change', value ?? '')}
                data={Object.entries(responseOptions.stage_of_change).map(
                  ([value, { label }]) => ({
                    label,
                    value,
                  }),
                )}
                error={errors.stage_of_change}
              />
              <MultiSelect
                label={t('health_profile.column.health_conditions')}
                onChange={value =>
                  setData(
                    'health_conditions',
                    responseOptions.health_conditions.map(healthCondition => ({
                      ...healthCondition,
                      response: value.includes(
                        healthCondition.disease_identifier,
                      ),
                    })),
                  )
                }
                value={data.health_conditions
                  .filter(condition => condition.response)
                  .map(condition => condition.disease_identifier)}
                data={responseOptions.health_conditions.map(option => ({
                  label: option.name,
                  value: option.disease_identifier,
                }))}
                placeholder={t('common.placeholder.select_an_option')}
                error={errors.health_conditions}
              />
            </Stack>
            <Button type="submit">{t('common.action.submit')}</Button>
          </Stack>
        </Card.Section>
      </Card>
    </form>
  );
}
