import { Link, useForm } from '@inertiajs/react';
import {
  Button,
  Card,
  Group,
  HoverCard,
  Radio,
  Text,
  TextInput,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { type SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import { AddressAutocomplete } from '@/components/AddressAutocomplete';
import type { Address, Carmen } from '@/types';
import { type Client } from '@/types';
import { nestedAttributes } from '@/utils';

import { Consent } from './_Consent';

interface Props {
  client: Client;
  action: string;
  carmen: Carmen;
  apiKey: string;
  address: Address;
}

export function Form({ client, action, carmen, apiKey, address }: Props) {
  const { t } = useTranslation();
  const [consented, setConsented] = useState(client.consent);
  const [authorized, setAuthorized] = useState(client.consent);
  const { data, setData, errors, submit, transform } = useForm(client);
  const [localErrors, setLocalErrors] = useState<Record<string, string>>({});
  useEffect(() => {
    setData('consent', consented && authorized);
  }, [consented, authorized]);

  transform(nestedAttributes(['addresses']));

  const handleAddressChange = (updatedAddress: Address) => {
    setData('addresses', [updatedAddress]);
  };

  const validateFields = () => {
    let isValid = true;
    const newErrors: Record<string, string> = {};

    if (!data.addresses?.[0]?.country) {
      newErrors['addresses[0].country'] = 'Country is required';
      isValid = false;
    }
    if (!data.addresses?.[0]?.province_state) {
      newErrors['addresses[0].province_state'] = 'Province/State is required';
      isValid = false;
    }
    setLocalErrors(newErrors);
    return isValid;
  };

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    if (validateFields()) {
      submit(data.id != null ? 'patch' : 'post', action);
    }
  };

  return (
    <>
      <form onSubmit={save}>
        <Card mb="lg">
          <Card.Section>
            <Field label={t('client.column.email')} required>
              <TextInput
                required
                name="email"
                value={data.email || ''}
                onChange={e => {
                  setData('email', e.target.value);
                }}
                error={errors.email}
              />
            </Field>
            <Field label={t('client.column.phone')} required>
              <TextInput
                required
                name="phone"
                value={data.phone || ''}
                onChange={e => {
                  setData('phone', e.target.value);
                }}
                error={errors.phone}
              />
            </Field>
            <Field label={t('client.column.first_name')} required>
              <TextInput
                required
                name="first_name"
                value={data.first_name || ''}
                onChange={e => {
                  setData('first_name', e.target.value);
                }}
                error={errors.first_name}
              />
            </Field>
            <Field label={t('client.column.last_name')} required>
              <TextInput
                required
                name="last_name"
                value={data.last_name || ''}
                onChange={e => {
                  setData('last_name', e.target.value);
                }}
                error={errors.last_name}
              />
            </Field>
            <Field label={t('client.column.preferred_name')}>
              <TextInput
                name="preferred_name"
                value={data.preferred_name != null ? data.preferred_name : ''}
                onChange={e => {
                  setData('preferred_name', e.target.value);
                }}
                error={errors.preferred_name}
              />
            </Field>
            <Field label={t('client.column.date_of_birth')} required>
              <DateInput
                required
                name="date_of_birth"
                placeholder="YYYY-MM-DD"
                value={
                  data.date_of_birth != null
                    ? new Date(data.date_of_birth)
                    : null
                }
                onChange={value => {
                  if (value instanceof Date) {
                    setData(
                      'date_of_birth',
                      new Date(
                        `${value.toISOString().split('T')[0]}T00:00:00`,
                      ).toISOString(),
                    );
                  }
                }}
                error={errors.date_of_birth}
              />
            </Field>
            <Field label={t('client.column.sex')} required>
              <Radio.Group
                name="sex"
                value={data.sex || ''}
                onChange={value => {
                  setData('sex', value);
                }}
                error={errors.sex}
              >
                <Group>
                  <Radio value="male" label="Male" />
                  <Radio value="female" label="Female" />
                  <Radio value="other" label="Other" />
                </Group>
              </Radio.Group>
            </Field>
          </Card.Section>
        </Card>
        <AddressAutocomplete
          address={address}
          onAddressChange={handleAddressChange}
          carmen={carmen}
          api_key={apiKey}
          errors={{ ...errors, ...localErrors }}
        />
        <Card mb="lg">
          <Card.Section>
            <Consent
              consented={consented}
              authorized={authorized}
              onConsentChange={setConsented}
              onAuthorizationChange={setAuthorized}
            />
          </Card.Section>
          <Card.Section>
            <Group justify="end" p="md">
              <Button variant="outline" component={Link} href={action}>
                {t('common.action.back')}
              </Button>
              <HoverCard width={280}>
                <HoverCard.Target>
                  <Button type="submit" disabled={!consented || !authorized}>
                    {t('provider.confirm.create_patient')}
                  </Button>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Text>{t('provider.confirm.create_patient_tooltip')}</Text>
                </HoverCard.Dropdown>
              </HoverCard>
            </Group>
          </Card.Section>
        </Card>
      </form>
    </>
  );
}
