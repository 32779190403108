import { Combobox, TextInput, useCombobox } from '@mantine/core';
import { RiSearchLine } from 'react-icons/ri';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';

interface AddressDetails {
  lat: number;
  lng: number;
  street: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
  locality: string;
}
interface Props {
  onChange: (event: AddressDetails) => void;
}

export const PlacesAutocomplete = ({ onChange }: Props) => {
  const {
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: 'YOUR_CALLBACK_NAME',
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const combobox = useCombobox();
  const handleSelect = (description: string) => {
    setValue(description, false);
    clearSuggestions();
    getGeocode({ address: description })
      .then(results => {
        const { lat, lng } = getLatLng(results[0]);
        const addressComponents = results[0].address_components;

        const getAddressComponent = (
          type: string,
          nameType: 'long_name' | 'short_name' = 'long_name',
        ) => {
          const component = addressComponents.find(
            (comp: { types: string | string[] }) => comp.types.includes(type),
          );
          return component ? component[nameType] : '';
        };
        const addressDetails: AddressDetails = {
          lat,
          lng,
          street: `${getAddressComponent('street_number')} ${getAddressComponent('route')}`,
          locality: getAddressComponent('route'),
          city: getAddressComponent('locality'),
          province: getAddressComponent(
            'administrative_area_level_1',
            'short_name',
          ),
          country: getAddressComponent('country'),
          postalCode: getAddressComponent('postal_code'),
        };

        onChange(addressDetails);
      })
      .catch(error => {
        console.error('Error getting geocode:', error);
      });
  };

  const renderSuggestions = (): JSX.Element => {
    const options = data.map(suggestion => (
      <Combobox.Option value={suggestion.description} key={suggestion.place_id}>
        <div>
          <strong>{suggestion.structured_formatting.main_text}</strong>{' '}
          <small>{suggestion.structured_formatting.secondary_text}</small>
        </div>
      </Combobox.Option>
    ));
    return (
      <Combobox
        onOptionSubmit={optionValue => {
          setValue(optionValue);
          handleSelect(optionValue);
          combobox.closeDropdown();
        }}
        store={combobox}
      >
        <Combobox.Target>
          <TextInput
            placeholder="Search address ..."
            value={value}
            onChange={event => {
              setValue(event.currentTarget.value);
              combobox.openDropdown();
              combobox.updateSelectedOptionIndex();
            }}
            rightSection={<RiSearchLine />}
            onClick={() => {
              combobox.openDropdown();
            }}
            onFocus={() => {
              combobox.openDropdown();
            }}
            onBlur={() => {
              combobox.closeDropdown();
            }}
          />
        </Combobox.Target>
        <Combobox.Dropdown>
          <Combobox.Options>
            {options.length === 0 ? (
              <Combobox.Empty>Nothing found</Combobox.Empty>
            ) : (
              options
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    );
  };

  return <div>{renderSuggestions()}</div>;
};
