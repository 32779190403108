import { Page } from '@/components';
import type { Provider, Site } from '@/types';

import { Form } from './_Form';

interface Props {
  provider: Provider;
  sites: Site[];
}

export default function Edit({ provider, sites }: Props) {
  return (
    <Page title="Update Staff">
      <Form provider={provider} sites={sites} />
    </Page>
  );
}
