import { Container, Stack } from '@mantine/core';

import { Back } from '@/components';
import {
  client_account_index_path,
  client_account_update_client_path,
} from '@/routes';
import type { Client } from '@/types';

import { PersonalInfoForm } from '../_PersonalInfoForm';

interface Props {
  client: Client;
}

export default function PersonalInfo({ client }: Props) {
  return (
    <Container size="xs">
      <Stack gap="xl">
        <Back label="Personal information" to={client_account_index_path()} />
        <PersonalInfoForm
          action={client_account_update_client_path()}
          client={client}
        />
      </Stack>
    </Container>
  );
}
