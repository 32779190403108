import {
  Button,
  Card,
  Checkbox,
  Group,
  Select,
  Table,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';

import type { ProductBundle, SiteProductBundle } from '@/types';

interface Props {
  value: (SiteProductBundle & {
    product_bundle?: ProductBundle;
  })[];
  onChange: (value: SiteProductBundle[]) => void;
  errors: Record<string, string>;
  productBundles: ProductBundle[];
}

export function SiteProductBundleEdit({
  value = [],
  onChange,
  errors,
  productBundles,
}: Props) {
  const { t } = useTranslation();
  const add = () => {
    onChange([...value, {}]);
  };

  const remove = (idx: number) => {
    onChange(value.map((v, i) => (i === idx ? { ...v, _destroy: true } : v)));
  };

  const updateProductBundle = (idx: number, productBundleId: string) => {
    const productBundle = productBundles.filter(
      productBundle => productBundle.id.toString() === productBundleId,
    )[0];

    if (productBundle != null) {
      onChange(
        value.map((v, i) =>
          i === idx
            ? {
                ...v,
                product_bundle_id: productBundleId,
              }
            : v,
        ),
      );
    }
  };

  const update = (idx: number, key: string, nextValue) => {
    onChange(value.map((v, i) => (i === idx ? { ...v, [key]: nextValue } : v)));
  };

  return (
    <Card>
      <Card.Section withBorder>
        <Group p="md" justify="space-between">
          <Text>{t('product_bundle.plural')}</Text>
          <Button
            size="xs"
            px="xs"
            onClick={() => {
              add();
            }}
          >
            <FaPlus />
          </Button>
        </Group>
      </Card.Section>
      <Card.Section>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th> {t('product_bundle.column.product_name')}</Table.Th>
              <Table.Th>{t('product_bundle.column.default_for_site')}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {value
              .filter(siteProductBundle => siteProductBundle._destroy == null)
              .map((item, idx) => (
                <Table.Tr key={idx}>
                  <Table.Td valign="top">
                    <Select
                      required
                      value={item.product_bundle_id?.toString() || ''}
                      name={`product_bundle_${idx}`}
                      error={errors?.[
                        `site_product_bundles[${idx}].product_bundle`
                      ]?.join(' and ')}
                      onChange={value => {
                        updateProductBundle(idx, value as string);
                      }}
                      data={productBundles.map(productBundle => ({
                        label: productBundle.product_name,
                        value: productBundle.id.toString(),
                      }))}
                    />
                  </Table.Td>
                  <Table.Td valign="top">
                    <Checkbox
                      checked={item.default_for_site}
                      onChange={e => {
                        update(idx, 'default_for_site', e.target.checked);
                      }}
                    />
                  </Table.Td>
                  {value.length > 1 && (
                    <Table.Td valign="top">
                      <UnstyledButton
                        mt="11"
                        type="button"
                        onClick={() => {
                          remove(idx);
                        }}
                      >
                        <FaTrashAlt />
                      </UnstyledButton>
                    </Table.Td>
                  )}
                </Table.Tr>
              ))}
          </Table.Tbody>
        </Table>
      </Card.Section>
    </Card>
  );
}
