import { Box, Card, Group, Stack, Text, Title } from '@mantine/core';
import { FaCircle } from 'react-icons/fa';

import type { Action, Color } from '@/types';
import { getColor } from '@/utils';

import classes from './_NavGroup.module.css';

interface Props {
  recommendations: {
    diet?: Action[];
    supplement?: Action[];
    lifestyle?: Action[];
  };
  value: Action | null;
  onChange: (value: Action) => void;
}

export function NavGroup({ recommendations, value, onChange }: Props) {
  const maxVisible = 8;
  return (
    <Stack>
      {Object.entries(recommendations)
        .sort()
        .map(([key, actions], x) => (
          <Stack mt="lg" key={x} mr="sm">
            <Title size="h3" tt="capitalize">
              {key}
            </Title>
            <Stack gap={0}>
              {actions.map((action, index) => (
                <Card
                  withBorder
                  radius={0}
                  key={index}
                  onClick={() => {
                    onChange(action);
                  }}
                  className={`${classes.navLink} ${index === 0 ? classes.firstCard : ''} ${index === actions.length - 1 ? classes.lastCard : ''}`}
                  data-active={action.id === value?.id}
                  data-action={action.action}
                >
                  <Text
                    size="sm"
                    fw={500}
                    c={action?.action === 'avoid' ? 'red' : 'blue'}
                    tt="uppercase"
                  >
                    {action.action}
                  </Text>
                  <Group justify="space-between">
                    <Text size="h5" tt="capitalize">
                      {action.name}
                    </Text>
                    <Group gap={4}>
                      {[
                        ...action.target_health_areas,
                        ...action.target_biomarkers,
                      ]
                        .sort((a, b) => {
                          const colorOrder = { red: 0, yellow: 1 };
                          return (
                            (colorOrder[a.color as Color] ?? 2) -
                            ((colorOrder[b.color] as Color) ?? 2)
                          );
                        })
                        .slice(0, maxVisible)
                        .map((area, index) => (
                          <Box key={index} style={{ flexShrink: 0 }}>
                            <FaCircle size={9} color={getColor(area.color)} />
                          </Box>
                        ))}
                      {action.target_health_areas.length +
                        action.target_biomarkers.length >
                        maxVisible && (
                        <Text size="xs" color="gray">
                          +
                          {action.target_health_areas.length +
                            action.target_biomarkers.length -
                            maxVisible}
                        </Text>
                      )}
                    </Group>
                  </Group>
                </Card>
              ))}
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
}
