import { Link, useForm } from '@inertiajs/react';
import {
  Autocomplete,
  Button,
  Card,
  Group,
  MultiSelect,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, PrivilegeSetInput } from '@/components';
import { PROVIDER_PRIVILEGE_SET, PROVIDER_ROLE } from '@/constants';
import type { Company, PrivilegeSet, Provider, Site } from '@/types';
import { formatDate } from '@/utils';

interface Props {
  provider: Provider & {
    privilege_set: PrivilegeSet;
  };
  companies?: Company[];
  sites: Site[];
}

export function Form({ provider, companies, sites }: Props) {
  const { t } = useTranslation();

  const { data, setData, submit, transform, errors } = useForm(provider);

  const [companyId, setCompanyId] = useState<number | null>();
  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    submit(
      provider.id != null ? 'patch' : 'post',
      `/admin/providers/${provider.id ?? ''}`,
    );
  };

  transform(
    ({
      privilege_set: privilegeSet,
      provider_sites: providerSites,
      ...data
    }) => ({
      privilege_set_attributes: privilegeSet,
      provider_sites_attributes: providerSites,
      ...data,
    }),
  );

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section>
          {companies != null && (
            <Field label={t('company.singular')}>
              <Autocomplete
                required
                onOptionSubmit={value => {
                  setCompanyId(Number(value));
                }}
                data={companies?.map(company => ({
                  value: company.id.toString(),
                  label: company.name,
                }))}
              />
            </Field>
          )}
          <Field label={t('site.plural')}>
            <MultiSelect
              required
              value={
                data.provider_sites != null
                  ? data.provider_sites
                      .filter(site => site._destroy == null)
                      .map(site => site.site_id.toString())
                  : []
              }
              hidePickedOptions
              onChange={value => {
                setData('provider_sites', [
                  ...(data.provider_sites?.reduce(
                    (sites, { _destroy, ...site }) => {
                      if (
                        value.includes(site.site_id.toString()) &&
                        _destroy != null
                      ) {
                        return [...sites, site];
                      } else if (
                        !value.includes(site.site_id.toString()) &&
                        site.id != null
                      ) {
                        return [...sites, { ...site, _destroy: true }];
                      } else if (!value.includes(site.site_id.toString())) {
                        return sites;
                      }
                      return [...sites, { ...site, _destroy }];
                    },
                    [],
                  ) ?? []),
                  ...(value.reduce((sites, siteId) => {
                    if (
                      data.provider_sites == null ||
                      !data.provider_sites?.some(
                        site => site.site_id.toString() === siteId,
                      )
                    ) {
                      return [...sites, { site_id: siteId }];
                    }
                    return sites;
                  }, []) ?? []),
                ]);
              }}
              data={(companies != null
                ? sites.filter(site => site.company_id === companyId)
                : sites
              ).map(site => ({
                value: site.id.toString(),
                label: site.description,
              }))}
            />
          </Field>
          <Field label={t('user.column.first_name')}>
            <TextInput
              required
              value={data.first_name}
              onChange={e => {
                setData('first_name', e.target.value);
              }}
              error={errors.first_name}
            />
          </Field>
          <Field label={t('user.column.last_name')}>
            <TextInput
              required
              value={data.last_name}
              onChange={e => {
                setData('last_name', e.target.value);
              }}
              error={errors.last_name}
            />
          </Field>
          <Field label={t('user.column.email')}>
            <TextInput
              required
              value={data.email}
              onChange={e => {
                setData('email', e.target.value);
              }}
              error={errors.email}
            />
          </Field>
          <Field label="Email Confirmation">
            <TextInput
              value={data.email_confirmation}
              onChange={e => {
                setData('email_confirmation', e.target.value);
              }}
              error={errors.email}
            />
          </Field>
          <Field label={t('user.column.role')}>
            <Select
              name="role"
              value={data.role}
              onChange={value => {
                setData(data => ({
                  ...data,
                  role: value as string,
                  privilege_set: PROVIDER_PRIVILEGE_SET.reduce(
                    (privilegeSet, privilege) => {
                      return {
                        ...privilegeSet,
                        [privilege]:
                          PROVIDER_ROLE[
                            value as keyof typeof PROVIDER_ROLE
                          ].includes(privilege),
                      };
                    },
                    {},
                  ),
                }));
              }}
              data={Object.keys(PROVIDER_ROLE).map(role => ({
                value: role,
                label: t(`provider.role.${role}.label`),
              }))}
              error={errors.role}
            />
          </Field>
          {provider.id != null && (
            <Field label={t('client.column.invitation')}>
              {provider.invitation_accepted_at !== null ? (
                <Text>
                  Accepted on {formatDate(provider.invitation_accepted_at)}
                </Text>
              ) : (
                <>
                  {provider.invitation_sent_at !== null && (
                    <Text>
                      Invitation sent on{' '}
                      {formatDate(provider.invitation_sent_at)}
                    </Text>
                  )}
                  <Button
                    component={Link}
                    type="button"
                    method="post"
                    href={`/admin/provider_invites?provider_id=${provider.id}`}
                    onClick={e => {
                      if (
                        !confirm(
                          t('admin.confirm.resend_invite', {
                            name: `${provider.first_name} ${provider.last_name}`,
                          }),
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                  >
                    {t('admin.actions.resend_invitation')}
                  </Button>
                </>
              )}
            </Field>
          )}
          <Field label={t('privilege_set.singular')}>
            <PrivilegeSetInput
              value={data.privilege_set}
              onChange={value => {
                setData('privilege_set', value);
              }}
              data={PROVIDER_PRIVILEGE_SET.map(privilege => ({
                value: privilege,
                label: t(`provider.privilege.${privilege}`),
              }))}
            />
          </Field>
        </Card.Section>
        <Card.Section>
          <Group p="md" justify="end">
            <Button type="submit">{t('common.action.save')}</Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
