import { useTranslation } from 'react-i18next';

import { Page } from '@/components';

import { HealthProfileForm } from './_HealthProfileForm';

export default function EditHealthProfile() {
  const { t } = useTranslation();

  return (
    <Page
      title={t('health_profile.page.title')}
      subtitle={t('health_profile.page.subtitle')}
    >
      <HealthProfileForm />
    </Page>
  );
}
