import { Box, Grid, Group, Progress, Text } from '@mantine/core';

import type { Color } from '@/types';
import { getBiomarkerRisk, getColor } from '@/utils';

interface Props {
  color: Color;
  score: number;
}

export function Risk({ color, score }: Props) {
  return (
    <Group gap="xs" align="start">
      <Text size="12px">0</Text>
      <Box pt="3px" style={{ flexGrow: 1 }}>
        <Grid gutter="4px">
          {['red', 'yellow', 'green'].map((option, i, array) => (
            <Grid.Col key={i} span={4}>
              <Progress
                color={getColor(color)}
                value={score * array.length - 100 * i}
                mb="6px"
              />
              {color === option && (
                <Text c="dimmed" size="12px" ta="center">
                  {getBiomarkerRisk(color)}
                </Text>
              )}
            </Grid.Col>
          ))}
        </Grid>
      </Box>
      <Text size="12px">100</Text>
    </Group>
  );
}
