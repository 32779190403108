import {
  Button,
  CopyButton,
  Divider,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { APP_CLIENT_ONBOARDING_TUTORIAL_URL } from '@/constants';
import { useSite } from '@/hooks';

interface Props {
  opened: boolean;
  close: () => void;
}

export function InviteClients({ opened, close }: Props) {
  const site = useSite();

  const { t } = useTranslation();

  const link = `${window.location.origin}/clients/sign_up?site_id=${site?.id ?? ''}`;

  return (
    <Modal
      opened={opened}
      onClose={close}
      withCloseButton={false}
      size="lg"
      radius="md"
      padding="xl"
    >
      <Stack>
        <Stack gap="lg" mb="lg">
          <Title order={3}>{t('patient.modal.invite_patients.title')}</Title>
          <Text>
            {t('patient.modal.invite_patients.description')}{' '}
            <a
              href={APP_CLIENT_ONBOARDING_TUTORIAL_URL}
              target="_blank"
              rel="noreferrer"
            >
              {t('patient.modal.invite_patients.learn_more')}
            </a>
          </Text>
          <Group gap="sm">
            <TextInput value={link} style={{ flex: 1 }} readOnly size="sm" />
            <CopyButton value={link}>
              {({ copied, copy }) => (
                <Button size="sm" onClick={copy}>
                  {t('patient.modal.invite_patients.cta', {
                    action: copied ? 'Copied' : 'Copy',
                  })}
                </Button>
              )}
            </CopyButton>
          </Group>
        </Stack>
        <Divider />

        <Stack gap="lg">
          <Title order={3}>{t('patient.modal.profile_setup.title')}</Title>
          <Text>{t('patient.modal.profile_setup.description')}</Text>
          <Group justify="end">
            <Button size="sm" component="a" href="/provider/clients/new">
              {t('patient.modal.profile_setup.cta')}
            </Button>
          </Group>
        </Stack>
      </Stack>
    </Modal>
  );
}
