import { Page } from '@/components';
import type { CollectionSite, CollectionSiteGroup } from '@/types';

import { Form } from './_Form';

interface Props {
  collection_site_group: CollectionSiteGroup;
  collection_sites: CollectionSite[];
}

export default function New(props: Props) {
  return (
    <Page title="Update Collection Site Group">
      <Form
        collectionSiteGroup={props.collection_site_group}
        collectionSite={props.collection_sites}
      />
    </Page>
  );
}
