import { useForm } from '@inertiajs/react';
import {
  Autocomplete,
  Badge,
  Button,
  Card,
  Checkbox,
  Group,
} from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import type { Assay, Client, CollectionSite, Kit } from '@/types';

interface Props {
  assay: Assay & {
    client?: Client;
    active_kit?: Kit;
    collection_site?: CollectionSite;
  };
  collectionSites: CollectionSite[];
}

export function Form({ assay, collectionSites }: Props) {
  const { t } = useTranslation();

  const { data, setData, patch, errors } = useForm({
    collected_on: assay.collected_on,
    collection_site_id: assay.collection_site_id,
    fasting: assay.fasting,
  });

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    patch(`/admin/assays/${assay.id}`);
  };

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section>
          <Field label={t('client.column.identifier')}>
            <Badge color="blue">{assay.client?.identifier}</Badge>
          </Field>
          <Field label={t('assay.column.code')}>
            <Badge color="blue">{assay.code}</Badge>
          </Field>
          <Field label={t('kit.column.barcode_id')}>
            {assay.active_kit?.barcode_id}
          </Field>
          <Field label={t('collection_site.singular')}>
            <Autocomplete
              defaultValue={assay.collection_site?.name}
              onOptionSubmit={value => {
                setData('collection_site_id', Number(value));
              }}
              data={collectionSites.map(collectionSite => ({
                value: collectionSite.id.toString(),
                label: collectionSite.name,
              }))}
            />
          </Field>
          <Field label={t('assay.column.collected_on')}>
            <DateTimePicker
              value={
                data.collected_on != null ? new Date(data.collected_on) : null
              }
              onChange={value => {
                setData('collected_on', value?.toString() ?? '');
              }}
              error={errors.collected_on}
            />
          </Field>
          <Field label={t('assay.column.fasting')}>
            <Checkbox
              checked={data.fasting}
              onChange={e => {
                setData('fasting', e.target.checked);
              }}
            />
          </Field>
          <Field label={t('assay.column.product_name')}>
            {assay.product_name}
          </Field>
        </Card.Section>
        <Card.Section>
          <Group p="md" justify="end">
            <Button type="submit">{t('common.action.save')}</Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
