import { useForm, usePage } from '@inertiajs/react';
import { Button, Card, Group, MultiSelect, Select } from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import { useStageOfChangeOptions } from '@/hooks';

interface Condition {
  name: string;
  disease_identifier: string;
  group_identifier: string;
  response: boolean;
}

interface HealthProfile {
  alcohol_per_week: string;
  dietary_allergies: string[];
  dietary_preferences: string[];
  stage_of_change: string;
  health_conditions: Condition[];
}

interface ResponseOptions {
  alcohol_per_week: string[];
  dietary_allergies: string[];
  dietary_preferences: string[];
  health_conditions: Condition[];
}

interface PageProps {
  client: {
    id: number;
  };
  health_profile: HealthProfile;
  response_options: ResponseOptions;
}

export function HealthProfileForm() {
  const { t } = useTranslation();

  const {
    client,
    health_profile: healthProfile,
    response_options: responseOptions,
  } = usePage<PageProps>().props;

  const { data, setData, patch, errors } = useForm(healthProfile);

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    patch(`/provider/clients/${client.id}/health_profile`);
  };

  const stageOfChangeOptions = useStageOfChangeOptions();

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section>
          <Field label={t('health_profile.column.alcohol_per_week')}>
            <Select
              name="alchohol_per_week"
              value={
                data.alcohol_per_week !== null ? data.alcohol_per_week : ''
              }
              onChange={value => {
                setData('alcohol_per_week', value);
              }}
              data={responseOptions.alcohol_per_week}
              error={errors.alcohol_per_week}
            />
          </Field>
          <Field label={t('health_profile.column.dietary_allergies')}>
            <MultiSelect
              onChange={value => {
                setData('dietary_allergies', value);
              }}
              value={
                data.dietary_allergies != null ? data.dietary_allergies : []
              }
              data={responseOptions.dietary_allergies}
              error={errors.dietary_allergies}
            />
          </Field>
          <Field label={t('health_profile.column.dietary_preferences')}>
            <MultiSelect
              onChange={value => {
                setData('dietary_preferences', value);
              }}
              value={
                data.dietary_preferences != null ? data.dietary_preferences : []
              }
              data={responseOptions.dietary_preferences}
              error={errors.dietary_preferences}
            />
          </Field>
          <Field label={t('health_profile.column.physical_activity')}>
            <Select
              name="stage_of_change"
              value={data.stage_of_change}
              onChange={value => {
                setData('stage_of_change', value);
              }}
              error={errors.stage_of_change}
              data={stageOfChangeOptions}
            />
          </Field>
          <Field label={t('health_profile.column.health_conditions')}>
            <MultiSelect
              error={errors.health_conditions}
              onChange={value => {
                let finalConditions = responseOptions.health_conditions;

                finalConditions = finalConditions.map(condition => ({
                  ...condition,
                  response: value.includes(condition.name),
                }));
                setData('health_conditions', finalConditions);
              }}
              value={data.health_conditions
                .filter(condition => condition.response)
                .map(condition => condition.name)}
              data={Object.values(responseOptions.health_conditions).map(
                option => option.name,
              )}
            />
          </Field>
        </Card.Section>
        <Card.Section>
          <Group justify="end" p="md">
            <Button type="submit">{t('common.action.save')}</Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
