interface Props {
  character: string;
  string: string;
}

export const FormatText = ({ character, string }: Props) =>
  string
    .split(character)
    .flatMap((item, index) =>
      index === 0 ? (
        <span key={index}>{item}</span>
      ) : (
        [<br key={`${index}_break`} />, <span key={index}>{item}</span>]
      ),
    );
