import { ActionIcon } from '@mantine/core';
import { FaRegQuestionCircle } from 'react-icons/fa';

interface Props {
  href: string;
}

export function Help({ href }: Props) {
  return (
    <ActionIcon
      c="dimmed"
      radius="xl"
      variant="light"
      component="a"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaRegQuestionCircle />
    </ActionIcon>
  );
}
