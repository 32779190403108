import { Grid, ScrollArea, Text } from '@mantine/core';
import { type ReactElement, type ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getActionPlan } from '@/api/provider';
import { useUser } from '@/hooks';
import { Provider as Layout } from '@/layouts';
import type { Action, Client, Provider } from '@/types';

import { SubHeader } from '../_SubHeader';
import { ActionPlanContent } from './_Content';
import { NavGroup } from './_NavGroup';

interface Props {
  client: Client;
}

function ActionPlan({ client }: Props) {
  const { t } = useTranslation();

  const provider = useUser<Provider>();

  const { isLoading, data } = useQuery(
    ['ActionPlan'],
    async () =>
      await getActionPlan(
        { clientId: client.id },
        {
          email: provider.email,
          apiKey: provider.api_key,
        },
      ),
  );

  const [selectedActionDetail, setSelectedActionDetail] =
    useState<Action | null>(null);
  useEffect(() => {
    if (data && data.recommendations) {
      const recommendations = data.recommendations;
      for (const key of Object.keys(recommendations).sort()) {
        if (recommendations[key].length > 0) {
          setSelectedActionDetail(recommendations[key][0]);
          break;
        }
      }
    }
  }, [data]);

  if (isLoading) {
    return (
      <Text ta="center" p="xl">
        {t('common.loading')}
      </Text>
    );
  }
  if (data) {
    return (
      <Grid gutter="xl">
        <Grid.Col span={{ base: 13, sm: 3 }}>
          <ScrollArea h={1000} pr={3}>
            <NavGroup
              recommendations={data.recommendations}
              value={selectedActionDetail}
              onChange={index => {
                setSelectedActionDetail(index);
              }}
            />
          </ScrollArea>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 9 }}>
          <ScrollArea h={1000} pr={3}>
            <ActionPlanContent
              actionPlanIdentifier={data.id}
              action={selectedActionDetail}
            />
          </ScrollArea>
        </Grid.Col>
      </Grid>
    );
  }
  return (
    <Text p="xl" ta="center">
      {t('action_plan.no_plan.no_plan_found')}
    </Text>
  );
}

ActionPlan.layout = (page: ReactNode & { props: Props }): ReactElement => (
  <Layout subHeader={<SubHeader client={page.props.client} />} page={page} />
);
export default ActionPlan;
