import { Page } from '@/components';
import type { Company } from '@/types';

import { Form } from './_Form';

interface Props {
  company: Company;
}

export default function New({ company }: Props) {
  return (
    <Page title="Edit Company">
      <Form company={company} />
    </Page>
  );
}
