import { useForm } from '@inertiajs/react';
import { Button, Card, Group, Textarea } from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import type { Batch } from '@/types';

interface Props {
  batch: Batch;
}

export function Notes({ batch }: Props) {
  const { t } = useTranslation();

  const { data, setData, patch, processing } = useForm({ notes: batch.notes });

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    patch(`/vendor_representative/batches/${batch.id}`, {
      preserveScroll: page => page.props.errors != null,
    });
  };

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section p="md" withBorder>
          {t('batch.column.notes')}
        </Card.Section>
        <Card.Section p="md" withBorder>
          <Textarea
            name="notes"
            value={data.notes}
            onChange={e => {
              setData('notes', e.target.value);
            }}
            disabled={processing}
          />
        </Card.Section>
        <Card.Section>
          <Group justify="end" p="md">
            <Button disabled={processing} type="submit">
              {t('common.action.save')}
            </Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
