import { Link } from '@inertiajs/react';
import {
  Card,
  Container,
  Group,
  NativeSelect,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { RiSearchLine } from 'react-icons/ri';

import { Empty, Risk, Score } from '@/components';
import { useSearch } from '@/hooks';
import {
  client_reports_health_datum_path,
  client_tests_index_path,
} from '@/routes';
import { sortHealthAreas } from '@/selectors';
import type { HealthArea } from '@/types';
import { formatDelta } from '@/utils';

import { Menu } from '../_Menu';

interface Props {
  health_data: {
    results: HealthArea[];
  };
}

export default function Index({ health_data: healthData }: Props) {
  const { t } = useTranslation();

  const { setQuery, setSort, results } = useSearch(
    ['name'],
    healthData.results,
    sortHealthAreas,
  );

  const options = [
    { label: 'Lowest score', value: 'lowest_score' },
    { label: 'Highest score', value: 'highest_score' },
    { label: 'Alphabetically', value: 'alphabetically' },
  ];

  return (
    <Container size="sm">
      <Stack gap="xl">
        <Menu />
        <Stack gap="xs">
          <Title size="h2">{t('health_data.headline')}</Title>
          <Text>{t('health_data.strapline')}</Text>
        </Stack>
        {healthData.results.length > 0 && (
          <Group>
            <NativeSelect
              data={options}
              onChange={e => setSort(e.target.value)}
            />
            <TextInput
              placeholder={t('health_data.placeholder.query')}
              leftSection={<RiSearchLine />}
              onChange={e => setQuery(e.target.value)}
            />
          </Group>
        )}
        {results.length > 0 ? (
          <Stack gap="sm">
            {results.map(result => (
              <Card
                key={result.myco_id}
                p="lg"
                component={Link}
                href={client_reports_health_datum_path(result.myco_id)}
              >
                <Stack gap="lg">
                  <Group justify="space-between" wrap="nowrap">
                    <Text truncate>{result.name}</Text>
                    <Group gap="sm" wrap="nowrap">
                      <Text size="sm">{formatDelta(result.delta)}</Text>
                      <Score
                        color={result.color}
                        score={result.percentage_risk}
                      />
                    </Group>
                  </Group>
                  <Risk color={result.color} score={result.percentage_risk} />
                </Stack>
              </Card>
            ))}
          </Stack>
        ) : (
          <Empty
            headline={t('report.no_data.headline')}
            strapline={t('report.no_data.strapline')}
            href={client_tests_index_path()}
          />
        )}
      </Stack>
    </Container>
  );
}
