import type { PageProps } from '@inertiajs/core';
import { usePage } from '@inertiajs/react';

interface Flash {
  error?: string;
  alert?: string;
  notice?: string;
}

interface Props extends PageProps {
  flash?: Flash;
}

export function useFlash(): Flash | null {
  const page = usePage<Props>();

  if (page.props.flash != null) {
    return page.props.flash;
  }

  return null;
}
