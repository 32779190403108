import { Grid } from '@mantine/core';

import type { Biofunction } from '@/types';

import { BiofunctionCollapsibleCards } from './_BiofunctionCollapsibleCards';
import { BiomarkerDetails } from './_BiomarkerDetails';

interface Props {
  data: Biofunction;
  selected: number | null;
  handleSelect: (id: number) => void;
  reportId: number;
}

export function BiofunctionDetailsWeb({
  data,
  selected,
  handleSelect,
  reportId,
}: Props) {
  return (
    <Grid gutter="lg">
      <Grid.Col span={6}>
        <BiofunctionCollapsibleCards
          data={data}
          selected={selected}
          handleSelect={handleSelect}
        />
      </Grid.Col>

      <Grid.Col span={6}>
        <BiomarkerDetails id={selected} reportId={reportId} />
      </Grid.Col>
    </Grid>
  );
}
