import { Text } from '@mantine/core';

import classes from './_Logo.module.css';

export function Logo() {
  return (
    <Text size="xl" py="12px" lh="36px" fw={700} className={classes.root}>
      molecular{' '}
      <Text component="span" c="brand" fw={700}>
        you
      </Text>
    </Text>
  );
}
