import { Card } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import type { Company } from '@/types';

interface Props {
  company: Company;
}

export function Details({ company }: Props) {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Section>
        <Field label={t('common.column.id')}>{company.id}</Field>
        <Field label={t('company.column.name')}>{company.name}</Field>
      </Card.Section>
    </Card>
  );
}
