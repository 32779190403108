import axios from 'axios';

import type { Biofunction } from '@/types';
import { getPostHeaders } from '@/utils';

interface Params {
  id: string;
  clientId: number;
}

export const getBiofunctionDetails = async ({
  id,
  clientId,
}: Params): Promise<Biofunction> =>
  await axios
    .get(
      `${window.location.origin}/provider/clients/${clientId}/biofunctions/${id}`,
      {
        headers: {
          ...getPostHeaders(),
        },
        params: {
          fields:
            'history,health_report_sample_concentrations,general_description,biofunction_name',
        },
      },
    )
    .then(({ data }) => data.biofunction);
