import { Box, Card, Group, Stack, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaCircle } from 'react-icons/fa';

import Icon from '@/components/Icon';
import type { Action } from '@/types';
import { getColor } from '@/utils';

interface Props {
  actionPlanIdentifier: string;
  action: Action | null;
}
export function ActionPlanContent({ action }: Props) {
  const { t } = useTranslation();

  if (action) {
    return (
      <Stack gap="lg">
        <Box>
          <Text
            size="sm"
            c={action?.action === 'avoid' ? 'red' : 'blue'}
            tt="uppercase"
          >
            {action.action}
          </Text>
          <Title size="h2" tt="capitalize">
            {action.name}
          </Title>
          <Text>{action.description}</Text>
        </Box>
        {action.action && (
          <Card>
            <Stack>
              <Title size="h4">{t('action_plan.targets.header')}</Title>
              <Text>{t('action_plan.targets.sub_text')}</Text>
              {action.target_biomarkers.length > 0 ? (
                <Stack gap="sm" mt="lg">
                  <Title size="h5">{t('action_plan.biomarkers.header')}</Title>
                  {action.target_biomarkers
                    .sort((a, b) => {
                      if (a.color === 'red' && b.color === 'yellow') return -1;
                      if (a.color === 'yellow' && b.color === 'red') return 1;
                      return 0;
                    })
                    .map((area, index) => (
                      <Group key={index}>
                        <FaCircle size={15} color={getColor(area.color)} />
                        <Text>{area.name}</Text>
                      </Group>
                    ))}
                </Stack>
              ) : null}
            </Stack>

            {action.target_health_areas.length > 0 ? (
              <Stack mt="lg">
                <Title size="h5">{t('action_plan.health_areas.header')}</Title>
                {action.target_health_areas.map((area, index) => (
                  <Group key={index}>
                    <FaCircle size={15} color={getColor(area.color)} />
                    <Text>{area.name}</Text>
                  </Group>
                ))}
              </Stack>
            ) : null}
          </Card>
        )}
        {action.options &&
          (() => {
            const options = JSON.parse(action.options);
            return options.length > 0 ? (
              <Card>
                <Stack>
                  <Title size="h4" p="sm">
                    {t('action_plan.examples.header')}
                  </Title>
                  {options.map((option, index) => (
                    <Box key={index} p="sm">
                      <Group>
                        <Icon iconId={option.icon_id} />
                        <Text>{option.name}</Text>
                      </Group>
                      <Text c="dimmed">{option.note}</Text>
                    </Box>
                  ))}
                </Stack>
              </Card>
            ) : null;
          })()}
      </Stack>
    );
  }

  return (
    <Text p="xl" ta="center">
      {t('action_plan.no_actions')}
    </Text>
  );
}
