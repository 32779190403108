import { Link, usePage } from '@inertiajs/react';
import {
  AppShell,
  Box,
  Container,
  Group,
  Menu,
  Stack,
  Text,
} from '@mantine/core';
import type { PropsWithChildren, ReactNode } from 'react';

import { usePermissions } from '@/hooks';

import classes from './_Base.module.css';
import { Flash } from './_Flash';

type Props = PropsWithChildren<{
  brand?: ReactNode;
  links?: {
    href: string;
    label: string;
    privilege?: string;
    links?: {
      href: string;
      label: string;
      privilege?: string;
    }[];
  }[];
  menu?: ReactNode;
  subHeader?: ReactNode;
  title?: string;
  actions?: ReactNode;
  containerSize?: string;
}>;

export function Base({
  brand,
  menu,
  links,
  title,
  actions,
  subHeader,
  containerSize = 'xl',
  children,
}: Props) {
  const { url } = usePage();

  const { hasPrivilege } = usePermissions();

  const items = links
    ?.filter(link => link.privilege == null || hasPrivilege(link.privilege))
    .map(link => {
      const menuItems = link.links
        ?.filter(link => link.privilege == null || hasPrivilege(link.privilege))
        .map(link => (
          <Menu.Item key={link.href} component={Link} href={link.href}>
            {link.label}
          </Menu.Item>
        ));

      if (menuItems != null) {
        return (
          <Menu
            key={link.label}
            trigger="hover"
            transitionProps={{ exitDuration: 0 }}
            withinPortal
          >
            <Menu.Target>
              <Link
                href={link.href}
                className={classes.mainLink}
                data-active={
                  link.links?.some(l => url.startsWith(l.href)) ?? undefined
                }
              >
                {link.label}
              </Link>
            </Menu.Target>
            <Menu.Dropdown>{menuItems}</Menu.Dropdown>
          </Menu>
        );
      }

      return (
        <Link
          key={link.label}
          href={link.href}
          className={classes.mainLink}
          data-active={url.startsWith(link.href) ?? undefined}
        >
          {link.label}
        </Link>
      );
    });

  return (
    <AppShell
      header={{ height: subHeader != null ? 156 : 60 }}
      className={classes.root}
    >
      <AppShell.Header>
        <Container size={containerSize}>
          <Group justify="space-between" gap="lg">
            <Group gap="lg">
              {brand}
              <Group gap={5}>{items}</Group>
            </Group>
            {menu}
          </Group>
        </Container>
        {subHeader != null && (
          <Container size={containerSize} px="xl" className={classes.subHeader}>
            {subHeader}
          </Container>
        )}
      </AppShell.Header>
      <AppShell.Main>
        <Box py="xl" px={{ sm: 'xl' }}>
          <Container size={containerSize}>
            {title != null && (
              <Group justify="space-between" mb="xl">
                <Text size="xl">{title}</Text>
                {actions}
              </Group>
            )}
            <Stack gap="xl">
              <Flash />
              {children}
            </Stack>
          </Container>
        </Box>
      </AppShell.Main>
    </AppShell>
  );
}
