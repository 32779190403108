import { Card } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { BatchStateField, Field, FileField } from '@/components';
import type { Batch, Vendor } from '@/types';

interface Props {
  batch: Batch & {
    vendor?: Vendor;
    links?: {
      download_vendor_data: string;
      download_vendor_report: string;
      download_qc_flags: string;
    };
  };
}

export function Details({ batch }: Props) {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Section>
        <Field label={t('batch.column.name')}>{batch.name}</Field>
        <Field label={t('batch.column.state')}>
          <BatchStateField value={batch.state} />
        </Field>
        <Field label={t('vendor.singular')}>{batch.vendor?.name}</Field>
        <Field label={t('batch.column.vendor_batch_number')}>
          {batch.vendor_batch_number}
        </Field>
        <Field label={t('batch.column.qc_version')}>{batch.qc_version}</Field>
        <Field label={t('batch.column.data_file_submitter')}>
          {batch.data_file_submitter?.name}
        </Field>
        <Field label={t('batch.column.vendor_sign_off_submitter')}>
          {batch.vendor_sign_off_submitter?.name}
        </Field>
        <Field label={t('batch.column.qc_review_submitter')}>
          {batch.qc_review_submitter?.name}
        </Field>
        <Field label={t('batch.link.download_vendor_data')}>
          <FileField value={batch.links?.download_vendor_data} />
        </Field>
        <Field label={t('batch.link.download_vendor_report')}>
          <FileField value={batch.links?.download_vendor_report} />
        </Field>
        <Field label={t('batch.link.download_qc_flags')}>
          <FileField value={batch.links?.download_qc_flags} />
        </Field>
        <Field label={t('batch.column.samples_barcodes')}>
          {batch.samples_barcodes?.join(', ')}
        </Field>
        <Field label={t('batch.column.notes')}>{batch.notes}</Field>
      </Card.Section>
    </Card>
  );
}
