import { Link } from '@inertiajs/react';
import { Button, Card, Menu } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaEllipsisV } from 'react-icons/fa';

import { DataGrid, Page, Pagination, Toolbar } from '@/components';
import type { EmailTemplate } from '@/types';

interface Props {
  email_templates: EmailTemplate[];
}

export default function Index(props: Props) {
  const { t } = useTranslation();

  const searchCols = ['name', 'internal_key', 'description'];

  return (
    <Page
      title="Email Template"
      actions={
        <Button component={Link} href="/admin/email_templates/new">
          Create Email Template
        </Button>
      }
    >
      <Card>
        <Card.Section>
          <Toolbar searchCols={searchCols} />
          <DataGrid
            data={props.email_templates}
            columns={[
              {
                header: t('common.column.id'),
                accessorFn: row => row.id,
              },
              {
                header: t('email_template.column.name'),
                accessorFn: row => row.name,
              },
              {
                header: t('email_template.column.internal_key'),
                accessorFn: row => row.internal_key,
              },
              {
                header: t('email_template.column.sendgrid_template_id'),
                accessorFn: row => row.sendgrid_template_id,
              },
              {
                header: t('email_template.column.cc_addresses'),
                accessorFn: row => row.cc_addresses,
              },
              {
                header: t('email_template.column.bcc_addresses'),
                accessorFn: row => row.bcc_addresses,
              },
              {
                header: t('email_template.column.active'),
                accessorFn: row => t(`common.${row.active ? 'yes' : 'no'}`),
              },
              {
                id: 'actions',
                cell: ({ row }) => (
                  <Menu>
                    <Menu.Target>
                      <span>
                        <FaEllipsisV />
                      </span>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        component={Link}
                        href={`/admin/email_templates/${row.original.id}`}
                      >
                        {t(`common.action.show`)}
                      </Menu.Item>
                      <Menu.Item
                        component={Link}
                        href={`/admin/email_templates/${row.original.id}/edit`}
                      >
                        {t(`common.action.edit`)}
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
          <Pagination />
        </Card.Section>
      </Card>
    </Page>
  );
}
