import { Radio, Table } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface Props {
  value: Record<string, boolean>;
  onChange: (e: { name: string; value: boolean }) => void;
  options: string[];
}

export function PrivilegeSetInput({ value, onChange, options }: Props) {
  const { t } = useTranslation();

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Privilege</Table.Th>
          <Table.Th align="center">{t('common.action.no')}</Table.Th>
          <Table.Th align="center">{t('common.action.yes')}</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {options.map(option => (
          <Table.Tr key={option}>
            <Table.Td>{t(`provider.privilege.${option}`)}</Table.Td>
            <Table.Td align="center">
              <Radio
                onChange={() => {
                  onChange({ name: option, value: false });
                }}
                checked={!value[option]}
              />
            </Table.Td>
            <Table.Td align="center">
              <Radio
                onChange={() => {
                  onChange({ name: option, value: true });
                }}
                checked={value[option]}
              />
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
}
