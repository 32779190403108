import { useForm } from '@inertiajs/react';
import { Button, Card, Group, TextInput } from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import type {
  CollectionSite,
  CollectionSiteCollectionSiteGroup,
  CollectionSiteGroup,
} from '@/types';
import { nestedAttributes } from '@/utils';

import { CollectionSites } from './_CollectionSites';

interface Props {
  collectionSiteGroup: CollectionSiteGroup & {
    collection_site_collection_site_groups?: CollectionSiteCollectionSiteGroup[];
  };
  collectionSite: CollectionSite[];
}

export function Form({ collectionSiteGroup, collectionSite }: Props) {
  const { t } = useTranslation();

  const { data, setData, submit, errors, transform } =
    useForm(collectionSiteGroup);

  // Rename `collection_site_collection_site_groups` to `collection_site_collection_site_groups_attributes` before submitting.
  transform(nestedAttributes(['collection_site_collection_site_groups']));

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    submit(
      collectionSiteGroup.id != null ? 'patch' : 'post',
      `/admin/collection_site_groups/${collectionSiteGroup.id ?? ''}`,
    );
  };
  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section>
          <Field label={t('collection_site.column.name')} required>
            <TextInput
              required
              value={data.name}
              onChange={e => {
                setData('name', e.target.value);
              }}
              error={errors.name}
            />
          </Field>
        </Card.Section>
        <Card.Section>
          <CollectionSites
            collectionSites={collectionSite}
            errors={errors}
            value={data.collection_site_collection_site_groups ?? []}
            onChange={value => {
              setData('collection_site_collection_site_groups', value);
            }}
          />
        </Card.Section>
      </Card>
      <Card>
        <Card.Section>
          <Group justify="end">
            <Button type="submit">{t('common.action.save')}</Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
