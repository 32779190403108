import { Box, Grid, SimpleGrid, Stack, Text } from '@mantine/core';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SampleConcentrationHistory } from '@/charts';
import { CardWithTab } from '@/components';
import { selectSampleConcentrationHistory } from '@/selectors';
import type { Measure, SampleConcentration } from '@/types';
import { formatRange, formatValue } from '@/utils';

interface Props {
  defaultIsOpen?: boolean;
  showWarning?: boolean;
  sampleConcentration: SampleConcentration & {
    biomarker_table: (Measure | null)[];
  };
}

export function SampleConcentrationCard({
  defaultIsOpen = false,
  showWarning = false,
  sampleConcentration,
}: Props) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const data = useMemo(
    () => selectSampleConcentrationHistory(sampleConcentration.biomarker_table),
    [sampleConcentration.biomarker_table],
  );
  return (
    <CardWithTab
      color={sampleConcentration.color}
      onClick={() => setIsOpen(!isOpen)}
      style={{ overflow: 'visible' }}
    >
      <Text>{sampleConcentration.measure_name}</Text>
      {isOpen && (
        <Grid gutter="xl">
          <Grid.Col span={{ base: 12, md: 8 }}>
            <Stack gap="xs">
              <SimpleGrid cols={2}>
                <Box>
                  <Text size="sm">{t('biomarker.column.value')}</Text>
                  <Text fw={700}>
                    {formatValue(
                      sampleConcentration.value,
                      sampleConcentration.unit,
                      sampleConcentration.percentile,
                    )}
                  </Text>
                </Box>
                <Box>
                  <Text size="sm">{t('biomarker.column.range')}</Text>
                  <Text>
                    {formatRange(
                      sampleConcentration.range,
                      sampleConcentration.unit,
                      sampleConcentration.percentile,
                    )}
                  </Text>
                </Box>
              </SimpleGrid>
              <Text size="sm">{sampleConcentration.normal_description}</Text>
              <Text size="sm">
                <u>{t('biomarker.column.nutrition_description')}:</u>{' '}
                {sampleConcentration.nutrition_description}
              </Text>
              {sampleConcentration.associated_health_areas != null &&
                sampleConcentration.associated_health_areas.length > 0 && (
                  <Text size="sm">
                    <u>{t('biomarker.column.associated_health_areas')}:</u>{' '}
                    {sampleConcentration.associated_health_areas.join(', ')}
                  </Text>
                )}
            </Stack>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4 }}>
            <Box h="200">
              <SampleConcentrationHistory data={data} />
            </Box>
          </Grid.Col>
        </Grid>
      )}
      {showWarning &&
        !sampleConcentration.indicated &&
        sampleConcentration.color !== 'green' && (
          <Text size="xs" c="dimmed">
            {t('report.biomarker.low_high_warning', {
              level: sampleConcentration.level === 'low' ? 'high' : 'low',
            })}
          </Text>
        )}
    </CardWithTab>
  );
}
