import { Card, Group, Table, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import type { Kit } from '@/types';

interface Props {
  value: Kit[];
}

export function KitsDisplay({ value = [] }: Props) {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Section withBorder>
        <Group p="md">
          <Text>{t('kit.plural')}</Text>
        </Group>
      </Card.Section>
      <Card.Section>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{t('kit.column.barcode_id')}</Table.Th>
              <Table.Th>{t('kit.column.shipped_on')}</Table.Th>
              <Table.Th>{t('kit.column.expiry_date')}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {value.map(kit => (
              <Table.Tr key={kit.id}>
                <Table.Td>{kit.barcode_id}</Table.Td>
                <Table.Td>{kit.shipped_on}</Table.Td>
                <Table.Td>{kit.expiry_date}</Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Card.Section>
    </Card>
  );
}
