import { useForm } from '@inertiajs/react';
import {
  Badge,
  Button,
  Card,
  Group,
  Table,
  Text,
  UnstyledButton,
} from '@mantine/core';
import dayjs from 'dayjs';
import { pick, set } from 'lodash';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrashAlt } from 'react-icons/fa';

import type { Assay, Batch, Sample } from '@/types';
import { formatDate } from '@/utils';

import { SampleAutocomplete } from './_SampleAutocomplete';

interface Props {
  batch: Batch & {
    samples?: Sample[];
  };
  samples: (Sample & {
    assay?: Assay;
  })[];
}

export function Samples({ batch, samples }: Props) {
  const { t } = useTranslation();

  const { data, setData, patch, transform, processing } = useForm({
    samples: [...(batch.samples ?? []), ...samples],
  });

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    patch(`/admin/batches/${batch.id}/update_samples`);
  };

  // Rename `samples` to `samples_attributes` before submitting.
  transform(data => ({
    samples_attributes: data.samples.map(sample =>
      pick(sample, ['id', 'batch_id']),
    ),
  }));

  const updateSample = (id: number, path: keyof Sample, value: unknown) => {
    setData(data => ({
      samples: data.samples?.map(item =>
        item.id === id ? set(item, path, value) : item,
      ),
    }));
  };

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section withBorder>
          <Group p="md" justify="space-between">
            <Text>
              {t('sample.plural')} (
              {data.samples.filter(s => s.batch_id != null).length})
            </Text>
          </Group>
        </Card.Section>
        <Card.Section withBorder>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{t('sample.column.id')}</Table.Th>
                <Table.Th>{t('client.column.identifier')}</Table.Th>
                <Table.Th>{t('assay.column.code')}</Table.Th>
                <Table.Th>{t('sample.column.sample_barcode')}</Table.Th>
                <Table.Th>{t('sample.column.sample_status')}</Table.Th>
                <Table.Th>{t('sample.column.sample_type')}</Table.Th>
                <Table.Th>{t('sample.column.profile')}</Table.Th>
                <Table.Th>{t('sample.column.collection_notes')}</Table.Th>
                <Table.Th>{t('sample.column.received_at')}</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data.samples
                ?.filter(sample => sample.batch_id !== null)
                .map(sample => (
                  <Table.Tr key={sample.id}>
                    <Table.Td>{sample.id}</Table.Td>
                    <Table.Td>
                      {sample.assay != null && (
                        <Badge>{sample.assay.client?.identifier}</Badge>
                      )}
                    </Table.Td>
                    <Table.Td>
                      {sample.assay != null && (
                        <Badge>{sample.assay.code}</Badge>
                      )}
                    </Table.Td>
                    <Table.Td>{sample.sample_barcode}</Table.Td>
                    <Table.Td>{sample.sample_status}</Table.Td>
                    <Table.Td>{sample.sample_type}</Table.Td>
                    <Table.Td>{sample.profile}</Table.Td>
                    <Table.Td>{sample.collection_notes ?? '—'}</Table.Td>
                    <Table.Td>{formatDate(sample.received_at) ?? '—'}</Table.Td>
                    <Table.Td>
                      <UnstyledButton
                        onClick={() => {
                          if (
                            confirm(
                              t('sample.confirm.delete', { id: sample.id }),
                            )
                          ) {
                            updateSample(sample.id, 'batch_id', null);
                          }
                        }}
                      >
                        <FaTrashAlt />
                      </UnstyledButton>
                    </Table.Td>
                  </Table.Tr>
                ))}
              <Table.Tr>
                <Table.Td colSpan={9}>
                  <SampleAutocomplete
                    placeholder={t('sample.column.sample_barcode')}
                    onOptionSubmit={value => {
                      updateSample(Number(value), 'batch_id', batch.id);
                    }}
                    data={data.samples
                      .filter(sample => sample.batch_id == null)
                      .sort(
                        (a, b) =>
                          dayjs(b.received_at).unix() -
                          dayjs(a.received_at).unix(),
                      )
                      .map(sample => ({
                        value: sample.id.toString(),
                        label: `${sample.sample_barcode} / ${
                          formatDate(sample.received_at) ?? ''
                        }`,
                      }))}
                  />
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </Card.Section>
        <Card.Section>
          <Group justify="end" p="md">
            <Button type="submit" disabled={processing}>
              {t('common.action.save')}
            </Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
