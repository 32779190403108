import { Link } from '@inertiajs/react';
import {
  Anchor,
  Box,
  Button,
  Card,
  List,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import type { Libraries } from '@react-google-maps/api';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';

import { FormatText } from '@/components';
import { useTrackClientEvent } from '@/hooks';
import {
  client_download_requisition_form_path,
  client_email_collection_site_details_path,
  client_tests_collection_sites_path,
  client_tests_index_path,
} from '@/routes';
import type { CollectionSite } from '@/types';

interface Props {
  collectionSite: CollectionSite;
  apiKey: string;
}

const libraries: Libraries = ['places'];

export const CollectionSiteSummary = ({ collectionSite, apiKey }: Props) => {
  const { t } = useTranslation();

  const trackEvent = useTrackClientEvent();

  const { isLoaded } = useJsApiLoader({
    id: 'google-maps-script',
    googleMapsApiKey: apiKey,
    libraries,
  });

  return (
    <Card p="xl">
      <Stack gap="lg">
        <Title size="h2">
          {t('onboarding.collection_site.attend_blood_collection')}
        </Title>

        <Box
          h={300}
          style={{
            borderRadius: 'var(--mantine-radius-default)',
            overflow: 'hidden',
          }}
        >
          {isLoaded && collectionSite != null && (
            <GoogleMap
              center={{
                lat: Number(collectionSite.latitude),
                lng: Number(collectionSite.longitude),
              }}
              zoom={13}
              mapContainerStyle={{
                width: '100%',
                height: '400px',
              }}
            >
              <Marker
                position={{
                  lat: Number(collectionSite.latitude),
                  lng: Number(collectionSite.longitude),
                }}
              />
            </GoogleMap>
          )}
        </Box>
        <Box>
          <Title size="h4">
            {t('onboarding.collection_site.your_collection_site')}
          </Title>
          <Text>{collectionSite.name}</Text>
          <Text size="md" c="dimmed">
            {collectionSite.full_address}
          </Text>
          <Anchor
            display="block"
            component={Link}
            href={client_tests_collection_sites_path({
              params: { back: client_tests_index_path() },
            })}
            rel="noopener noreferrer"
            fw={700}
            onClick={() => {
              trackEvent('find_another_location', {});
            }}
          >
            {t('onboarding.collection_site.find_another_location')}
          </Anchor>
          <Anchor
            display="block"
            href={`https://www.google.com/maps/dir/Current+Location/${collectionSite.latitude as number},${collectionSite.longitude as number}`}
            target="_blank"
            rel="noopener noreferrer"
            fw={700}
          >
            {t('onboarding.collection_site.get_directions')}
          </Anchor>
        </Box>

        {collectionSite?.opening_hours != null && (
          <Box>
            <Title size="h4">
              {t('onboarding.collection_site.opening_hours')}
            </Title>
            <Text size="md">
              {collectionSite.opening_hours != null ? (
                <FormatText
                  character="\n"
                  string={collectionSite.opening_hours}
                />
              ) : null}
            </Text>
          </Box>
        )}
        <Box>
          <Title size="h4">{t('onboarding.collection_site.how_to_book')}</Title>
          {collectionSite?.booking_preference === 'Phone' && (
            <Text>
              {t('onboarding.collection_site.booking_preference.phone', {
                phone: collectionSite.phone,
              })}
            </Text>
          )}
          {collectionSite?.booking_preference === 'Drop-in' && (
            <Text>
              {t('onboarding.collection_site.booking_preference.drop_in')}
            </Text>
          )}
          {collectionSite?.booking_preference === 'Book Online' && (
            <Text>
              {t('onboarding.collection_site.booking_preference.book_online')}
              <Anchor
                href={collectionSite.booking_site_url}
                target="_blank"
                rel="noopener noreferrer"
                fw={700}
              >
                {collectionSite.booking_site_url}
              </Anchor>
            </Text>
          )}
        </Box>
        <Box>
          <Title size="h4">
            {t('onboarding.collection_site.what_to_bring.title')}
          </Title>
          <List size="md">
            <List.Item>
              Your <u>printed</u> {collectionSite.name}{' '}
              <Anchor href={client_download_requisition_form_path()}>
                requisition form
              </Anchor>
            </List.Item>
            <List.Item>
              {' '}
              {t('onboarding.collection_site.what_to_bring.unopened_kit')}
            </List.Item>
          </List>
        </Box>
        <Box>
          <Title size="h4">
            {t('onboarding.collection_site.preparation.title')}
          </Title>
          <List size="md">
            <List.Item>
              {t('onboarding.collection_site.preparation.requirement_1')}
            </List.Item>
            <List.Item>
              {t('onboarding.collection_site.preparation.requirement_2')}
            </List.Item>
            <List.Item>
              {t('onboarding.collection_site.preparation.requirement_3')}
            </List.Item>
          </List>
        </Box>
        <Button
          component={Link}
          href={client_email_collection_site_details_path()}
          method="post"
          color="blue"
          style={{ width: 'fit-content' }}
        >
          {t('onboarding.collection_site.action.email_summary')}
        </Button>
      </Stack>
    </Card>
  );
};
