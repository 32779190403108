import { Link } from '@inertiajs/react';
import { Anchor, Button, Card, Space, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { APP_SUPPORT_EMAIL } from '@/constants';

interface Props {
  user_type: 'clients' | 'providers';
}

function Success({ user_type: userType }: Props) {
  const { t } = useTranslation();

  return (
    <Card padding="xl">
      <Text size="xl" fw={700} mb="xl">
        {t('password.success.title')}
      </Text>
      <Text size="xl">
        {t('password.success.sub_title')}{' '}
        <Anchor fw={500} href={`mailto:${APP_SUPPORT_EMAIL}`}>
          {APP_SUPPORT_EMAIL}
        </Anchor>
      </Text>
      <Space h="xl" />
      <div>
        <Button component={Link} href={`/${userType}/sign_in`}>
          {t('common.action.okay')}
        </Button>
      </div>
    </Card>
  );
}

export default Success;
