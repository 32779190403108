import { ColorSwatch, Group, Stack, Text } from '@mantine/core';

interface Props {
  passwordRequirements: {
    requirement: string;
    message: string;
    pass: boolean;
  }[];
}

export function PasswordRequirements({ passwordRequirements }: Props) {
  return (
    <Stack gap="xs">
      {passwordRequirements.map((item, i) => (
        <Group key={i} gap="xs">
          <ColorSwatch
            size={8}
            color={`var(--mantine-color-${item.pass ? 'blue' : 'gray'}-5)`}
            withShadow={false}
          />
          <Text size="xs" fw={300}>
            {item.message}
          </Text>
        </Group>
      ))}
    </Stack>
  );
}
