export const getBiomarkerOrder = (
  color: string,
  indicated: boolean,
): number => {
  switch (color) {
    case 'red':
      return indicated ? 1 : 5;
    case 'yellow':
      return indicated ? 2 : 6;
    case 'green':
      return indicated ? 3 : 4;
    default:
      return indicated ? 0 : 7;
  }
};
