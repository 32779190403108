export const getColorOrder = (color: string): number => {
  switch (color) {
    case 'red':
      return 0;
    case 'yellow':
      return 1;
    case 'green':
      return 2;
    default:
      return 3;
  }
};
