import { Page } from '@/components';
import type { CollectionSite } from '@/types';

import { Form } from './_Form';

interface Props {
  collection_site: CollectionSite;
  carmen: Record<
    string,
    {
      code: string;
      name: string;
    }[]
  >;
  api_key: string;
}

export default function New(props: Props) {
  return (
    <Page title="Create Collection Site">
      <Form
        collectionSite={props.collection_site}
        carmen={props.carmen}
        api_key={props.api_key}
      />
    </Page>
  );
}
