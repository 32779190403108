import { Page } from '@/components';
import type { Vendor, VendorRepresentative } from '@/types';

import { Form } from './_Form';

interface Props {
  vendor_representative: VendorRepresentative;
  vendors: Vendor[];
}

function New(props: Props) {
  return (
    <Page title="Create Vendor Representative">
      <Form
        vendorRepresentative={props.vendor_representative}
        vendors={props.vendors}
      />
    </Page>
  );
}

export default New;
