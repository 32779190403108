import { Alert } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Page } from '@/components';
import type { Batch } from '@/types';

import { Details } from './_Details';
import { Notes } from './_Notes';
import { QCFlags } from './_QCFlags';
import { VendorData } from './_VendorData';
import { VendorReport } from './_VendorReport';

interface Props {
  batch: Batch;
}

export default function Review({ batch }: Props) {
  const { t } = useTranslation();

  return (
    <Page title={`${t('batch.action.review')} ${t('batch.singular')}`}>
      {batch.state === 'data_file_needed' && (
        <Alert color="yellow">
          {t('batch.message.vendor_data_file_needed', {
            profile: batch.profile,
            samples: batch.samples_barcodes?.join(', '),
          })}
        </Alert>
      )}
      {batch.state === 'data_file_errors' && (
        <Alert color="red">
          {t('batch.message.vendor_data_file_errors', {
            samples: batch.samples_barcodes?.join(', '),
          })}
          <strong>{batch.error}</strong>
        </Alert>
      )}
      {batch.state === 'qc_pending' && (
        <Alert color="green">{t('batch.message.vendor_qc_pending')}</Alert>
      )}
      {batch.state === 'qc_vendor_sign_off_needed' && (
        <Alert color="green">
          {t('batch.message.vendor_qc_sign_off_needed')}
        </Alert>
      )}
      {batch.state === 'qc_review_needed' && (
        <Alert color="green">
          {t('batch.message.vendor_qc_review_needed')}
        </Alert>
      )}
      {batch.rerun_entire_batch != null && (
        <Alert color="red">
          {t('batch.message.vendor_rerun_entire_batch')}
        </Alert>
      )}
      <Details batch={batch} />
      <QCFlags batch={batch} />
      <VendorData batch={batch} />
      <VendorReport batch={batch} />
      <Notes batch={batch} />
    </Page>
  );
}
