import { Group, Text } from '@mantine/core';

import classes from './_BiomarkersListItem.module.css';

interface Props {
  onClick: () => void;
  name: string;
  children: React.ReactNode;
  selected: boolean;
}

export function BiomarkersListItem({
  onClick,
  name,
  children,
  selected,
}: Props) {
  return (
    <Group
      wrap="nowrap"
      px="lg"
      className={classes.listItem}
      data-active={selected}
      onClick={() => onClick()}
      style={{ cursor: 'pointer' }}
    >
      <Text tt="capitalize" size="md" truncate w="100%">
        {name}
      </Text>
      {children}
    </Group>
  );
}
