import { Page } from '@/components';
import type { Company, Provider, Site } from '@/types';

import { Form } from './_Form';

interface Props {
  provider: Provider;
  companies: Company[];
  sites: Site[];
}

export default function New({ provider, companies, sites }: Props) {
  return (
    <Page title="Create Provider">
      <Form provider={provider} companies={companies} sites={sites} />
    </Page>
  );
}
