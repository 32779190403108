import { Anchor, Button, Card, Input, Space, Stack, Text } from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { APP_SUPPORT_EMAIL } from '@/constants';
import { useForm } from '@/hooks';
import type { UserType } from '@/types';

interface Props {
  resource_name: UserType;
  paths: {
    password_path: string;
  };
}

function NewPasswordForm({ resource_name: resourceName, paths }: Props) {
  const { t } = useTranslation();

  const { getPath, setPath, post } = useForm<
    Record<
      UserType,
      {
        email: string;
      }
    >
  >();

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    post(paths.password_path);
  };

  return (
    <form onSubmit={save}>
      <Card padding="xl">
        <Text size="xl" fw={700} mb="xl">
          {t('password.new.title')}
        </Text>
        <Stack>
          <Text size="xl">{t('password.new.sub_title')}</Text>
          <Input
            required
            placeholder={t('password.new.email')}
            name="email"
            autoCapitalize="none"
            value={getPath(`${resourceName}.email`, '')}
            onChange={e => setPath(`${resourceName}.email`, e.target.value)}
          />
          <div>
            <Text size="xl">
              {t('password.new.help')}{' '}
              <Anchor fw="500" href={`mailto:${APP_SUPPORT_EMAIL}`}>
                {APP_SUPPORT_EMAIL}
              </Anchor>
            </Text>
          </div>
        </Stack>
        <Space h="xl" />
        <div>
          <Button type="submit">{t('password.new.reset_password')}</Button>
        </div>
      </Card>
    </form>
  );
}

export default NewPasswordForm;
