import { Link } from '@inertiajs/react';
import { Group, Text, UnstyledButton } from '@mantine/core';
import { RiArrowLeftSLine } from 'react-icons/ri';

import { useQueryParams } from '@/hooks';

interface Props {
  label: string;
  to: string;
}

export function Back({ label, to }: Props) {
  const queryParams = useQueryParams();

  return (
    <UnstyledButton component={Link} href={queryParams.get('back') ?? to}>
      <Group gap="xs">
        <RiArrowLeftSLine size="32" />
        <Text size="xl" fw={700}>
          {label}
        </Text>
      </Group>
    </UnstyledButton>
  );
}
