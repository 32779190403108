import { useState } from 'react';

import type { Nullable } from '@/types';

export const useTooltip = <T>(data: Nullable<T>) => {
  const [tooltip, setTooltip] = useState({
    data,
    position: {
      left: 0,
      top: 0,
    },
  });

  const show = (data: T, left: number, top: number) =>
    setTooltip(tooltip => ({
      ...tooltip,
      data,
      position: {
        left,
        top,
      },
    }));

  const hide = () =>
    setTooltip(tooltip => ({
      ...tooltip,
      position: {
        left: 0,
        top: 0,
      },
    }));

  return {
    tooltip,
    setTooltip,
    show,
    hide,
  };
};
