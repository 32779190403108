import { useForm } from '@inertiajs/react';
import { Button, Card, Group, Select, TextInput } from '@mantine/core';
import type { SyntheticEvent } from 'react';

import { Field } from '@/components';
import type { Vendor, VendorRepresentative } from '@/types';

interface Props {
  vendorRepresentative: VendorRepresentative;
  vendors: Vendor[];
}

export function Form({ vendorRepresentative, vendors }: Props) {
  const { data, setData, submit, errors } = useForm(vendorRepresentative);

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    submit(
      vendorRepresentative.id != null ? 'patch' : 'post',
      `/admin/vendor_representatives/${vendorRepresentative.id ?? ''}`,
    );
  };

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section>
          <Field label="Vendor">
            <Select
              value={data.vendor_id?.toString()}
              onChange={value => {
                setData('vendor_id', Number(value));
              }}
              data={vendors.map(vendor => ({
                value: vendor.id.toString(),
                label: vendor.name,
              }))}
            />
          </Field>
          <Field label="First Name">
            <TextInput
              required
              value={data.first_name}
              onChange={e => {
                setData('first_name', e.target.value);
              }}
              error={errors.first_name}
            />
          </Field>
          <Field label="Last Name">
            <TextInput
              required
              value={data.last_name}
              onChange={e => {
                setData('last_name', e.target.value);
              }}
              error={errors.last_name}
            />
          </Field>
          <Field label="Email">
            <TextInput
              required
              value={data.email}
              onChange={e => {
                setData('email', e.target.value);
              }}
              error={errors.email}
            />
          </Field>
        </Card.Section>
        <Card.Section>
          <Group p="md" justify="end">
            <Button type="submit">Save</Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
