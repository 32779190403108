import { Link } from '@inertiajs/react';
import { Button, Card, Group, Modal, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

import { client_tests_deactivate_kit_path } from '@/routes';
import type { Kit } from '@/types';

interface Props {
  kit: Kit;
}

export function KitExpired({ kit }: Props) {
  const { t } = useTranslation();

  const [opened, { open, close }] = useDisclosure(false);

  return (
    <Card p="xl">
      <Stack gap="lg">
        <Title size="h2">
          <strong>{t('kit.expired.headline')}</strong>
        </Title>
        <Text size="lg">
          Your kit barcode is <strong>{kit.barcode_id}</strong> expired on
          <strong> {kit.expiry_date}</strong>
        </Text>
        <Modal
          opened={opened}
          onClose={close}
          withCloseButton={false}
          size="sm"
          padding="xl"
          centered
        >
          <Stack>
            <Title size="h3">{t('kit.cancel.headline')}</Title>
            <Stack gap="sm">
              <Text>{t('kit.cancel.strapline')}</Text>
              <Text>{t('kit.cancel.strapline_text')}</Text>
            </Stack>

            <Group>
              <Button onClick={close} variant="outline">
                {t('common.action.cancel')}
              </Button>
              <Button
                onClick={open}
                component={Link}
                href={client_tests_deactivate_kit_path()}
                method="patch"
                as="button"
              >
                {t('kit.cancel.confirm')}
              </Button>
            </Group>
          </Stack>
        </Modal>
        <Group>
          <Button onClick={open}>{t('kit.cancel.cta')}</Button>
        </Group>
      </Stack>
    </Card>
  );
}
