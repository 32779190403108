import { Box, Card, Group, Stack, Text } from '@mantine/core';

import type { HealthArea } from '@/types';
import { formatDelta } from '@/utils';

import classes from './_NavGroup.module.css';

interface Props {
  onSelect: (index: number) => void;
  value: number;
  healthAreas: HealthArea[];
}

export function NavGroup({ onSelect, value, healthAreas }: Props) {
  return (
    <Stack gap="xs">
      {healthAreas.map((healthArea, index) => (
        <Card
          key={index}
          onClick={() => {
            onSelect(index);
          }}
          className={classes.navLink}
          data-active={value === index}
        >
          <Group justify="space-between" wrap="nowrap">
            <Text truncate>{healthArea.name}</Text>
            <Group wrap="nowrap">
              <Text fw={700} size="sm" style={{ textWrap: 'nowrap' }}>
                {formatDelta(healthArea.delta)}
              </Text>
              <Box
                className={classes.percentageRisk}
                data-color={healthArea.color}
              >
                {Math.round(healthArea.percentage_risk)}
              </Box>
            </Group>
          </Group>
        </Card>
      ))}
    </Stack>
  );
}
