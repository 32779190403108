import { Card } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { BatchStateField, Field, FileField } from '@/components';
import type { Batch } from '@/types';

interface Props {
  batch: Batch & {
    links?: {
      download_qc_flags: string;
    };
  };
}

const states = ['data_file_needed', 'data_file_errors', 'qc_pending'];

export function Details({ batch }: Props) {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Section>
        <Field label={t('batch.column.name')}>{batch.name}</Field>
        {batch.state != null && (
          <Field label={t('batch.column.state')}>
            <BatchStateField value={batch.state} />
          </Field>
        )}
        {batch.profile != null && (
          <Field label={`${batch.profile} Template`}>
            <FileField value={batch.template} />
          </Field>
        )}
        {states.includes(batch.state ?? '') || (
          <Field label={t('batch.link.quality_control_results')}>
            <FileField value={batch.links?.download_qc_flags} />
          </Field>
        )}
        <Field label={t('batch.column.my_notes')}>{batch.my_notes}</Field>
      </Card.Section>
    </Card>
  );
}
