import axios from 'axios';

import type { Credentials } from '@/types';
import type { Action } from '@/types';
import { getPostHeaders } from '@/utils';
interface Params {
  clientId: number;
}

interface ActionPlan {
  id: string;
  recommendations: Action;
  identifier: string;
  name: string;
  report_id: number;
  actions: {
    lifestyle: Action[];
    supplement: Action[];
    diet: Action[];
  };
}

export const getActionPlan = async (
  { clientId }: Params,
  { email, apiKey }: Credentials,
): Promise<ActionPlan> =>
  await axios
    .get<{ action_plan: ActionPlan }>(
      `${window.location.origin}/provider/api/action_plans/?client_id=${clientId}`,
      {
        headers: {
          ...getPostHeaders(),
          Authorization: `Basic ${btoa(`${email}:${apiKey}`)}`,
        },
      },
    )
    .then(({ data }) => data.action_plan);
