import { Alert } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Page } from '@/components';
import type { Batch } from '@/types';

import { Details } from './_Details';
import { MYNotes } from './_MYNotes';
import { QCFlags } from './_QCFlags';

interface Props {
  batch: Batch;
}

export default function Review(props: Props) {
  const { t } = useTranslation();

  return (
    <Page title="Review Batch">
      {props.batch.state === 'qc_review_needed' && (
        <Alert color="yellow">{t('batch.message.qc_review_needed')}</Alert>
      )}
      <Details batch={props.batch} />
      <QCFlags batch={props.batch} />
      <MYNotes batch={props.batch} />
    </Page>
  );
}
