import { useForm } from '@inertiajs/react';
import {
  Button,
  Card,
  Group,
  Stack,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import type { Vendor } from '@/types';

interface Props {
  vendor: Vendor;
}

export function Form({ vendor }: Props) {
  const { t } = useTranslation();

  const { data, setData, submit, errors } = useForm(vendor);

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    submit(
      vendor.id != null ? 'patch' : 'post',
      `/admin/vendors/${vendor.id ?? ''}`,
    );
  };

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section>
          <Field label={t('vendor.column.name')} required>
            <TextInput
              required
              value={data.name}
              onChange={e => {
                setData('name', e.target.value);
              }}
              error={errors.name}
            />
          </Field>
          <Field label={t('vendor.column.display_name_as')}>
            <TextInput
              value={data.display_name}
              onChange={e => {
                setData('display_name', e.target.value);
              }}
              error={errors.display_name}
            />
          </Field>
          <Field label={t('vendor.column.contact_information')}>
            <Stack gap="md">
              <TextInput
                value={data.contact_title}
                onChange={e => {
                  setData('contact_title', e.target.value);
                }}
                error={errors.contact_title}
                placeholder="Title"
              />
              <TextInput
                value={data.contact_first_name}
                onChange={e => {
                  setData('contact_first_name', e.target.value);
                }}
                error={errors.contact_first_name}
                placeholder="First Name"
              />
              <TextInput
                value={data.contact_last_name}
                onChange={e => {
                  setData('contact_last_name', e.target.value);
                }}
                error={errors.contact_last_name}
                placeholder="Last Name"
              />
            </Stack>
          </Field>
          <Field label={t('vendor.column.processing_time_in_days')} required>
            <TextInput
              required
              value={data.processing_time_in_days}
              onChange={e => {
                setData('processing_time_in_days', e.target.value);
              }}
              error={errors.processing_time_in_days}
            />
          </Field>
          <Field label={t('vendor.column.phone')}>
            <TextInput
              value={data.contact_phone}
              onChange={e => {
                setData('contact_phone', e.target.value);
              }}
              error={errors.contact_phone}
            />
          </Field>
          <Field label={t('vendor.column.mobile')}>
            <TextInput
              value={data.contact_mobile}
              onChange={e => {
                setData('contact_mobile', e.target.value);
              }}
              error={errors.contact_mobile}
            />
          </Field>
          <Field label={t('vendor.column.fax')}>
            <TextInput
              value={data.contact_fax}
              onChange={e => {
                setData('contact_fax', e.target.value);
              }}
              error={errors.contact_fax}
            />
          </Field>
          <Field label={t('vendor.column.email')}>
            <TextInput
              value={data.contact_email}
              onChange={e => {
                setData('contact_email', e.target.value);
              }}
              error={errors.contact_email}
            />
          </Field>
          <Field label={t('vendor.column.notes')}>
            <Textarea
              value={data.notes}
              onChange={e => {
                setData('notes', e.target.value);
              }}
              error={errors.notes}
              rows={4}
            />
          </Field>
        </Card.Section>
      </Card>
      <Card>
        <Card.Section withBorder>
          <Group p="md">
            <Text>{t('address.singular')}</Text>
          </Group>
        </Card.Section>
        <Card.Section>
          <Field label={t('vendor.column.street')}>
            <TextInput
              value={data.street1}
              onChange={e => {
                setData('street1', e.target.value);
              }}
              error={errors.street1}
            />
          </Field>
          <Field label={t('vendor.column.city')}>
            <TextInput
              value={data.city}
              onChange={e => {
                setData('city', e.target.value);
              }}
              error={errors.city}
            />
          </Field>
          <Field label={t('vendor.column.province_state')}>
            <TextInput
              value={data.province_state}
              onChange={e => {
                setData('province_state', e.target.value);
              }}
              error={errors.province_state}
            />
          </Field>
          <Field label={t('vendor.column.postal_zip')}>
            <TextInput
              value={data.country}
              onChange={e => {
                setData('country', e.target.value);
              }}
              error={errors.country}
            />
          </Field>
        </Card.Section>
        <Card.Section withBorder>
          <Group p="md">
            <Text>{t('vendor.column.mailing_address')}</Text>
          </Group>
        </Card.Section>
        <Card.Section>
          <Field label={t('vendor.column.street')}>
            <TextInput
              value={data.mailing_street1}
              onChange={e => {
                setData('mailing_street1', e.target.value);
              }}
              error={errors.mailing_street1}
            />
          </Field>
          <Field label={t('vendor.column.city')}>
            <TextInput
              value={data.mailing_city}
              onChange={e => {
                setData('mailing_city', e.target.value);
              }}
              error={errors.mailing_city}
            />
          </Field>
          <Field label={t('vendor.column.province_state')}>
            <TextInput
              value={data.mailing_province_state}
              onChange={e => {
                setData('mailing_province_state', e.target.value);
              }}
              error={errors.mailing_province_state}
            />
          </Field>
          <Field label={t('vendor.column.postal_zip')}>
            <TextInput
              value={data.mailing_postal_zip}
              onChange={e => {
                setData('mailing_postal_zip', e.target.value);
              }}
              error={errors.mailing_postal_zip}
            />
          </Field>
        </Card.Section>
        <Card.Section>
          <Group p="md" justify="end">
            <Button type="submit">{t('common.action.save')}</Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
