import { Button, Card, Stack, Text, Title } from '@mantine/core';
import type { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Auth } from '@/layouts';
import { client_tests_index_path } from '@/routes';

function ShareWithProviders() {
  const { t } = useTranslation();

  return (
    <Card padding="xl">
      <Card.Section withBorder p="xl">
        <Stack gap="lg">
          <Stack gap="sm">
            <Title size="h3">
              {t('onboarding.share_with_providers.title')}
            </Title>
            <Text size="lg">
              {t('onboarding.share_with_providers.description')}
            </Text>
          </Stack>
          <Button component="a" href={client_tests_index_path()}>
            {t('onboarding.share_with_providers.cta')}
          </Button>
        </Stack>
      </Card.Section>
    </Card>
  );
}

ShareWithProviders.layout = (page: ReactNode): ReactElement => (
  <Auth page={page} />
);

export default ShareWithProviders;
