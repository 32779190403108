import Fuse from 'fuse.js';
import { sortBy } from 'lodash/fp';
import { useMemo, useState } from 'react';

export const useSearch = <T>(
  keys: string[],
  list: T[],
  callback: (sort: string, list: T[]) => T[] = (sort, list) =>
    sortBy(sort, list),
) => {
  const fuse = useMemo(
    () =>
      new Fuse(list, {
        keys,
        shouldSort: false,
        threshold: 0.3,
      }),
    [list],
  );

  const [query, setQuery] = useState('');

  const [sort, setSort] = useState('');

  const results = useMemo(
    () =>
      callback(
        sort,
        query.length > 0 ? fuse.search(query).map(row => row.item) : list,
      ),
    [fuse, query, sort],
  );

  return {
    fuse,
    query,
    setQuery,
    setSort,
    results,
  };
};
