import { Box, Card, Group, Stack, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { BiomarkerSummary } from '@/charts';
import type { Biofunction } from '@/types';

import classes from './_NavGroup.module.css';

interface Props {
  biofunctions: Biofunction[];
  value: number;
  onSelect: (index: number) => void;
}

export function NavGroup({ onSelect, value, biofunctions }: Props) {
  const { t } = useTranslation();
  return (
    <Stack gap="xs">
      <Title lineClamp={1} mb="xs" size="h4">
        {t('biofunctions.pathways')}
      </Title>
      {biofunctions.map((biofunction, index) => (
        <Card
          key={index}
          onClick={() => {
            onSelect(index);
          }}
          className={classes.navLink}
          data-active={value === index}
          style={{ cursor: 'pointer' }}
        >
          <Group justify="space-between">
            <Text tt="capitalize" truncate w="60%">
              {biofunction.biofunction_name}
            </Text>
            <Box w="30%" h="100%">
              <BiomarkerSummary data={biofunction.all_measure_counts} />
            </Box>
          </Group>
        </Card>
      ))}
    </Stack>
  );
}
