import { Box, createPolymorphicComponent, Group } from '@mantine/core';
import type { PropsWithChildren, ReactNode } from 'react';
import { forwardRef } from 'react';

import classes from './ListAction.module.css';

interface Props extends PropsWithChildren {
  rightSection?: ReactNode;
}

export const ListAction = createPolymorphicComponent<'div', Props>(
  forwardRef<HTMLDivElement, Props>(
    ({ rightSection, children, ...props }, ref) => (
      <Box className={classes.link} {...props} ref={ref}>
        <Group justify="space-between">
          {children}
          {rightSection}
        </Group>
      </Box>
    ),
  ),
);
