import { useForm } from '@inertiajs/react';
import { Card, Container, Group, Stack, Switch, Text } from '@mantine/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Back } from '@/components';
import {
  client_account_index_path,
  client_account_toggle_data_sharing_path,
} from '@/routes';

interface Props {
  client: {
    share_with_provider: boolean;
    site: {
      name: string;
      site_type: string;
    };
  };
}

export default function DataSharing({ client }: Props) {
  const { t } = useTranslation();

  const isClinic = client.site.site_type === 'Clinic';

  const { data, setData, patch } = useForm<{
    data_sharing?: {
      share_with_provider: boolean;
    };
  }>();

  useEffect(() => {
    if (data.data_sharing !== undefined) {
      patch(client_account_toggle_data_sharing_path());
    }
  }, [data.data_sharing]);

  return (
    <Container size="xs">
      <Stack gap="xl">
        <Back
          label={t('account.data_sharing.title')}
          to={client_account_index_path()}
        />
        <Card padding="xl">
          <Stack gap="lg">
            <Text>
              {isClinic
                ? t('account.data_sharing.description_clinic')
                : t('account.data_sharing.description_dtc')}
            </Text>
            <Group justify="space-between">
              <Text>
                {isClinic
                  ? client.site.name
                  : t('account.data_sharing.share_with_coach')}
              </Text>
              <Switch
                onChange={event =>
                  setData({
                    data_sharing: { share_with_provider: event.target.checked },
                  })
                }
                defaultChecked={client.share_with_provider}
              />
            </Group>
          </Stack>
        </Card>
      </Stack>
    </Container>
  );
}
