import { Link, usePage } from '@inertiajs/react';
import { Box, Menu, MenuDivider, Text, ThemeIcon } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { RiLogoutCircleRLine, RiUserLine } from 'react-icons/ri';

import { useSite, useUser } from '@/hooks';
import type { User } from '@/types';

interface Props {
  root: string;
}

export function UserMenu({ root }: Props) {
  const { url } = usePage();

  const { t } = useTranslation();

  const site = useSite();

  const user = useUser<User>();

  return user != null ? (
    <Menu position="bottom-end">
      <Menu.Target>
        <ThemeIcon color="brand" variant="light" radius="xl">
          <RiUserLine />
        </ThemeIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Box p="sm">
          <Text size="sm">
            {user?.first_name} {user?.last_name}
          </Text>
          {user?.role != null && (
            <Text size="xs" tt="capitalize">
              {user.role}
            </Text>
          )}
        </Box>
        <MenuDivider />
        {site != null && (
          <>
            <Menu.Item
              leftSection={<RiUserLine />}
              component={Link}
              href="/provider/account/edit"
            >
              {t('account.singular')}
            </Menu.Item>
            <MenuDivider />
          </>
        )}
        {url.startsWith('/client') && (
          <>
            <Menu.Item
              leftSection={<RiUserLine />}
              component={Link}
              href="/client/account"
            >
              {t('account.singular')}
            </Menu.Item>
            <MenuDivider />
          </>
        )}
        <Menu.Item
          leftSection={<RiLogoutCircleRLine />}
          component={Link}
          href={`${root}/sign_out`}
          method="delete"
          as="button"
        >
          {t('common.action.logout')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  ) : null;
}
