import { router } from '@inertiajs/react';
import type { ComboboxData } from '@mantine/core';
import { Button, Flex, Select } from '@mantine/core';
import { BiSortDown, BiSortUp } from 'react-icons/bi';

import { useQueryParams } from '@/hooks';

interface Props {
  data: ComboboxData;
}

export function Order({ data }: Props) {
  const queryParams = useQueryParams();

  return (
    <Flex>
      <Select
        w={200}
        placeholder="Order"
        rightSection={
          <Button
            px="sm"
            onClick={() => {
              router.get('', {
                order: {
                  col: queryParams.get('order[col]'),
                  dir: queryParams.get('order[dir]') === 'ASC' ? 'DESC' : 'ASC',
                },
              });
            }}
          >
            {queryParams.get('order[dir]') === 'ASC' ? (
              <BiSortUp />
            ) : (
              <BiSortDown />
            )}
          </Button>
        }
        rightSectionPointerEvents="all"
        value={queryParams.get('order[col]')}
        onChange={value => {
          router.get('', {
            order: { col: value, dir: queryParams.get('order[dir]') },
          });
        }}
        data={data}
      />
    </Flex>
  );
}
