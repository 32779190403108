import { Link } from '@inertiajs/react';
import { Anchor, Button, Card, Space, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { APP_SUPPORT_EMAIL } from '@/constants';

interface Props {
  email: string;
}

function Success({ email }: Props) {
  const { t } = useTranslation();

  return (
    <Card padding="xl">
      <Text size="xl" fw={700} mb="xl">
        {t('registration.success.title')}
      </Text>
      <Stack>
        <Text size="xl">{t('registration.success.sub_title', { email })}</Text>
        <Text size="xl">
          Contact{' '}
          <Anchor fw={7500} href={`mailto:${APP_SUPPORT_EMAIL}`}>
            {APP_SUPPORT_EMAIL}
          </Anchor>{' '}
          if can’t find the email. Remember to check your spam folder first.
        </Text>
      </Stack>
      <Space h="xl" />
      <div>
        <Link href="/clients/sign_in">
          <Button>{t('common.action.okay')}</Button>
        </Link>
      </div>
    </Card>
  );
}

export default Success;
