import { Page } from '@/components';
import type { Profile, Vendor } from '@/types';

import { Form } from './_Form';

interface Props {
  profile: Profile;
  vendors: Vendor[];
}

export default function New({ profile, vendors }: Props) {
  return (
    <Page title="Update Profile">
      <Form profile={profile} vendor={vendors} />
    </Page>
  );
}
