import { Link, useForm } from '@inertiajs/react';
import {
  Anchor,
  Button,
  Card,
  Checkbox,
  PasswordInput,
  Space,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PasswordRequirements } from '@/components';
import { useValidateEmail, useValidatePassword } from '@/hooks';

interface Props {
  site: {
    id: number;
    description: string;
    site_type: string;
  };
}

function New({ site }: Props) {
  const { t } = useTranslation();

  const { data, setData, post, errors } = useForm({
    email: '',
    password: '',
    password_confirmation: '',
  });

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    post(`/clients/sign_up?site_id=${site.id}`);
  };

  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);

  const isEmailValid = useValidateEmail(data.email);

  const { isPasswordValid, passwordRequirements } = useValidatePassword(
    data.password,
    data.password_confirmation,
  );

  return (
    <form onSubmit={save}>
      <Card padding="xl">
        {site.site_type === 'Clinic' && (
          <Text size="xl" fw={700} mb="xs" c="blue">
            {site.description}
          </Text>
        )}
        <Text size="xl" fw={700} mb="xl">
          {t('registration.new.title')}
        </Text>
        <Stack>
          <TextInput
            placeholder={t('registration.new.email')}
            name="email"
            value={data.email}
            onChange={e => {
              setData('email', e.target.value);
            }}
            error={errors.email}
          />
          <PasswordInput
            placeholder={t('registration.new.password')}
            name="password"
            value={data.password}
            onChange={e => {
              setData('password', e.target.value);
            }}
            error={errors.password}
          />
          <PasswordInput
            placeholder={t('registration.new.password_confirmation')}
            name="password_confirmation"
            value={data.password_confirmation}
            onChange={e => setData('password_confirmation', e.target.value)}
            error={errors.password_confirmation}
          />
          <PasswordRequirements passwordRequirements={passwordRequirements} />
          <Checkbox
            label={t('registration.new.terms_conditions')}
            name="terms_and_conditions"
            checked={termsAndConditionsAccepted}
            onChange={() => {
              setTermsAndConditionsAccepted(!termsAndConditionsAccepted);
            }}
          />
          <Text size="xs">
            Click to view{' '}
            <Anchor
              href="https://www.molecularyou.com/consent-form"
              target="_blank"
              rel="noreferrer"
              fw={500}
            >
              Consent
            </Anchor>{' '}
            and{' '}
            <Anchor
              href="https://www.molecularyou.com/assessment-terms-conditions"
              target="_blank"
              rel="noreferrer"
              fw={500}
            >
              Terms and Conditions
            </Anchor>
          </Text>
        </Stack>
        <Space h="xl" />
        <Stack>
          <div>
            <Button
              type="submit"
              disabled={
                !isEmailValid || !isPasswordValid || !termsAndConditionsAccepted
              }
            >
              {t('common.action.create')}
            </Button>
          </div>
          <Text size="sm">
            {t('registration.new.have_account')}{' '}
            <Anchor fw={500} component={Link} href="/clients/sign_in">
              {t('common.action.login')}
            </Anchor>
          </Text>
        </Stack>
      </Card>
    </form>
  );
}

export default New;
