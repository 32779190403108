import type { Measure } from '@/types';

import { filterNotEmpty } from './filterNotEmpty';

export const selectSampleConcentrationHistory = (
  sampleConcentrations: (Measure | null)[],
) => {
  const getValue = (row: Measure) => {
    if (row.percentile != null) return row.percentile;
    if (row.value != null) return Number(row.value);
    return null;
  };

  return sampleConcentrations.filter(filterNotEmpty).map(row => ({
    collection_date: row.collection_date,
    color: row.color,
    value: getValue(row),
    level: row.level,
    unit: row.unit,
    range: row.range,
  }));
};
