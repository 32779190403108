export const scaleSampleConcentration = (
  value: number,
  rrLow: number,
  rrHigh: number,
  greenHeight: number,
  height: number,
): number => {
  const differenceBetweenRanges = rrHigh - rrLow;
  const spaceBetweenHighestAndLowestRanges = rrHigh + rrLow;
  const scaleFactor = greenHeight / differenceBetweenRanges;

  const refMid = spaceBetweenHighestAndLowestRanges / 2;

  const boundryPadding = 10;

  if (value > rrHigh + differenceBetweenRanges) {
    value = rrHigh + differenceBetweenRanges;
    return height / 2 - (value - refMid) * scaleFactor + 10;
  }

  if (value < rrLow - differenceBetweenRanges) {
    value = rrLow - differenceBetweenRanges;
    height / 2 - (value - refMid) * scaleFactor - 10;
  }
  let scaledYPosition = height / 2 - (value - refMid) * scaleFactor;

  //ensure the plotted value is within the bounds of the graph and preventing cutting off plotted value
  // Math.min... keeps it from being positioned too close to or beyond the bottom edge of the chart.
  // Math.max... ensures scaledYPosition is at least boundaryPadding from the top
  scaledYPosition = Math.max(
    Math.min(scaledYPosition, height - boundryPadding),
    boundryPadding,
  );

  return scaledYPosition;
};
