import { Box, Grid, Group, Stack, Text, Title } from '@mantine/core';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getBiofunctionDetails } from '@/api/provider/getBiofunctionDetails';
import { BiomarkerSummary } from '@/charts';
import { SampleConcentration } from '@/charts';
import { selectSampleConcentrationHistory } from '@/selectors';
import type { Biofunction } from '@/types';

import { BiomarkerDetails } from './_BiomarkerDetails';
import { BiomarkersListItem } from './_BiomarkersListItem';
import { CollapsibleCard } from './_CollapsibleCard';

interface Props {
  biofunction: Biofunction;
  clientId: number;
  reportId: number;
}

export function BiofunctionDetails({ biofunction, clientId, reportId }: Props) {
  const { t } = useTranslation();

  const biofunctionDetails = useQuery(
    ['biofunctionDetails', biofunction.id, clientId],
    async () => await getBiofunctionDetails({ clientId, id: biofunction.id }),
    {
      initialData: undefined,
    },
  );

  const [expandedCards, setExpandedCards] = useState({
    biomarkers: true,
    history: true,
    info: true,
  });

  const { data, isFetching, isSuccess } = biofunctionDetails;

  const toggleCollapse = (card: keyof typeof expandedCards) => {
    setExpandedCards(prevState => ({
      ...prevState,
      [card]: !prevState[card],
    }));
  };

  const [selected, setSelected] = useState<number | null>(null);

  useEffect(() => {
    if (biofunctionDetails.data?.health_report_sample_concentrations?.length) {
      setSelected(
        biofunctionDetails.data.health_report_sample_concentrations[0].id,
      );
    }
  }, [data]);

  const handleSelect = (id: number) => {
    setSelected(id);
  };

  if (isFetching) {
    return (
      <Text ta="center" p="xl">
        {t('common.loading')}
      </Text>
    );
  }

  if (isSuccess) {
    return (
      <Grid gutter="lg">
        <Grid.Col span={6}>
          <Stack gap="md">
            <Title lineClamp={1} tt="capitalize" size="h4">
              {data.biofunction_name}
            </Title>
            <CollapsibleCard
              title={t('biomarker.plural')}
              onToggle={() => toggleCollapse('biomarkers')}
              isOpen={expandedCards.biomarkers}
              children={
                <div>
                  {data.health_report_sample_concentrations?.map(
                    (concentration, index) => (
                      <BiomarkersListItem
                        onClick={() => handleSelect(concentration.id)}
                        key={index}
                        name={concentration.measure_name}
                        selected={concentration.id === selected}
                        children={
                          <SampleConcentration
                            data={selectSampleConcentrationHistory([
                              concentration,
                            ])}
                          />
                        }
                      />
                    ),
                  )}
                </div>
              }
            />

            <CollapsibleCard
              title={t('biofunctions.pathway_history')}
              onToggle={() => toggleCollapse('history')}
              isOpen={expandedCards.history}
              children={
                <div>
                  {data.history
                    ?.slice()
                    .sort(
                      (a, b) =>
                        new Date(a.collection_date).getTime() -
                        new Date(b.collection_date).getTime(),
                    )
                    .map(history => (
                      <Group
                        justify="flex-start"
                        px="lg"
                        key={history.collection_date}
                      >
                        <Text tt="capitalize" w="30%" py="xs">
                          {dayjs(history.collection_date).format('MMM YYYY')}
                        </Text>
                        <Box w="30%">
                          <BiomarkerSummary data={history.all_measure_counts} />
                        </Box>
                      </Group>
                    ))}
                </div>
              }
            />

            <CollapsibleCard
              title={`${data.biofunction_name} Info`}
              onToggle={() => toggleCollapse('info')}
              isOpen={expandedCards.info}
              children={<Text px="lg">{data.general_description}</Text>}
            />
          </Stack>
        </Grid.Col>

        <Grid.Col span={6}>
          <BiomarkerDetails id={selected} reportId={reportId} />
        </Grid.Col>
      </Grid>
    );
  }

  return (
    <Text p="xl" ta="center">
      {t('common.no_data')}
    </Text>
  );
}
