import { Link } from '@inertiajs/react';
import { Button, Card, Menu } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaEllipsisV } from 'react-icons/fa';

import { DataGrid, Page, Pagination, Toolbar } from '@/components';
import type { ProductBundle, ProductSampleTemplate, Profile } from '@/types';
import { formatMoney } from '@/utils';

interface Props {
  product_bundles: (ProductBundle & {
    product_sample_templates?: (ProductSampleTemplate & {
      profile?: Profile;
    })[];
  })[];
}

export default function Index(props: Props) {
  const { t } = useTranslation();

  const searchCols = ['product_name', 'description'];

  return (
    <Page
      title="Product Bundles"
      actions={
        <Button component={Link} href="/admin/product_bundles/new">
          Create Product Bundle
        </Button>
      }
    >
      <Card>
        <Card.Section>
          <Toolbar searchCols={searchCols} />
          <DataGrid
            data={props.product_bundles}
            columns={[
              {
                header: t('common.column.id'),
                accessorFn: row => row.id,
              },
              {
                header: t('product_bundle.column.product_name'),
                accessorFn: row => row.product_name,
              },
              {
                header: t('product_bundle.column.description'),
                accessorFn: row => row.description,
              },
              {
                header: t('product_bundle.column.default_price_in_cents'),
                accessorFn: row => formatMoney(row.default_price_in_cents),
              },
              {
                header: t('product_bundle.column.assay_total'),
                accessorFn: row => row.assay_total,
              },
              {
                header: t('product_bundle.column.included_profiles'),
                accessorFn: row =>
                  row.product_sample_templates
                    ?.map(
                      item =>
                        `${item.profile?.name ?? ''} (${item.sample_type ?? ''})`,
                    )
                    .join(', '),
              },
              {
                id: 'actions',
                cell: ({ row }) => (
                  <Menu>
                    <Menu.Target>
                      <span>
                        <FaEllipsisV />
                      </span>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        component={Link}
                        href={`/admin/product_bundles/${row.original.id}/edit`}
                      >
                        {t('common.action.edit')}
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
          <Pagination />
        </Card.Section>
      </Card>
    </Page>
  );
}
