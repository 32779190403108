import { useForm } from '@inertiajs/react';
import {
  Button,
  Card,
  Center,
  Group,
  Radio,
  Text,
  Tooltip,
} from '@mantine/core';
import {
  getExpandedRowModel,
  getGroupedRowModel,
  getSortedRowModel,
} from '@tanstack/table-core';
import axios from 'axios';
import { capitalize } from 'lodash';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DataGrid } from '@/components';
import type { Batch, QCFlag } from '@/types';
import { formatBarcode } from '@/utils';

interface Props {
  batch: Batch & {
    batch_qc_flags?: QCFlag[];
  };
}

const states = ['qc_vendor_sign_off_needed', 'qc_review_needed'];

export function QCFlags({ batch }: Props) {
  const { t } = useTranslation();

  const [data, setData] = useState(batch.batch_qc_flags);

  const form = useForm();

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    form.patch(`/vendor_representative/batches/${batch.id}/submit`, {
      preserveScroll: page => page.props.errors != null,
    });
  };

  const setVendorRerunGuidance = async (
    ids: number[],
    value: string,
  ): Promise<void> => {
    setData(data =>
      data?.map(item =>
        ids.includes(item.id)
          ? { ...item, vendor_rerun_guidance: value }
          : item,
      ),
    );

    await axios.patch(`/vendor_representative/batches/${batch.id}/qc_flags`, {
      qc_flags: ids.map(id => ({ id, vendor_rerun_guidance: value })),
    });
  };

  if (!states.includes(batch.state)) {
    return null;
  }

  return (
    <Card>
      <Card.Section p="md" withBorder>
        {t('qc_flag.plural')}
      </Card.Section>
      <Card.Section withBorder>
        <DataGrid
          data={data}
          columns={[
            {
              id: 'barcode',
              header: 'Barcode',
              accessorFn: row => formatBarcode(row.barcode, row.biomarker),
            },
            {
              header: 'Biomarker',
              accessorFn: row => row.biomarker,
            },
            {
              header: 'Issue',
              accessorFn: row => row.issue,
            },
            {
              header: 'Action For Vendor',
              accessorFn: row => row.action_for_vendor,
            },
            {
              header: 'Severity',
              accessorFn: row => capitalize(row.severity),
            },
            {
              header: 'No Rerun Advised',
              aggregationFn: (_, rows) => rows.map(row => row.original.id),
              aggregatedCell: ({ getValue }) => (
                <Center>
                  <Button
                    variant="text"
                    size="xs"
                    onClick={() => {
                      void (async () => {
                        await setVendorRerunGuidance(
                          getValue<number[]>(),
                          'no_rerun_advised',
                        );
                      })();
                    }}
                    disabled={batch.state !== 'qc_vendor_sign_off_needed'}
                  >
                    {t('batch.action.select_all')}
                  </Button>
                </Center>
              ),
              cell: ({ row }) => (
                <Center>
                  <Tooltip label="No Rerun Recommended">
                    <Radio
                      checked={
                        row.original.vendor_rerun_guidance ===
                        'no_rerun_advised'
                      }
                      disabled={batch.state !== 'qc_vendor_sign_off_needed'}
                      onChange={() => {
                        void (async () => {
                          await setVendorRerunGuidance(
                            [row.original.id],
                            'no_rerun_advised',
                          );
                        })();
                      }}
                    />
                  </Tooltip>
                </Center>
              ),
            },
            {
              header: 'Rerun Advised',
              aggregationFn: (_, rows) => rows.map(row => row.original.id),
              aggregatedCell: ({ getValue }) => (
                <Center>
                  <Button
                    variant="text"
                    size="xs"
                    onClick={() => {
                      void (async () => {
                        await setVendorRerunGuidance(
                          getValue<number[]>(),
                          'rerun_advised',
                        );
                      })();
                    }}
                    disabled={batch.state !== 'qc_vendor_sign_off_needed'}
                  >
                    {t('batch.action.select_all')}
                  </Button>
                </Center>
              ),
              cell: ({ row }) => (
                <Center>
                  <Tooltip label="Rerun Recommended">
                    <Radio
                      checked={
                        row.original.vendor_rerun_guidance === 'rerun_advised'
                      }
                      onChange={() => {
                        void (async () => {
                          await setVendorRerunGuidance(
                            [row.original.id],
                            'rerun_advised',
                          );
                        })();
                      }}
                      disabled={batch?.state !== 'qc_vendor_sign_off_needed'}
                    />
                  </Tooltip>
                </Center>
              ),
            },
          ]}
          getGroupedRowModel={getGroupedRowModel()}
          getExpandedRowModel={getExpandedRowModel()}
          getSortedRowModel={getSortedRowModel()}
          enableSorting={true}
          autoResetExpanded={false}
          initialState={{
            grouping: ['barcode'],
            sorting: [
              {
                id: 'barcode',
                desc: false,
              },
            ],
            expanded: true,
          }}
        />
      </Card.Section>
      <Card.Section>
        <form onSubmit={save}>
          <Group justify="end" p="md">
            {form.errors?.batch_qc_flags != null && (
              <Text c="red">{form.errors.batch_qc_flags}</Text>
            )}
            <Button
              disabled={batch.state !== 'qc_vendor_sign_off_needed'}
              type="submit"
            >
              {t('batch.action.submit_batch')}
            </Button>
          </Group>
        </form>
      </Card.Section>
    </Card>
  );
}
