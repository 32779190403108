import { useAmplitudeKey, useUser } from '@/hooks';
import type { Client } from '@/types';
import { trackEvent } from '@/utils';

type TrackClientEvent = (event: string, data: Record<string, string>) => void;

export const useTrackClientEvent = (): TrackClientEvent => {
  const amplitudeKey = useAmplitudeKey();

  const client = useUser<Client>();

  return (event: string, data: Record<string, string> = {}) => {
    if (amplitudeKey != null && client != null) {
      trackEvent(
        amplitudeKey,
        client.email,
        {
          client_ID: client.id,
          name: `${client.last_name} ${client.first_name}`,
        },
        event,
        data,
      );
    }
  };
};
