import { usePage } from '@inertiajs/react';
import { Grid } from '@mantine/core';
import { compact } from 'lodash';

import { Card } from './_Card';
import { Field } from './_Field';

type StageOfChange = Record<
  string,
  {
    label: string;
    sub_label: string;
  }
>;

interface Condition {
  name: string;
  disease_identifier: string;
  group_identifier: string;
  response: boolean;
}

interface IHealthProfile {
  alcohol_per_week: string;
  dietary_allergies: string[];
  dietary_preferences: string[];
  stage_of_change: string;
  health_conditions: Condition[];
}

interface PageProps {
  client: {
    id: number;
  };
  health_profile: IHealthProfile;
  physical_activity_level_options: StageOfChange;
}

export function HealthProfile() {
  const {
    client,
    health_profile: healthProfile,
    physical_activity_level_options: physicalActivityLevelOptions,
  } = usePage<PageProps>().props;

  return (
    <Card
      title="Health Profile"
      editLink={`/provider/clients/${client.id}/health_profile`}
      padding={true}
      component={
        <Grid gutter="sm" p="md">
          <Field
            label="Alcoholic drinks per week"
            value={healthProfile?.alcohol_per_week}
          />
          <Field
            label="Allergies"
            value={compact(healthProfile?.dietary_allergies)?.join(', ')}
          />
          <Field
            label="Dietary restrictions"
            value={compact(healthProfile?.dietary_preferences)?.join(', ')}
          />
          <Field
            label="Level of physical activity"
            value={
              physicalActivityLevelOptions[healthProfile.stage_of_change]?.label
            }
          />
          <Field
            label="Diagnosed health conditions"
            value={healthProfile.health_conditions
              ?.filter((condition: Condition) => condition.response)
              ?.map((condition: Condition) => condition.name)
              ?.join(', ')}
          />
        </Grid>
      }
    />
  );
}
