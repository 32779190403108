export const formatVendorRerunGuidance = (
  columnId: string,
  rows: {
    original: {
      vendor_rerun_guidance: string;
    };
  }[],
): string =>
  `${
    rows.filter(row => row.original.vendor_rerun_guidance === columnId).length
  }/${rows.length}`;
