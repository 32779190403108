import { Link } from '@inertiajs/react';
import { Button, Card, Menu } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaEllipsisV } from 'react-icons/fa';

import { DataGrid, Page, Pagination, Toolbar } from '@/components';
import type { Site } from '@/types';
import { formatDate } from '@/utils';

interface Props {
  sites: Site[];
}

export default function Index(props: Props) {
  const { t } = useTranslation();

  const searchCols = ['description', 'site_type'];

  return (
    <Page
      title="Sites"
      actions={
        <Button component={Link} href="/admin/sites/new">
          Create Site
        </Button>
      }
    >
      <Card>
        <Card.Section>
          <Toolbar searchCols={searchCols} />
          <DataGrid
            data={props.sites}
            columns={[
              {
                header: t('site.column.description'),
                accessorFn: row => row.description,
              },
              {
                header: t('site.column.site_type'),
                accessorFn: row => row.site_type,
              },
              {
                header: t('provider.plural'),
                accessorFn: row => row.providers_size,
              },
              {
                header: t('common.column.created_at'),
                accessorFn: row => formatDate(row.created_at),
              },
              {
                header: t('common.column.updated_at'),
                accessorFn: row => formatDate(row.updated_at),
              },
              {
                id: 'actions',
                cell: ({ row }) => (
                  <Menu>
                    <Menu.Target>
                      <span>
                        <FaEllipsisV />
                      </span>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        component={Link}
                        href={`/admin/sites/${row.original.id}/edit`}
                      >
                        {t('common.action.edit')}
                      </Menu.Item>
                      <Menu.Item
                        component={Link}
                        href={`/admin/sites/${row.original.id}`}
                      >
                        {t('common.action.show')}
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
          <Pagination />
        </Card.Section>
      </Card>
    </Page>
  );
}
