import { Grid, Stack } from '@mantine/core';
import type { ReactElement, ReactNode } from 'react';

import { usePermissions } from '@/hooks';
import { Provider } from '@/layouts';
import type { Client } from '@/types';

import { HealthProfile } from './_HealthProfile';
import { PersonalInfo } from './_PersonalInfo';
import { ReportStatus } from './_ReportStatus';
import { SubHeader } from './_SubHeader';
import { Tests } from './_Tests';

interface Props {
  client: Client;
}

function Show({ client }: Props) {
  const { hasPrivilege } = usePermissions();

  return (
    <Stack>
      <ReportStatus />
      <Grid gutter="xl">
        <Grid.Col span={{ base: 12, md: 4 }}>
          <PersonalInfo />
        </Grid.Col>
        {client.share_with_provider &&
          hasPrivilege('change_health_profiles') && (
            <Grid.Col span={{ base: 12, md: 4 }}>
              <HealthProfile />
            </Grid.Col>
          )}
        <Grid.Col span={{ base: 12, md: 4 }}>
          <Tests />
        </Grid.Col>
      </Grid>
    </Stack>
  );
}

Show.layout = (page: ReactNode & { props: Props }): ReactElement => (
  <Provider subHeader={<SubHeader client={page.props.client} />} page={page} />
);

export default Show;
