import { Checkbox, Group, Stack, Text } from '@mantine/core';

interface Props {
  value?: Record<string, boolean>;
  onChange: (value: Record<string, boolean>) => void;
  data: {
    value: string;
    label: string;
  }[];
}

export function RequisitionFormSet({ value, onChange, data }: Props) {
  return (
    <Stack>
      {data.map(item => (
        <Group key={item.value}>
          <Checkbox
            key={item.value}
            onChange={e => {
              onChange({ ...value, [item.value]: e.currentTarget.checked });
            }}
            checked={value?.[item.value] !== false}
          ></Checkbox>
          <Text>{item.label}</Text>
        </Group>
      ))}
    </Stack>
  );
}
