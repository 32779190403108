import amplitude from 'amplitude-js';

export const trackEvent = (
  amplitudeKey: string,
  email: string,
  userProperties: Record<string, string | number>,
  event: string,
  data: Record<string, string | number>,
) => {
  const amplitudeInstance = amplitude.getInstance();

  amplitudeInstance.init(amplitudeKey, email);
  amplitudeInstance.setUserProperties(userProperties);

  amplitudeInstance.logEvent(event, data);
};
