import { Link } from '@inertiajs/react';
import { Box, Card, SimpleGrid, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import {
  FaRegFile,
  FaStream,
  FaUser,
  FaUsers,
  FaUserTie,
} from 'react-icons/fa';

import { Page } from '@/components';
import { usePermissions } from '@/hooks';

export default function Index() {
  const { hasPrivilege } = usePermissions();

  const { t } = useTranslation();

  const panelRow1 = [
    {
      href: '/admin/sites',
      privilege: 'change_sites',
      icon: <FaUsers size={72} />,
      label: t('site.plural'),
    },
    {
      href: '/admin/reports',
      privilege: 'change_reports',
      icon: <FaRegFile size={72} />,
      label: t('report.plural'),
    },
    {
      href: '/admin/assays',
      privilege: 'change_assays',
      icon: <FaStream size={72} />,
      label: t('assay.plural'),
    },
    {
      href: '/admin/admins',
      privilege: 'change_admins',
      icon: <FaUserTie size={72} />,
      label: t('admin.plural'),
    },
    {
      href: '/admin/account/edit',
      icon: <FaUser size={72} />,
      label: t('account.singular'),
    },
  ];

  return (
    <Page title="Welcome to the Molecular You Administrator Dashboard">
      <Stack gap="xl">
        <SimpleGrid cols={4}>
          {panelRow1
            .filter(
              panel =>
                (panel.privilege != null && hasPrivilege(panel.privilege)) ??
                panel.privilege == null,
            )
            .map(panel => (
              <Card
                key={panel.href}
                component={Link}
                href={panel.href}
                c="dimmed"
                mt={7}
                padding="xl"
              >
                <Box ta="center" pt="md">
                  {panel.icon}
                  <Text size="xl" p="md" fw={800}>
                    {panel.label}
                  </Text>
                </Box>
              </Card>
            ))}
        </SimpleGrid>
      </Stack>
    </Page>
  );
}
