import { Center, Stepper } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface Props {
  active: number;
}

export function Progress({ active }: Props) {
  const { t } = useTranslation();

  return (
    <Center>
      <Stepper active={active} miw={260}>
        <Stepper.Step label="Personal information" />
        <Stepper.Step label={t('health_profile.singular')} />
      </Stepper>
    </Center>
  );
}
