import { Box } from '@mantine/core';
import type { PropsWithChildren } from 'react';

import classes from './Tooltip.module.css';

interface Props extends PropsWithChildren {
  position: {
    top: number;
    left: number;
  };
}

export function Tooltip({ position, children }: Props) {
  return (
    <Box
      className={classes.tooltip}
      style={{ top: `${position.top + 8}px`, left: `${position.left}px` }}
      data-visible={position.left > 0 && position.top > 0}
    >
      {children}
    </Box>
  );
}
