import { useForm } from '@inertiajs/react';
import { Button, Card, Group, Radio, TextInput } from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import type { EmailTemplate } from '@/types';

interface Props {
  emailTemplate: EmailTemplate;
}

export function Form({ emailTemplate }: Props) {
  const { t } = useTranslation();

  const { data, setData, submit, errors } = useForm(emailTemplate);

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    submit(
      emailTemplate.id != null ? 'patch' : 'post',
      `/admin/email_templates/${emailTemplate.id ?? ''}`,
    );
  };

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section>
          <Field label={t('email_template.column.name')}>
            <TextInput
              required
              value={data.name}
              onChange={e => {
                setData('name', e.target.value);
              }}
              error={errors.name}
            />
          </Field>
          <Field label={t('email_template.column.description')}>
            <TextInput
              value={data.description}
              onChange={e => {
                setData('description', e.target.value);
              }}
              error={errors.description}
            />
          </Field>
          <Field label={t('email_template.column.internal_key')}>
            <TextInput
              value={data.internal_key}
              onChange={e => {
                setData('internal_key', e.target.value);
              }}
              error={errors.internal_key}
            />
          </Field>
          <Field label={t('email_template.column.sendgrid_template_id')}>
            <TextInput
              value={data.sendgrid_template_id}
              onChange={e => {
                setData('sendgrid_template_id', e.target.value);
              }}
              error={errors.sendgrid_template_id}
            />
          </Field>
          <Field label={t('email_template.column.cc_addresses')}>
            <TextInput
              value={data.cc_addresses}
              onChange={e => {
                setData('cc_addresses', e.target.value);
              }}
              error={errors.cc_addresses}
            />
          </Field>
          <Field label={t('email_template.column.bcc_addresses')}>
            <TextInput
              value={data.bcc_addresses}
              onChange={e => {
                setData('bcc_addresses', e.target.value);
              }}
              error={errors.bcc_addresses}
            />
          </Field>
          <Field label={t('email_template.column.active')}>
            <Radio.Group
              name="active"
              value={data.active ? 'active' : 'inactive'}
              onChange={value => {
                setData('active', value === 'active');
              }}
              error={errors.active}
            >
              <Group>
                <Radio value="inactive" label={t('common.state.inactive')} />
                <Radio value="active" label={t('common.state.active')} />
              </Group>
            </Radio.Group>
          </Field>
        </Card.Section>
        <Card.Section>
          <Group p="md" justify="end">
            <Button type="submit">{t('common.action.save')}</Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
