export const resetTime = (date: Date | string | null): string => {
  if (date instanceof Date) {
    return new Date(
      `${date.toISOString().split('T')[0]}T00:00:00`,
    ).toISOString();
  } else if (typeof date === 'string') {
    return new Date(`${date}T00:00:00`).toISOString();
  }
  return '';
};
