import { usePage } from '@inertiajs/react';
import { Grid } from '@mantine/core';
import { capitalize } from 'lodash';

import { usePermissions } from '@/hooks';

import { Card } from './_Card';
import { Field } from './_Field';

interface PageProps {
  client: {
    shipping_address: Record<string, string>;
    id: number;
    first_name: string;
    last_name: string;
    preferred_name: string;
    age: number;
    sex: string;
  };
}

export function PersonalInfo() {
  const { hasPrivilege } = usePermissions();

  const { client } = usePage<PageProps>().props;
  return (
    <Card
      title="Personal Information"
      editLink={
        hasPrivilege('change_clients')
          ? `/provider/clients/${client.id}/edit`
          : null
      }
      padding={true}
      component={
        <Grid gutter="sm" p="md">
          <Field
            label="Name"
            value={`${client.first_name} ${client.last_name}`}
          />
          {client.preferred_name != null && ( // Show preferred_name field only if present
            <Field label="Preferred name" value={client.preferred_name} />
          )}
          <Field label="Age" value={client.age} />
          <Field label="Sex at birth" value={capitalize(client.sex)} />
          <Field
            label="Address"
            value={client.shipping_address?.full_address}
          />
        </Grid>
      }
    />
  );
}
