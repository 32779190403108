import { Card } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import type { EmailTemplate } from '@/types';

interface Props {
  emailTemplate: EmailTemplate;
}

export function Details({ emailTemplate }: Props) {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Section>
        <Field label={t('email_template.column.name')}>
          {emailTemplate.name}
        </Field>
        <Field label={t('email_template.column.description')}>
          {emailTemplate.description}
        </Field>
        <Field label={t('email_template.column.internal_key')}>
          {emailTemplate.internal_key}
        </Field>
        <Field label={t('email_template.column.sendgrid_template_id')}>
          {emailTemplate.sendgrid_template_id}
        </Field>
        <Field label={t('email_template.column.cc_addresses')}>
          {emailTemplate.cc_addresses}
        </Field>
        <Field label={t('email_template.column.bcc_addresses')}>
          {emailTemplate.bcc_addresses}
        </Field>
        <Field label={t('email_template.column.active')}>
          {t(`common.${emailTemplate.active ? 'yes' : 'no'}`)}
        </Field>
      </Card.Section>
    </Card>
  );
}
