import { useForm } from '@inertiajs/react';
import {
  Button,
  Card,
  Group,
  HoverCard,
  Radio,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';

import { Field, Page } from '@/components';
import { useTrackProviderEvent } from '@/hooks';
import type { Site } from '@/types';

interface Props {
  site: Site;
}

export default function Edit({ site }: Props) {
  const { t } = useTranslation();

  const trackEvent = useTrackProviderEvent();

  const { setData, patch, data, errors } = useForm(site);

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    patch('/provider/site');
  };

  return (
    <Page title="Clinic Settings">
      <form onSubmit={save}>
        <Stack gap="xl">
          <Card p="xl">
            <Card.Section withBorder>
              <Group>
                <Text mb="md">
                  {t('site.settings.blood_draw_booking_information')}
                </Text>
                <HoverCard
                  width={320}
                  shadow="md"
                  withArrow
                  closeDelay={400}
                  position="bottom"
                >
                  <HoverCard.Target>
                    <Text mb="sm" style={{ marginLeft: '-10px' }}>
                      <FaInfoCircle />
                    </Text>
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                    <Text>
                      {t('site.settings.blood_draw_booking_information_text')}
                    </Text>
                  </HoverCard.Dropdown>
                </HoverCard>
              </Group>
            </Card.Section>
            <Card.Section>
              <Field label={t('site.settings.booking_url')}>
                <TextInput
                  value={data.booking_site_url}
                  onChange={e => {
                    setData('booking_site_url', e.target.value);
                  }}
                  error={errors.booking_site_url}
                />
              </Field>
              <Field
                label={t('site.settings.booking_method_description')}
                required
              >
                <TextInput
                  required
                  value={data.public_description}
                  onChange={e => {
                    setData('public_description', e.target.value);
                  }}
                  error={errors.public_description}
                />
              </Field>
            </Card.Section>
          </Card>
          <Card>
            <Card.Section>
              <Field label={t('site.settings.release_behavior')}>
                <Radio.Group
                  name="hold_reports"
                  value={data.hold_reports ? 'true' : 'false'}
                  onChange={value => {
                    setData('hold_reports', value === 'true');
                    trackEvent('hold_reports', { value });
                  }}
                >
                  <Stack>
                    <Radio
                      value="false"
                      label={
                        <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                          {t('site.settings.release_immediately')}
                        </span>
                      }
                    />
                    <Text>
                      {t('site.settings.release_immediately_description')}
                    </Text>
                    <Radio
                      value="true"
                      label={
                        <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                          {t('site.settings.hold_reports')}
                        </span>
                      }
                    />
                    <Text>{t('site.settings.hold_reports_description')}</Text>
                  </Stack>
                </Radio.Group>
              </Field>
            </Card.Section>
            <Card.Section>
              <Group justify="end" p="md">
                <Button type="submit">{t('common.action.save')}</Button>
              </Group>
            </Card.Section>
          </Card>
        </Stack>
      </form>
    </Page>
  );
}
