import { ColorSwatch, Group, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { getBatchStateColor } from '@/utils';

interface Props {
  value: string;
}

export function BatchStateField({ value }: Props) {
  const { t } = useTranslation();

  return (
    <Group gap="xs">
      <ColorSwatch
        size={8}
        color={`var(--mantine-color-${getBatchStateColor(value)}-5)`}
        withShadow={false}
      />
      <Text size="sm">{t(`batch.state.${value}`)}</Text>
    </Group>
  );
}
