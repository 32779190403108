import { Card, Group, Table, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import type { ProductBundle, SiteProductBundle } from '@/types';

interface Props {
  siteProductBundle: (SiteProductBundle & {
    product_bundle?: ProductBundle;
  })[];
}

export function SiteProductBundleShow(data: Props) {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Section withBorder>
        <Group p="md" justify="space-between">
          <Text>{t('product_bundle.plural')}</Text>
        </Group>
      </Card.Section>
      <Card.Section>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{t('product_bundle.column.product_name')}</Table.Th>
              <Table.Th>{t('product_bundle.column.default_for_site')}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {data.siteProductBundle.map(item => (
              <Table.Tr key={item.id}>
                <Table.Td>{item.product_bundle?.product_name}</Table.Td>
                <Table.Td>
                  {item.default_for_site === true ? 'Yes' : 'No'}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Card.Section>
    </Card>
  );
}
