import { Box, Text, useComputedColorScheme } from '@mantine/core';
import * as d3 from 'd3';
import dayjs from 'dayjs';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@/components';
import { useDimensions, useTooltip } from '@/hooks';
import type { Color, History } from '@/types';
import { getColor } from '@/utils';

interface Props {
  data: History[];
}

const OFFSET = { top: 10, right: 10, bottom: 10, left: 80 };

export function HealthAreaHistory({ data }: Props) {
  const { t } = useTranslation();

  const container = useRef<HTMLDivElement>(null);

  const { width, height } = useDimensions(container);

  const offsetWidth = width - OFFSET.right - OFFSET.left;
  const offsetHeight = height - OFFSET.top - OFFSET.bottom;

  const xScale = useMemo(
    () =>
      d3
        .scaleBand()
        .domain(data.map(item => item.collected_on))
        .range([0, offsetWidth])
        .padding(0.4),
    [width],
  );

  const yScale = useMemo(
    () => d3.scaleLinear().domain([0, 100]).range([offsetHeight, 0]),
    [height],
  );

  const colorScale = useMemo(
    () =>
      d3
        .scaleThreshold<number, Color>()
        .domain([33.3333, 66.6666])
        .range(['red', 'yellow', 'green']),
    [height],
  );

  const xRange = xScale.range();

  const ticks = useMemo(
    () =>
      colorScale.range().map(value => {
        const [min, max] = colorScale.invertExtent(value);
        return {
          value,
          extent: [min ?? 0, max ?? 100],
        };
      }),
    [height],
  );

  const { tooltip, show, hide } = useTooltip<History>(null);

  const colorSchme = useComputedColorScheme();

  return (
    <Box w="100%" h="100%" pos="relative" ref={container}>
      <Tooltip position={tooltip.position}>
        {tooltip.data && (
          <>
            <Text size="sm">
              Score: {Math.round(tooltip.data.percentage_risk)}
            </Text>
            <Text size="sm">
              {dayjs(tooltip.data.collected_on).format('MMM YYYY')}
            </Text>
          </>
        )}
      </Tooltip>
      <svg width={width} height={height}>
        <g
          width={OFFSET.left}
          transform={`translate(${[0, OFFSET.top].join(',')})`}
        >
          {ticks.map(({ value, extent }, index) => (
            <text
              key={index}
              fontSize={12}
              fill={colorSchme === 'light' ? '#868e96' : '#828282'}
              dominantBaseline="middle"
              transform={`translate(${[0, yScale((extent[0] + extent[1]) / 2)].join(',')})`}
            >
              {t(`health_area.risk.${value}`)}
            </text>
          ))}
        </g>
        <g
          width={offsetWidth}
          height={offsetHeight}
          transform={`translate(${[OFFSET.left, OFFSET.top].join(',')})`}
        >
          {ticks.map(({ extent }, index) => (
            <line
              key={index}
              strokeWidth={1}
              stroke={colorSchme === 'light' ? '#dee2e6' : '#424242'}
              x2={offsetWidth}
              y1={yScale(extent[1])}
              y2={yScale(extent[1])}
            />
          ))}
        </g>
        <g
          width={offsetWidth}
          height={offsetHeight}
          transform={`translate(${[OFFSET.left, OFFSET.top].join(',')})`}
        >
          {data.map((item, index, array) => (
            <rect
              key={index}
              x={xScale(item.collected_on)}
              y={yScale(item.percentage_risk)}
              width={xScale.bandwidth()}
              height={offsetHeight - yScale(item.percentage_risk)}
              onMouseMove={() =>
                show(
                  item,
                  xScale(item.collected_on) +
                    OFFSET.left +
                    xScale.bandwidth() / 2,
                  OFFSET.top + yScale(item.percentage_risk),
                )
              }
              onMouseOut={hide}
              fill={
                index + 1 === array.length
                  ? getColor(colorScale(item.percentage_risk))
                  : '#d2d7e1'
              }
            />
          ))}
        </g>
        <g transform={`translate(${OFFSET.left}, ${height - OFFSET.bottom})`}>
          <path
            fill="none"
            stroke={colorSchme === 'light' ? '#dee2e6' : '#424242'}
            d={['M', xRange[0], 0, 'L', xRange[1], 0].join(' ')}
          />
        </g>
      </svg>
    </Box>
  );
}
