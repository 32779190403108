import { useForm } from '@inertiajs/react';
import {
  Button,
  Card,
  Group,
  Input,
  PasswordInput,
  Switch,
  TextInput,
} from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Page } from '@/components';
import type { Provider } from '@/types';

interface Props {
  provider: Provider;
}

export default function Edit({ provider }: Props) {
  const { t } = useTranslation();

  const { data, setData, patch, errors } = useForm(provider);

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    patch('/provider/account');
  };

  return (
    <Page title="Update Account">
      <form onSubmit={save}>
        <Card>
          <Card.Section>
            <Field label={t('account.column.first_name')}>
              <TextInput
                required
                name="first_name"
                value={data.first_name}
                onChange={e => {
                  setData('first_name', e.target.value);
                }}
                error={errors.email}
              />
            </Field>
            <Field label={t('account.column.last_name')}>
              <TextInput
                required
                name="last_name"
                value={data.last_name}
                onChange={e => {
                  setData('last_name', e.target.value);
                }}
                error={errors.email}
              />
            </Field>
            <Field label={t('account.column.email')}>
              <Input
                required
                name="email"
                value={data.email}
                onChange={e => {
                  setData('email', e.target.value);
                }}
                error={errors.email}
              />
            </Field>
            <Field label={t('account.column.current_password')}>
              <PasswordInput
                name="current_password"
                onChange={e => {
                  setData('current_password', e.target.value);
                }}
                error={errors.current_password}
              />
            </Field>
            <Field label={t('account.column.password')}>
              <PasswordInput
                name="password"
                value={data.password != null ? data.password : ''}
                onChange={e => {
                  setData('password', e.target.value);
                }}
                error={errors.password}
              />
            </Field>
            <Field label={t('account.column.password_confirmation')}>
              <PasswordInput
                name="password_confirmation"
                value={
                  data.password_confirmation != null
                    ? data.password_confirmation
                    : ''
                }
                onChange={e => {
                  setData('password_confirmation', e.target.value);
                }}
                error={errors.password_confirmation}
              />
            </Field>
            <Field label={t('account.column.email_notification')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ marginRight: '25px' }}>
                  {t('account.column.email_notification_details')}
                </p>
                <Switch
                  onChange={e => {
                    setData('client_signup_alerts', e.target.checked);
                  }}
                  checked={data.client_signup_alerts}
                />
              </div>
            </Field>
          </Card.Section>
          <Card.Section>
            <Group justify="end" p="md">
              <Button type="submit">{t('common.action.save')}</Button>
            </Group>
          </Card.Section>
        </Card>
      </form>
    </Page>
  );
}
