import { Link } from '@inertiajs/react';
import { Anchor, Card, Group, Table, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import type { Company, Site } from '@/types';

interface Props {
  company?: Company & {
    sites?: Site[];
  };
}

export function Sites({ company }: Props) {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Section withBorder>
        <Group p="md" justify="space-between">
          <Text>{t('site.plural')}</Text>
        </Group>
      </Card.Section>
      <Card.Section>
        <Table>
          {company?.sites?.map(site => (
            <Table.Tr key={site.id}>
              <Table.Td>{site.id}</Table.Td>
              <Table.Td>
                <Anchor component={Link} href={`/admin/sites/${site.id}`}>
                  {site.description}
                </Anchor>
              </Table.Td>
            </Table.Tr>
          ))}
        </Table>
      </Card.Section>
    </Card>
  );
}
