import { Link } from '@inertiajs/react';
import {
  Anchor,
  Box,
  Button,
  Card,
  Container,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';

import sampleReceived from '@/assets/images/sample_received.png';
import { APP_SHOP_URL, APP_SUPPORT_URL_RESULTS } from '@/constants';
import { useTrackClientEvent } from '@/hooks';
import {
  client_reports_health_data_path,
  client_tests_collection_sites_path,
  client_tests_index_path,
  client_tests_register_kit_path,
} from '@/routes';
import type { Assay, CollectionSite, Kit, Site } from '@/types';

import { BloodSamplePrompt } from './_BloodSamplePrompt';
import { CollectionSiteSummary } from './_CollectionSiteSummary';
import { KitExpired } from './_KitExpired';

interface Client {
  assays?: Assay[];
  onboarding_assay?: {
    collected_on?: string;
    active_kit?: Kit;
    collection_site: CollectionSite;
    samples_received_at_my: boolean;
  };
}

interface Props {
  client: Client;
  site: Site;
  api_key: string;
}

export default function Index({ client, site, api_key: apiKey }: Props) {
  const { t } = useTranslation();
  const trackEvent = useTrackClientEvent();
  return (
    <Container size="xs">
      <Stack gap="lg">
        {site.site_type === 'Clinic' && (
          <BloodSamplePrompt
            bookingUrl={site.booking_site_url}
            siteName={site.name}
            bookingDescription={site.booking_description}
          />
        )}

        {site.site_type !== 'Clinic' &&
          client.onboarding_assay?.active_kit?.expired === true &&
          !(
            client.onboarding_assay.collected_on != null ||
            client.onboarding_assay.samples_received_at_my
          ) && <KitExpired kit={client.onboarding_assay.active_kit} />}

        {site.site_type !== 'Clinic' &&
          client.onboarding_assay?.active_kit?.expired == null && (
            <Card p="xl">
              <Stack gap="lg">
                <Title size="h2">{t('onboarding.take_a_test.title')}</Title>
                <Text>{t('onboarding.take_a_test.description')}</Text>
                <Group>
                  <Button
                    component="a"
                    href={APP_SHOP_URL}
                    color="blue"
                    variant="outline"
                    target="_blank"
                    onClick={() => {
                      trackEvent('client_visit_store', {});
                    }}
                  >
                    {t('onboarding.buy_kit.title')}
                  </Button>
                  <Button
                    component={Link}
                    href={client_tests_register_kit_path()}
                    color="blue"
                    variant="filled"
                  >
                    {t('onboarding.take_a_test.register_kit.title')}
                  </Button>
                </Group>
              </Stack>
            </Card>
          )}

        {site.site_type !== 'Clinic' &&
          client.onboarding_assay?.active_kit?.expired === false &&
          client.onboarding_assay?.collection_site == null && (
            <Card p="xl">
              <Stack gap="lg">
                <Title size="h2">
                  {t('onboarding.collection_site_card.title')}
                </Title>
                <Text>{t('onboarding.collection_site_card.description')}</Text>
                <Button
                  component={Link}
                  href={client_tests_collection_sites_path({
                    params: { back: client_tests_index_path() },
                  })}
                  color="blue"
                  target="_blank"
                  style={{ width: 'fit-content' }}
                >
                  {t('onboarding.collection_site_card.action.start')}
                </Button>
              </Stack>
            </Card>
          )}

        {site.site_type !== 'Clinic' &&
          client.onboarding_assay?.active_kit != null &&
          client.onboarding_assay?.collection_site != null &&
          !client.onboarding_assay.samples_received_at_my && (
            <CollectionSiteSummary
              collectionSite={client.onboarding_assay?.collection_site}
              apiKey={apiKey}
            />
          )}

        {site.site_type !== 'Clinic' &&
          client.onboarding_assay?.active_kit != null &&
          !client.onboarding_assay?.active_kit?.expired &&
          !client.onboarding_assay?.samples_received_at_my && (
            <Group>
              <Text size="lg">
                Your kit barcode is{' '}
                <strong>{client.onboarding_assay.active_kit.barcode_id}</strong>{' '}
                expiring on{' '}
                <strong>
                  {client.onboarding_assay.active_kit.expiry_date}
                </strong>
              </Text>
            </Group>
          )}

        {client.onboarding_assay?.samples_received_at_my === true && (
          <Card p="xl">
            <Group>
              <Stack gap="xs">
                <Title size="h2">{t('onboarding.sample_received.title')}</Title>
                <Text>{t('onboarding.sample_received.description')}</Text>
                <Anchor
                  href={APP_SUPPORT_URL_RESULTS}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('onboarding.sample_received.result_update')}
                </Anchor>
              </Stack>
              <img src={sampleReceived} alt="Apple App Store" />
            </Group>
          </Card>
        )}

        {client.assays != null && client.assays.length > 0 && (
          <Card p="xl">
            <Stack gap="lg">
              <Title size="h2">{t('onboarding.result_ready.title')}</Title>
              <Text>{t('onboarding.result_ready.description')}</Text>
              <Anchor
                component={Link}
                fw={700}
                href={client_reports_health_data_path()}
              >
                {t('onboarding.result_ready.action.go_to_results')}
              </Anchor>
            </Stack>
          </Card>
        )}

        {site.site_type === 'Clinic' && (
          <Card p="xl">
            <Stack gap="lg">
              <Title size="h2">{t('onboarding.provider.title')}</Title>
              <Box>
                {site.name != null && (
                  <Text>
                    {t('onboarding.provider.link', { name: site.name })}
                  </Text>
                )}
                {site.phone != null && (
                  <Text>
                    {t('onboarding.provider.phone', { phone: site.phone })}
                  </Text>
                )}
                {site.address != null && (
                  <Text>
                    {t('onboarding.provider.address', {
                      address: site.address,
                    })}
                  </Text>
                )}
              </Box>
            </Stack>
          </Card>
        )}
      </Stack>
    </Container>
  );
}
