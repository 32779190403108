export const formatDelta = (delta: number): string => {
  if (delta === -101) {
    return ``;
  } else if (delta > 0) {
    // Up
    return `\u2191 ${Math.round(delta)}`;
  } else if (delta === 0) {
    return `= ${Math.round(delta)}`;
  } else {
    // Down
    return `\u2193 ${Math.abs(Math.round(delta))}`;
  }
};
