import {
  Autocomplete,
  Button,
  Card,
  Checkbox,
  Group,
  Table,
  Text,
  TextInput,
  UnstyledButton,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';

import type { Assay, Kit } from '@/types';
import { resetTime } from '@/utils';

interface Props {
  value: Partial<Kit & { assay?: Assay }>[];
  onChange: (value: Partial<Kit>[]) => void;
  errors: Record<string, string>;
  assays: Assay[];
}

export function KitsAdd({ value = [], onChange, errors, assays }: Props) {
  const add = () => {
    onChange([...value, {}]);
  };
  const update = (idx: number, key: string, nextValue) => {
    onChange(value.map((v, i) => (i === idx ? { ...v, [key]: nextValue } : v)));
  };
  const remove = (idx: number) => {
    onChange(value.map((v, i) => (i === idx ? { ...v, _destroy: true } : v)));
  };

  const { t } = useTranslation();
  return (
    <Card>
      <Card.Section withBorder>
        <Group p="md" justify="space-between">
          <Text>{t('kit.plural')}</Text>
          <Button
            size="xs"
            px="xs"
            onClick={() => {
              add();
            }}
          >
            <FaPlus />
          </Button>
        </Group>
      </Card.Section>
      <Card.Section>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th> {t('kit.column.barcode_id')} </Table.Th>
              <Table.Th> {t('assay.column.assay_test')} </Table.Th>
              <Table.Th> {t('kit.column.tracking_number')} </Table.Th>
              <Table.Th> {t('kit.column.shipped_on')} </Table.Th>
              <Table.Th> {t('kit.column.expiry_date')} </Table.Th>
              <Table.Th> {t('kit.column.active')} </Table.Th>
              <Table.Th> {t('kit.column.verified')} </Table.Th>
              <Table.Th> {t('kit.column.verified_on')} </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {value
              .filter(kit => kit._destroy == null)
              .map((item, idx) => (
                <Table.Tr key={idx}>
                  <Table.Td valign="top">
                    <TextInput
                      value={item.barcode_id ?? ''}
                      name={`barcode_id_${idx}`}
                      error={errors?.[`kits[${idx}].barcode_id`]?.join(' and ')}
                      onChange={e => {
                        update(idx, 'barcode_id', e.target.value);
                      }}
                    />
                  </Table.Td>
                  <Table.Td valign="top">
                    {item.assay?.code !== undefined ? (
                      <p>{item.assay.code}</p>
                    ) : (
                      <Autocomplete
                        defaultValue={item?.assay_id}
                        error={errors?.[`kits[${idx}].assay_id`]?.join(' and ')}
                        onOptionSubmit={value => {
                          update(idx, 'assay_id', Number(value));
                        }}
                        data={assays.map(assay => ({
                          label: assay.code,
                          value: assay.id.toString(),
                        }))}
                      />
                    )}
                  </Table.Td>
                  <Table.Td valign="top">
                    <TextInput
                      value={item.tracking_number ?? ''}
                      name={`tracking_number_${idx}`}
                      error={errors?.[`kits[${idx}].tracking_number`]?.join(
                        ' and ',
                      )}
                      onChange={e => {
                        update(idx, 'tracking_number', e.target.value);
                      }}
                    />
                  </Table.Td>
                  <Table.Td valign="top">
                    <DateInput
                      name={`shipped_on_${idx}`}
                      value={
                        item.shipped_on != null
                          ? new Date(item.shipped_on)
                          : null
                      }
                      onChange={date => {
                        update(
                          idx,
                          'shipped_on',
                          date != null ? resetTime(date) : null,
                        );
                      }}
                      error={errors?.[`kits[${idx}].shipped_on`]?.join(' and ')}
                    />
                  </Table.Td>
                  <Table.Td valign="top">
                    <DateInput
                      name={`expiry_date_${idx}`}
                      value={
                        item.expiry_date != null
                          ? new Date(item.expiry_date)
                          : null
                      }
                      onChange={date => {
                        update(
                          idx,
                          'expiry_date',
                          date != null ? resetTime(date) : null,
                        );
                      }}
                      error={errors?.[`kits[${idx}].expiry_date`]?.join(
                        ' and ',
                      )}
                    />
                  </Table.Td>
                  <Table.Td valign="top">
                    <Checkbox
                      checked={item.active}
                      onChange={e => {
                        update(idx, 'active', e.target.checked);
                      }}
                    />
                  </Table.Td>
                  <Table.Td valign="top">
                    <Checkbox
                      checked={item.verified}
                      onChange={e => {
                        update(idx, 'verified', e.target.checked);
                      }}
                    />
                  </Table.Td>
                  <Table.Td valign="top">
                    <DateInput
                      name={`verified_on_${idx}`}
                      value={
                        item.verified_on != null
                          ? new Date(item.verified_on)
                          : null
                      }
                      onChange={date => {
                        update(
                          idx,
                          'verified_on',
                          date != null ? resetTime(date) : null,
                        );
                      }}
                      error={errors?.[`kits[${idx}].verified_on`]?.join(
                        ' and ',
                      )}
                    />
                  </Table.Td>
                  {value.length > 0 && (
                    <Table.Td valign="top">
                      <UnstyledButton
                        mt="11"
                        type="button"
                        onClick={() => {
                          if (
                            confirm(
                              t('kit_batch.confirm.delete', {
                                id: item.barcode_id,
                              }),
                            )
                          ) {
                            remove(idx);
                          }
                        }}
                      >
                        <FaTrashAlt />
                      </UnstyledButton>
                    </Table.Td>
                  )}
                </Table.Tr>
              ))}
          </Table.Tbody>
        </Table>
      </Card.Section>
    </Card>
  );
}
