import { Link, useForm } from '@inertiajs/react';
import {
  Alert,
  Anchor,
  Button,
  Card,
  Container,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Back } from '@/components';
import { APP_SUPPORT_EMAIL } from '@/constants';
import {
  client_account_edit_health_profile_path,
  client_tests_index_path,
  client_tests_validate_kit_path,
} from '@/routes';

interface Props {
  update_health_profile: boolean;
}

export default function RegisterKit({
  update_health_profile: updateHealthProfile,
}: Props) {
  const { t } = useTranslation();

  const { data, setData, post, errors } = useForm({
    kit_barcode: '',
  });

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    post(client_tests_validate_kit_path());
  };

  return (
    <Container size="xs">
      <Stack gap="xl">
        <Back
          label={t('onboarding.take_a_test.register_kit.title')}
          to={client_tests_index_path()}
        />
        {updateHealthProfile && (
          <Alert radius="md" color="green">
            <Text>
              {t('onboarding.take_a_test.register_kit.health_profile_alert')}{' '}
              <Anchor
                component={Link}
                href={client_account_edit_health_profile_path()}
              >
                {t(
                  'onboarding.take_a_test.register_kit.action.update_health_profile',
                )}
              </Anchor>
            </Text>
          </Alert>
        )}
        <form onSubmit={save}>
          <Card p="xl">
            <Stack gap="lg">
              <Text size="xl">
                {t('onboarding.take_a_test.register_kit.kit_barcode')}
              </Text>
              <Text size="sm" c="dimmed">
                {t('onboarding.take_a_test.register_kit.description')}
              </Text>
              <TextInput
                required
                name="kit_barcode"
                value={data.kit_barcode}
                onChange={e => {
                  setData('kit_barcode', e.target.value);
                }}
                placeholder="XXX-X-XXX"
                error={typeof errors === 'string' ? errors : null}
              />
              <Text size="sm" c="dimmed">
                {t(
                  'onboarding.take_a_test.register_kit.kit_opened_description',
                )}{' '}
                <Anchor
                  fw="500"
                  href={`mailto:${APP_SUPPORT_EMAIL}`}
                  c="dimmed"
                >
                  {APP_SUPPORT_EMAIL}
                </Anchor>
              </Text>
              <Button type="submit" style={{ width: 'fit-content' }}>
                {t('onboarding.take_a_test.register_kit.action.register')}
              </Button>
            </Stack>
          </Card>
        </form>
      </Stack>
    </Container>
  );
}
