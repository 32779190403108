import { Link } from '@inertiajs/react';
import { Button, Card, Menu } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaEllipsisV, FaExclamationTriangle } from 'react-icons/fa';

import { DataGrid, Page, Pagination, Toolbar } from '@/components';
import type { Provider } from '@/types';
import { formatDate } from '@/utils';

interface Props {
  providers: Provider[];
}

export default function Index(props: Props) {
  const { t } = useTranslation();

  const searchCols = [
    'first_name',
    'last_name',
    'email',
    'provider_sites_site_description',
  ];

  return (
    <Page
      title="Providers"
      actions={
        <Button component={Link} href="/admin/providers/new">
          Create Provider
        </Button>
      }
    >
      <Card>
        <Card.Section>
          <Toolbar searchCols={searchCols} />
          <DataGrid
            data={props.providers}
            columns={[
              {
                header: t('common.column.id'),
                accessorFn: row => row.id,
              },
              {
                header: t('user.column.first_name'),
                accessorFn: row => row.first_name,
              },
              {
                header: t('user.column.last_name'),
                accessorFn: row => row.last_name,
              },
              {
                header: t('user.column.email'),
                accessorFn: row => row.email,
              },
              {
                header: t('user.column.role'),
                accessorFn: row =>
                  t(`provider.role.${row.role ?? 'none'}.label`),
              },
              {
                header: t('user.column.invitation_accepted'),
                cell: ({ row }) => (
                  <>
                    {row.original.invitation_accepted_at != null ? (
                      <div>
                        <FaCheck style={{ color: 'lightblue' }} size={25} />
                      </div>
                    ) : (
                      <div style={{ color: 'orange' }}>
                        <FaExclamationTriangle style={{ marginRight: '5px' }} />
                        Pending
                      </div>
                    )}
                  </>
                ),
              },
              {
                header: t('common.column.created_at'),
                accessorFn: row => formatDate(row.created_at),
              },
              {
                header: t('common.column.updated_at'),
                accessorFn: row => formatDate(row.updated_at),
              },
              {
                header: t('site.plural'),
                accessorFn: row => {
                  const providerSites = row.provider_sites;
                  return providerSites
                    ?.map(site => site.site.description)
                    .join(', ');
                },
              },
              {
                id: 'actions',
                cell: ({ row }) => (
                  <Menu>
                    <Menu.Target>
                      <span>
                        <FaEllipsisV />
                      </span>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        component={Link}
                        href={`/admin/providers/${row.original.id}/edit`}
                      >
                        {t('common.action.edit')}
                      </Menu.Item>
                      {row.original.status === 'active' && (
                        <Menu.Item
                          component={Link}
                          method="delete"
                          as="button"
                          href={`/admin/providers/${row.original.id}/deactivate`}
                          onClick={e => {
                            if (
                              !confirm(
                                t('user.message.deactivate_confirmation', {
                                  name: row.original.first_name,
                                }),
                              )
                            ) {
                              e.preventDefault();
                            }
                          }}
                        >
                          {t('user.action.deactivate')}
                        </Menu.Item>
                      )}
                      <Menu.Item
                        component={Link}
                        method="delete"
                        as="button"
                        href={`/admin/providers/${row.original.id}`}
                        onClick={e => {
                          if (
                            !confirm(
                              t('user.message.delete_confirmation', {
                                name: row.original.first_name,
                              }),
                            )
                          ) {
                            e.preventDefault();
                          }
                        }}
                      >
                        {t('common.action.delete')}
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
          <Pagination />
        </Card.Section>
      </Card>
    </Page>
  );
}
