import { useForm } from '@inertiajs/react';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  Center,
  Checkbox,
  Group,
  Modal,
  Stack,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { snakeCase } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';

import { Field, Page } from '@/components';
import { useTrackProviderEvent } from '@/hooks';
import type { Client, Kit } from '@/types';

interface Props {
  client: Client;
  kits: Kit[];
}

function New({ client, kits }: Props) {
  const { t } = useTranslation();

  const trackEvent = useTrackProviderEvent();

  const { data, setData, post } = useForm({ kit_id: '' });
  const [checklistItems, setChecklistItems] = useState<string[]>([]);
  const [opened, { open, close }] = useDisclosure(false);
  const [showChecklistError, setShowChecklistError] = useState(false);
  const [showOnboardingError, setShowOnboardingError] = useState(false);

  const isSelectedKit = function (kit: Kit): boolean {
    return kit.id === Number(data.kit_id);
  };

  const clientCompletedOnboarding = function (client: Client): boolean {
    return (
      client.date_of_birth !== null &&
      client.sex !== null &&
      client.primary_questionnaire !== null
    );
  };

  return (
    <Page title="New Test">
      <Card>
        <Card.Section>
          <Modal opened={opened} onClose={close} withCloseButton={false}>
            <form
              onSubmit={e => {
                e.preventDefault();
                post(`/provider/clients/${client.id}/assays`);
              }}
            >
              <Text size="xl" fw={700} ta="center">
                {t('checklist.modal.title')}
              </Text>
              <Text ta="center" my="20">
                {t('checklist.modal.description', {
                  barcode: kits?.find(isSelectedKit)?.barcode_id,
                  name: `${client.first_name} ${client.last_name}`,
                })}
              </Text>
              <Center>
                <Group>
                  <Button onClick={close}>{t('common.action.cancel')}</Button>
                  <Button type="submit">{t('common.action.confirm')}</Button>
                </Group>
              </Center>
            </form>
          </Modal>
          <Field label={t('kit.column.barcode_id')}>
            <Autocomplete
              onOptionSubmit={value => {
                setData('kit_id', value);
              }}
              data={kits.map(kit => ({
                label: kit.barcode_id,
                value: kit.id.toString(),
              }))}
              placeholder={
                kits.length > 0
                  ? t('checklist.placeholder', { total: kits.length })
                  : t('checklist.no_barcode_placeholder')
              }
            />
          </Field>
          <Field label={t('checklist.singular')}>
            <Checkbox.Group
              value={checklistItems}
              onChange={value => {
                setShowChecklistError(false);
                setChecklistItems(value);
              }}
            >
              <Stack>
                {['fast', 'exercise', 'cancer', 'sick'].map(requirement => (
                  <Checkbox
                    key={requirement}
                    value={snakeCase(requirement)}
                    label={t(`checklist.requirement.${requirement}`)}
                  />
                ))}
              </Stack>
            </Checkbox.Group>
          </Field>
        </Card.Section>
        <Card.Section>
          <Group justify="end" p="md">
            {showChecklistError && (
              <Text c="red">{t('checklist.alert.requirements_not_met')}</Text>
            )}
            {showOnboardingError && (
              <Text c="red">{t('checklist.alert.missing_onboarding')}</Text>
            )}
            <Button
              type="button"
              disabled={data.kit_id === ''}
              onClick={() => {
                if (checklistItems.length === 4) {
                  if (clientCompletedOnboarding(client)) {
                    open();
                  } else {
                    setShowOnboardingError(true);
                  }
                } else {
                  setShowChecklistError(true);
                  trackEvent('checklist_unfilled_error');
                }
              }}
            >
              {t('common.action.create')}
            </Button>
          </Group>
        </Card.Section>
      </Card>
      <Alert color="yellow" icon={<FaInfoCircle />}>
        {t('assay.alert.barcode_not_available')}
      </Alert>
    </Page>
  );
}

export default New;
