import { Anchor, Button, Card, Input, Space, Stack, Text } from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { APP_SUPPORT_EMAIL } from '@/constants';
import { useForm } from '@/hooks';
import type { UserType } from '@/types';

interface Props {
  resource_name: UserType;
  paths: {
    confirmation_path: string;
  };
}

export function New({ resource_name: resourceName, paths }: Props) {
  const { t } = useTranslation();

  const { getPath, setPath, post } = useForm<
    Record<
      UserType,
      {
        email: string;
      }
    >
  >();

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    post(paths.confirmation_path);
  };

  return (
    <form onSubmit={save}>
      <Card>
        <Text size="xl" fw={700} mb="xl">
          {t('confirmation.new.title')}
        </Text>
        <Stack>
          <Text size="xl">{t('confirmation.new.sub_title')}</Text>
          <Input
            placeholder={t('confirmation.new.email')}
            name="email"
            required
            autoCapitalize="none"
            value={getPath(`${resourceName}.email`, '')}
            onChange={e => setPath(`${resourceName}.email`, e.target.value)}
          />
          <Text size="xl">
            {t('confirmation.new.help')}{' '}
            <Anchor href={`mailto:${APP_SUPPORT_EMAIL}`} fw={500}>
              {APP_SUPPORT_EMAIL}
            </Anchor>
          </Text>
        </Stack>
        <Space h="xl" />
        <div>
          <Button type="submit">{t('confirmation.new.resend_email')}</Button>
        </div>
      </Card>
    </form>
  );
}

export default New;
