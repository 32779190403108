import type { PageProps } from '@inertiajs/core';
import { usePage } from '@inertiajs/react';

interface Props<T> extends PageProps {
  current_user?: T;
}

export function useUser<T>(): T {
  const page = usePage<Props<T>>();

  if (!page.props.current_user) {
    throw new Error('login_required');
  }

  return page.props.current_user;
}
