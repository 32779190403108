import type { CardProps } from '@mantine/core';
import { Box, Card, createPolymorphicComponent, Group } from '@mantine/core';
import { forwardRef } from 'react';

import type { Color } from '@/types';
import { getColor } from '@/utils';

import classes from './CardWithTab.module.css';

interface Props extends CardProps {
  color: Color;
}

export const CardWithTab = createPolymorphicComponent<'div', Props>(
  forwardRef<HTMLDivElement, Props>(({ color, children, ...props }, ref) => (
    <Card className={classes.card} p={0} {...props} ref={ref}>
      <Group align="stretch" wrap="nowrap" gap="lg">
        <Box data-color={color} miw="12px" bg={getColor(color)} />
        <Box py="md" pr="xl" style={{ flexGrow: 1 }}>
          {children}
        </Box>
      </Group>
    </Card>
  )),
);
