import { sortBy } from 'lodash/fp';

import type { SampleConcentration } from '@/types';

export const sortSampleConcentrations = (
  sort: string,
  list: SampleConcentration[],
) => {
  switch (sort) {
    case 'alphabetically':
      return sortBy([row => row.measure_name.toLowerCase()], list);
    case 'highest_score':
      return list.toReversed();
    default:
      return list;
  }
};
