import { Page } from '@/components';
import type { Admin, PrivilegeSet } from '@/types';

import { Form } from './_Form';

interface Props {
  admin: Admin & {
    privilege_set: PrivilegeSet;
  };
}

export default function Edit(props: Props) {
  return (
    <Page title="Update Admin">
      <Form admin={props.admin} />
    </Page>
  );
}
