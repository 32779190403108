import { Link } from '@inertiajs/react';
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { RiMapPin2Line } from 'react-icons/ri';

import type { CollectionSite } from '@/api/client';
import { FormatText } from '@/components';
import { client_tests_register_collection_site_path } from '@/routes';

import classes from './_CollectionSiteRow.module.css';

interface Props {
  collectionSite: CollectionSite;
  onClick: () => void;
}

export function CollectionSiteRow({ collectionSite, onClick }: Props) {
  const { t } = useTranslation();

  const [opened, { toggle }] = useDisclosure(false);

  return (
    <Box className={classes.root} data-opened={opened ?? undefined}>
      <Group justify="space-between" p="xs">
        <Stack gap="xs">
          <Group gap="xs">
            <Text>{collectionSite.name}</Text>
            <ActionIcon
              variant="subtle"
              radius="xl"
              onClick={e => {
                e.stopPropagation();
                onClick();
              }}
            >
              <RiMapPin2Line />
            </ActionIcon>
          </Group>

          <Text size="sm" c="light-gray">
            {collectionSite.distance.toFixed(1)} km
          </Text>
          {opened && (
            <Stack gap="xs">
              <Text size="sm" c="dimmed" mt="xs">
                {collectionSite.opening_hours != null ? (
                  <FormatText
                    character="\n"
                    string={collectionSite.opening_hours}
                  />
                ) : null}
              </Text>
              <Text size="sm" c="dimmed" mt="xs">
                {t('onboarding.collection_site.booking_method', {
                  method: collectionSite.booking_preference,
                })}
              </Text>
            </Stack>
          )}
          <Anchor onClick={toggle} size="sm">
            {opened ? 'Hide details' : 'Show details'}
          </Anchor>
        </Stack>
        {opened && (
          <Button
            component={Link}
            href={client_tests_register_collection_site_path()}
            data={{ collection_site_id: collectionSite.id }}
            method="post"
            as="button"
          >
            {t('onboarding.collection_site.action.select')}
          </Button>
        )}
      </Group>
    </Box>
  );
}
