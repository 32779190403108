import { Grid, ScrollArea, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Action, ActionType, Effect } from '@/types';

import { ActionPlanContent } from './_Content';
import { NavGroup } from './_NavGroup';

interface Props {
  actions: Record<ActionType, (Action & { effects: Effect[] })[]>;
  action_plan: {
    id: string;
    recommendations: {
      diet: Action[];
      supplement: Action[];
      lifestyle: Action[];
    };
  };
}

export default function Index({ action_plan }: Props) {
  const { t } = useTranslation();

  const [selectedActionDetail, setSelectedActionDetail] =
    useState<Action | null>(null);
  useEffect(() => {
    if (action_plan && action_plan.recommendations) {
      const recommendations = action_plan.recommendations;
      for (const key of Object.keys(recommendations).sort()) {
        if (recommendations[key].length > 0) {
          setSelectedActionDetail(recommendations[key][0]);
          break;
        }
      }
    }
  }, [action_plan]);

  if (action_plan) {
    return (
      <Grid gutter="xl">
        <Grid.Col span={{ base: 13, sm: 3 }}>
          <ScrollArea h={1000} pr={3}>
            <NavGroup
              recommendations={action_plan.recommendations}
              value={selectedActionDetail}
              onChange={index => {
                setSelectedActionDetail(index);
              }}
            />
          </ScrollArea>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 9 }}>
          <ScrollArea h={1000} pr={3}>
            <ActionPlanContent
              actionPlanIdentifier={action_plan.id}
              action={selectedActionDetail}
            />
          </ScrollArea>
        </Grid.Col>
      </Grid>
    );
  }

  return (
    <Text p="xl" ta="center">
      {t('action_plan.no_plan.no_plan_found')}
    </Text>
  );
}
