import { Card, Table, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import type { Assay } from '@/types';

interface Props {
  value: (Assay & {
    report: Report;
  })[];
}

export function Assays({ value = [] }: Props) {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Section p="md" withBorder>
        <Text>{t('assay.column.purchased_tests')}</Text>
      </Card.Section>
      <Card.Section>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{t('assay.column.test_id_and_order_date')}</Table.Th>
              <Table.Th>{t('report.column.report_release_status')}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {value.map(value => (
              <Table.Tr key={value.id}>
                {value.report != null ? (
                  <>
                    <Table.Td>{value.display_assay_created_date}</Table.Td>
                    <Table.Td>
                      {value.report.current_status}{' '}
                      {value.report.released_to_client_at}
                    </Table.Td>
                  </>
                ) : (
                  <>
                    <Table.Td>{value.display_assay_created_date}</Table.Td>
                    <Table.Td>{t('assay.column.no_report_available')}</Table.Td>
                  </>
                )}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Card.Section>
    </Card>
  );
}
