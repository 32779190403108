import { Grid, Text } from '@mantine/core';

interface Props {
  label: string;
  value?: string;
}

export function Field({ label, value }: Props) {
  return (
    <>
      <Grid.Col span={7}>
        <Text fw={500} size="sm">
          {label}
        </Text>
      </Grid.Col>
      <Grid.Col span={5}>
        <Text size="sm">{value != null && value !== '' ? value : 'None'}</Text>
      </Grid.Col>
    </>
  );
}
