import { useForm } from '@inertiajs/react';
import { Button, Card, Group, TextInput } from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import type { Company } from '@/types';

interface Props {
  company?: Company;
}

export function Form({ company }: Props) {
  const { t } = useTranslation();

  const { data, setData, submit, errors } = useForm(company);

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    submit(
      company?.id != null ? 'patch' : 'post', //
      `/admin/companies/${company?.id ?? ''}`,
    );
  };

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section>
          <Field label={t('company.column.name')}>
            <TextInput
              required
              value={data.name}
              onChange={e => {
                setData('name', e.target.value);
              }}
              error={errors.name}
            />
          </Field>
        </Card.Section>
        <Card.Section>
          <Group p="md" justify="end">
            <Button type="submit">{t('common.action.save')}</Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
