import { Link } from '@inertiajs/react';
import { Anchor, Box, Text } from '@mantine/core';

interface Props {
  headline: string;
  strapline: string;
  href: string;
}

export function Empty({ headline, strapline, href }: Props) {
  return (
    <Box px="xl" py={120}>
      <Text ta="center" fw={500} c="dimmed">
        {headline}
      </Text>
      <Text ta="center">
        <Anchor component={Link} href={href}>
          {strapline}
        </Anchor>
      </Text>
    </Box>
  );
}
