import { Card } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import type { Vendor } from '@/types';

interface Props {
  vendor: Vendor;
}

export function Details({ vendor }: Props) {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Section>
        <Field label={t('vendor.column.name')}>{vendor.name}</Field>
        <Field label={t('vendor.column.display_name_as')}>
          {vendor.display_name}
        </Field>
        <Field label={t('vendor.column.contact_information')}>
          {vendor.contact_title} {vendor.contact_first_name}{' '}
          {vendor.contact_last_name}
        </Field>
        <Field label={t('vendor.column.phone')}>{vendor.contact_phone}</Field>
        <Field label={t('vendor.column.mobile')}>{vendor.contact_mobile}</Field>
        <Field label={t('vendor.column.fax')}>{vendor.contact_fax}</Field>
        <Field label={t('vendor.column.address')}>{vendor.full_address}</Field>
        <Field label={t('vendor.column.mailing_address')}>
          {vendor.mailing_address}
        </Field>
        <Field label={t('vendor.column.email')}>{vendor.contact_email}</Field>
        <Field label={t('vendor.column.notes')}>{vendor.notes}</Field>
      </Card.Section>
    </Card>
  );
}
