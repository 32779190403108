import { Link } from '@inertiajs/react';
import {
  Anchor,
  Button,
  Card,
  Checkbox,
  Input,
  PasswordInput,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from '@/hooks';
import type { UserType } from '@/types';

interface Props {
  resource_name: UserType;
  paths: {
    new_password_path: string;
    session_path: string;
  };
}

function New({ resource_name: resourceName, paths }: Props) {
  const { t } = useTranslation();

  const { getPath, setPath, post } = useForm<
    Record<
      UserType,
      {
        email: string;
        password: string;
        remember_me: boolean;
      }
    >
  >();

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    post(paths.session_path);
  };

  return (
    <form onSubmit={save}>
      <Card padding="xl">
        <Text size="xl" fw={700} mb="xl">
          {t(`session.${resourceName}.title`)}
        </Text>
        <Stack>
          <Input
            placeholder={t('session.new.email')}
            name="email"
            autoCapitalize="none"
            value={getPath(`${resourceName}.email`, '')}
            onChange={e => setPath(`${resourceName}.email`, e.target.value)}
          />
          <PasswordInput
            placeholder={t('session.new.password')}
            name="password"
            value={getPath(`${resourceName}.password`, '')}
            onChange={e => setPath(`${resourceName}.password`, e.target.value)}
          />
          <Checkbox
            label={t('session.new.remember_me')}
            value={getPath(`${resourceName}.remember_me`, false)}
            onChange={e =>
              setPath(`${resourceName}.remember_me`, e.target.checked)
            }
          />
        </Stack>
        <Space h="xl" />
        <Stack>
          <div>
            <Button type="submit">{t('common.action.login')}</Button>
          </div>
          <Anchor
            fw={500}
            size="sm"
            component={Link}
            href={paths.new_password_path}
          >
            {t('session.new.forgot_password')}
          </Anchor>
        </Stack>
      </Card>
    </form>
  );
}

export default New;
