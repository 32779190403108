import { Link } from '@inertiajs/react';
import { ActionIcon } from '@mantine/core';
import { FaCog } from 'react-icons/fa';

import { IfHasPrivilege } from '@/components';

export function ClinicSettings() {
  return (
    <IfHasPrivilege privilege="change_site">
      <ActionIcon
        c="dimmed"
        radius="xl"
        variant="light"
        component={Link}
        href="/provider/site/edit"
      >
        <FaCog />
      </ActionIcon>
    </IfHasPrivilege>
  );
}
