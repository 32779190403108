import { Card, Select, Stack, TextInput } from '@mantine/core';
import type { Libraries } from '@react-google-maps/api';
import { useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, PlacesAutocomplete } from '@/components';
import type { Address } from '@/types';
const libraries: Libraries = ['places'];
import { COUNTRY } from '@/constants';

interface AddressDetails {
  lat: number;
  lng: number;
  street: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
  locality: string;
}

interface Props {
  address: Address;
  carmen: Record<
    string,
    {
      code: string;
      name: string;
    }[]
  >;
  api_key: string;
  errors: Record<string, string>;
  onAddressChange: (updatedAddress: Address) => void;
}

const countryMap: Record<string, string> = {
  Canada: 'CA',
  'United States': 'US',
};

export function AddressAutocomplete({
  address,
  carmen,
  api_key,
  errors,
  onAddressChange,
}: Props) {
  const { t } = useTranslation();
  const { isLoaded } = useJsApiLoader({
    id: 'google-maps-script',
    googleMapsApiKey: api_key,
    libraries,
  });

  const [formState, setFormState] = useState<Address>(address);

  const update = (field: keyof Address, value: string | number) => {
    const updatedState = {
      ...formState,
      [field]: value,
      address_type: 'shipping',
    };
    setFormState(updatedState);
    onAddressChange(updatedState);
  };

  useEffect(() => {
    setFormState(prevState => {
      const updatedState = { ...prevState };
      (Object.keys(address) as (keyof Address)[]).forEach(key => {
        if (!prevState[key] && address[key] !== undefined) {
          updatedState[key] = address[key];
        }
      });
      return updatedState;
    });
  }, [address]);

  const handlePlaceChange = (details: AddressDetails) => {
    const updatedState = {
      ...formState,
      address_line_1: details.locality,
      address_line_2: details.street,
      city: details.city,
      postal_code: details.postalCode,
      province_state: details.province,
      country: countryMap[details.country] || details.country,
    };
    setFormState(updatedState);
    onAddressChange(updatedState);
  };

  return (
    <Card mb="lg">
      <Stack>
        <Field label={t('collection_site.column.search_address')}>
          {isLoaded && <PlacesAutocomplete onChange={handlePlaceChange} />}
        </Field>
        <Field label={t('collection_site.column.street_address')} required>
          <Stack>
            <TextInput
              required
              value={formState.address_line_1 || ''}
              onChange={e => update('address_line_1', e.target.value)}
              error={errors['addresses[0].address_line_1']}
              placeholder="Unit number, street number..."
            />
            <TextInput
              value={formState.address_line_2 || ''}
              onChange={e => update('address_line_2', e.target.value)}
              error={errors['addresses[0].address_line_2']}
              placeholder="Street name, building name..."
            />
          </Stack>
        </Field>
        <Field label={t('collection_site.column.city')} required>
          <TextInput
            required
            value={formState.city || ''}
            onChange={e => update('city', e.target.value)}
            error={errors['addresses[0].city']}
          />
        </Field>
        <Field label={t('collection_site.column.country')} required>
          <Select
            required
            value={formState.country || ''}
            onChange={value => update('country', value as string)}
            data={COUNTRY}
            error={errors['addresses[0].country']}
          />
        </Field>
        <Field label={t('collection_site.column.province_state')} required>
          <Select
            required
            value={formState.province_state}
            onChange={code => {
              if (code) {
                update('province_state', code);
              }
            }}
            data={
              carmen[formState.country]
                ? carmen[formState.country].map(item => ({
                    label: item.name,
                    value: item.code,
                  }))
                : []
            }
            error={errors['addresses[0].province_state']}
          />
        </Field>
        <Field label={t('collection_site.column.postal_zip_code')} required>
          <TextInput
            required
            value={formState.postal_code || ''}
            onChange={e => update('postal_code', e.target.value)}
            error={errors['addresses[0].postal_code']}
          />
        </Field>
      </Stack>
    </Card>
  );
}
