import { Link } from '@inertiajs/react';
import {
  Container,
  Group,
  NativeSelect,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { RiSearchLine } from 'react-icons/ri';

import { CardWithTab, Empty } from '@/components';
import { useSearch } from '@/hooks';
import {
  client_reports_sample_concentration_path,
  client_tests_index_path,
} from '@/routes';
import { sortSampleConcentrations } from '@/selectors';
import type { SampleConcentration } from '@/types';

import { Menu } from '../_Menu';

interface Props {
  sample_concentrations: SampleConcentration[];
}

export default function Index({
  sample_concentrations: sampleConcentrations,
}: Props) {
  const { t } = useTranslation();

  const { setQuery, setSort, results } = useSearch(
    ['measure_name'],
    sampleConcentrations,
    sortSampleConcentrations,
  );

  const options = [
    { label: 'Lowest score', value: 'lowest_score' },
    { label: 'Highest score', value: 'highest_score' },
    { label: 'Alphabetically', value: 'alphabetically' },
  ];

  return (
    <Container size="sm">
      <Stack gap="xl">
        <Menu />
        <Stack gap="xs">
          <Title size="h2">{t('sample_concentration.headline')}</Title>
          <Text>{t('sample_concentration.strapline')}</Text>
        </Stack>
        <Group>
          <NativeSelect
            data={options}
            onChange={e => setSort(e.target.value)}
          />
          <TextInput
            placeholder={t('sample_concentration.placeholder.query')}
            leftSection={<RiSearchLine />}
            onChange={e => setQuery(e.target.value)}
          />
        </Group>
        {results.length > 0 ? (
          <Stack gap="xs">
            {results.map(result => (
              <CardWithTab
                key={result.id}
                color={result.color}
                component={Link}
                href={client_reports_sample_concentration_path(result.id)}
              >
                <Text>{result.measure_name}</Text>
              </CardWithTab>
            ))}
          </Stack>
        ) : (
          <Empty
            headline={t('report.no_data.headline')}
            strapline={t('report.no_data.strapline')}
            href={client_tests_index_path()}
          />
        )}
      </Stack>
    </Container>
  );
}
