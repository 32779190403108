import { useForm } from '@inertiajs/react';
import {
  Autocomplete,
  Button,
  Card,
  CardSection,
  Checkbox,
  Group,
  Table,
  Text,
  TextInput,
  UnstyledButton,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { omit, set } from 'lodash';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';

import type { Assay, Kit } from '@/types';

interface Props {
  assay: Assay & {
    kits?: Kit[];
  };
  kits: Kit[];
}

export function Kits({ assay, kits }: Props) {
  const { t } = useTranslation();

  const { data, setData, patch, transform } = useForm({
    kits: assay.kits ?? [],
  });

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    patch(`/admin/assays/${assay.id}/kits`, {
      preserveScroll: page => page.props.errors != null,
      preserveState: page => page.props.errors != null,
    });
  };

  const filterValidKits = (
    kits: { barcode_id: string }[],
    validKits: { barcode_id: string }[],
    assayKits: { barcode_id: string }[],
  ) => {
    return kits.filter(kit =>
      [...validKits, ...assayKits].some(
        validKit => validKit.barcode_id === kit.barcode_id,
      ),
    );
  };

  // Filter kits and Rename `kits` to `kits_attributes` before submitting.
  transform(data => {
    const filteredKits = filterValidKits(data.kits, kits, assay.kits ?? []);
    return {
      kits: data.kits,
      ...(filteredKits.length > 0 && {
        kits_attributes: filteredKits.map((item, index) => ({
          ...omit(item, ['verified_on', 'expiry_date']),
          _index: index,
        })),
      }),
    };
  });
  const addKit = () => {
    setData(data => ({
      kits: [...data.kits, {}] as Kit[],
    }));
  };

  const setKit = (index: number, kitId: number) => {
    const selectedKit = kits.find(k => k.id === kitId);
    setData(data => ({
      kits: data.kits.map((kit, i) =>
        i === index && selectedKit != null ? selectedKit : kit,
      ),
    }));
  };

  const updateKit = (index: number, path: keyof Kit, value: unknown) => {
    setData(data => ({
      kits: data.kits.map((kit, i) =>
        i === index ? set(kit, path, value) : kit,
      ),
    }));
  };

  const removeKit = (index: number) => {
    const selectedKit = data.kits[index];
    if (selectedKit.id != null) {
      setData(data => ({
        kits: data.kits.map((kit, i) =>
          i === index
            ? {
                ...kit,
                assay_id: null,
                _filter: true,
              }
            : kit,
        ),
      }));
    } else {
      setData(data => ({
        kits: data.kits.filter((_, i) => i !== index),
      }));
    }
  };
  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section withBorder>
          <Group p="md" justify="space-between">
            <Text>{t('kit.plural')}</Text>
            <Button size="xs" px="xs" onClick={addKit}>
              <FaPlus />
            </Button>
          </Group>
        </Card.Section>
        <CardSection withBorder>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{t('kit.column.barcode_id')}</Table.Th>
                <Table.Th>{t('kit.column.shipped_on')}</Table.Th>
                <Table.Th>{t('kit.column.barcode_id_required')}</Table.Th>
                <Table.Th>{t('kit.column.verified')}</Table.Th>
                <Table.Th>{t('kit.column.verified_on')}</Table.Th>
                <Table.Th>{t('kit.column.expiry_date')}</Table.Th>
                <Table.Th>{t('kit.column.active')}</Table.Th>
                <Table.Th>{t('kit.column.tracking_method')}</Table.Th>
                <Table.Th>{t('kit.column.tracking_number')}</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data.kits
                .filter(kit => kit._filter == null)
                .map((kit, index) => (
                  <Table.Tr key={index}>
                    <Table.Td>
                      {kit.id != null ? (
                        kit.barcode_id
                      ) : (
                        <Autocomplete
                          required
                          defaultValue={kit.barcode_id}
                          onChange={value => {
                            updateKit(index, 'barcode_id', value?.toString());
                          }}
                          onOptionSubmit={value => {
                            setKit(index, Number(value));
                          }}
                          data={
                            kits.length === 0
                              ? [
                                  {
                                    value: '',
                                    label: 'No valid kits found',
                                    disabled: true,
                                  },
                                ]
                              : kits.map(kit => ({
                                  value: kit.id.toString(),
                                  label: kit.barcode_id,
                                }))
                          }
                        />
                      )}
                    </Table.Td>
                    <Table.Td>
                      <DatePickerInput
                        value={
                          kit.shipped_on != null
                            ? new Date(kit.shipped_on)
                            : null
                        }
                        onChange={value => {
                          updateKit(index, 'shipped_on', value?.toString());
                        }}
                      />
                    </Table.Td>
                    <Table.Td>
                      <Checkbox
                        checked={kit.barcode_id_required}
                        onChange={e => {
                          updateKit(
                            index,
                            'barcode_id_required',
                            e.target.checked,
                          );
                        }}
                      />
                    </Table.Td>
                    <Table.Td>
                      <Checkbox
                        checked={kit.verified}
                        onChange={e => {
                          updateKit(index, 'verified', e.target.checked);
                        }}
                      />
                    </Table.Td>
                    <Table.Td>{kit.verified_on ?? '—'}</Table.Td>
                    <Table.Td>{kit.expiry_date ?? '—'}</Table.Td>
                    <Table.Td>
                      <Checkbox
                        checked={kit.active}
                        onChange={e => {
                          updateKit(index, 'active', e.target.checked);
                        }}
                        disabled={
                          !kit.active &&
                          data.kits.some(
                            kit => kit.active === true && kit._filter == null,
                          )
                        }
                      />
                    </Table.Td>
                    <Table.Td>
                      <TextInput
                        value={kit.tracking_method ?? ''}
                        onChange={e => {
                          updateKit(index, 'tracking_method', e.target.value);
                        }}
                      />
                    </Table.Td>
                    <Table.Td>
                      <TextInput
                        value={kit.tracking_number ?? ''}
                        onChange={e => {
                          updateKit(index, 'tracking_number', e.target.value);
                        }}
                      />
                    </Table.Td>
                    <Table.Td>
                      <UnstyledButton
                        onClick={() => {
                          if (
                            confirm(t('kit.confirm.delete', { id: kit.id }))
                          ) {
                            removeKit(index);
                          }
                        }}
                      >
                        <FaTrashAlt />
                      </UnstyledButton>
                    </Table.Td>
                  </Table.Tr>
                ))}
              {data.kits.filter(kit => kit._filter == null).length === 0 && (
                <Table.Tr>
                  <Table.Td align="center" colSpan={9}>
                    {t('common.no_data')}
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Tbody>
          </Table>
        </CardSection>
        <Card.Section>
          <Group justify="end" p="md">
            <Button type="submit">{t('common.action.save')}</Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
