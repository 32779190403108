import { Group, Select, Stack, Text, TextInput } from '@mantine/core';
import type { ReactElement, ReactNode } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';

import { getSampleConcentrations } from '@/api/provider';
import { useUser } from '@/hooks';
import { Provider as Layout } from '@/layouts';
import {
  filterByChange,
  filterByMeasureName,
  sortByColor,
  sortByMeasureName,
} from '@/selectors';
import type { Provider } from '@/types';

import { SampleConcentrationCard } from './_SampleConcentrationCard';
import { SubHeader } from './_SubHeader';

interface Props {
  client: {
    access_token: string;
    share_with_provider: boolean;
    current_report?: {
      id: number;
    };
  };
}

function BiomarkerLookup({ client }: Props) {
  const { t } = useTranslation();

  const provider = useUser<Provider>();

  const sampleConcentrations = useQuery(
    ['SampleConcentrations', client.current_report?.id],
    async () =>
      await getSampleConcentrations(
        { reportId: client.current_report?.id },
        { email: provider.email, apiKey: provider.api_key },
      ),
    { initialData: [] },
  );

  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState('All');

  const data = sampleConcentrations.data
    .filter(filterByMeasureName(query))
    .filter(filterByChange(filter))
    .toSorted(sortByMeasureName)
    .toSorted(sortByColor);

  const hasMultipleBiomarkers = sampleConcentrations.data?.some(
    s => s.biomarker_table.length > 1,
  );

  if (sampleConcentrations.isFetching) {
    return (
      <Text ta="center" p="xl">
        {t('common.loading')}
      </Text>
    );
  }

  return (
    <Stack>
      <Group justify="space-between">
        <Select
          size="sm"
          disabled={hasMultipleBiomarkers == null}
          value={filter}
          onChange={value => {
            setFilter(value ?? 'All');
          }}
          data={['All', 'Worsened', 'Improved']}
        />
        <TextInput
          size="sm"
          value={query}
          onChange={e => {
            setQuery(e.currentTarget.value);
          }}
          placeholder="Search biomarkers"
          rightSection={<BiSearch size={20} />}
        />
      </Group>
      <Text size="16px" fw={700}>
        {t('biomarker.plural')} ({data?.length})
      </Text>
      <Stack gap="xs">
        {data?.map((s, i) => (
          <SampleConcentrationCard
            key={s.id}
            defaultIsOpen={i === 0}
            sampleConcentration={s}
          />
        ))}
      </Stack>
    </Stack>
  );
}

BiomarkerLookup.layout = (page: ReactNode & { props: Props }): ReactElement => (
  <Layout subHeader={<SubHeader client={page.props.client} />} page={page} />
);

export default BiomarkerLookup;
