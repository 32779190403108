import { Link } from '@inertiajs/react';
import {
  Box,
  Card,
  Container,
  Grid,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';

import {
  HealthAreaHistory,
  HealthAreaRisk,
  SampleConcentrationLegend,
} from '@/charts';
import { Back, CardWithTab, Reveal } from '@/components';
import {
  client_reports_health_data_path,
  client_reports_health_datum_path,
  client_reports_sample_concentration_path,
} from '@/routes';
import type { HealthArea, History, SampleConcentration } from '@/types';

interface Props {
  health_report: HealthArea;
  sample_concentrations: SampleConcentration[];
  health_area_history: History[];
}

export default function Show({
  health_report: healthReport,
  sample_concentrations: sampleConcentrations,
  health_area_history: healthAreaHistory,
}: Props) {
  const { t } = useTranslation();

  return (
    <Container size="sm">
      <Stack gap="xl">
        <Stack gap="xl">
          <Group gap="xs">
            <Back
              label={healthReport.name}
              to={client_reports_health_data_path()}
            />
          </Group>
          <Card p="xl" style={{ overflow: 'visible' }}>
            <Grid gutter="xl">
              <Grid.Col span={{ base: 12, md: 7 }}>
                <Stack>
                  <Title order={2} size="h4">
                    Overview
                  </Title>
                  <Box hiddenFrom="md" h={200}>
                    {healthAreaHistory.length > 1 ? (
                      <HealthAreaHistory
                        data={healthAreaHistory.toReversed()}
                      />
                    ) : (
                      healthAreaHistory.map(row => (
                        <HealthAreaRisk
                          name={healthReport.name}
                          risk={row.percentage_risk}
                          color={row.color}
                        />
                      ))
                    )}
                  </Box>
                  <Reveal text={healthReport.description} delimiter="↵↵" />
                </Stack>
              </Grid.Col>
              <Grid.Col span={5} visibleFrom="md">
                <Box h={200}>
                  {healthAreaHistory.length > 1 ? (
                    <HealthAreaHistory data={healthAreaHistory.toReversed()} />
                  ) : (
                    healthAreaHistory.map(row => (
                      <HealthAreaRisk
                        name={healthReport.name}
                        risk={row.percentage_risk}
                        color={row.color}
                      />
                    ))
                  )}
                </Box>
              </Grid.Col>
            </Grid>
          </Card>
        </Stack>
        <Stack gap="lg">
          <Title size="h3">
            {`${healthReport.name} ${t('biomarker.plural').toLowerCase()} (${sampleConcentrations.length})`}
          </Title>
          <SampleConcentrationLegend />
          <Stack gap="xs">
            {sampleConcentrations.map(row => (
              <CardWithTab
                key={row.id}
                color={row.color}
                component={Link}
                href={client_reports_sample_concentration_path(row.id, {
                  params: {
                    back: client_reports_health_datum_path(
                      healthReport.myco_id,
                    ),
                  },
                })}
              >
                <Text>{row.measure_name}</Text>
                {!row.indicated && row.color !== 'green' && (
                  <Text size="xs" c="dimmed">
                    {t('report.biomarker.low_high_warning', {
                      level: row.level === 'low' ? 'high' : 'low',
                    })}
                  </Text>
                )}
              </CardWithTab>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}
