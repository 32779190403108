import { Page } from '@/components';
import type {
  Address,
  Carmen,
  CollectionSiteGroup,
  Company,
  ProductBundle,
  Site,
} from '@/types';

import { Form } from './_Form';

interface Props {
  site: Site & {
    addresses: Address[];
  };
  collection_site_group: CollectionSiteGroup[];
  carmen: Carmen;
  address: Address[];
  product_bundles: ProductBundle[];
  companies: Company[];
}

export default function New(props: Props) {
  return (
    <Page title="Create Site">
      <Form
        site={props.site}
        collectionSiteGroups={props.collection_site_group}
        carmen={props.carmen}
        address={props.site.addresses}
        productBundle={props.product_bundles}
        companies={props.companies}
      />
    </Page>
  );
}
