import axios from 'axios';

import type { Credentials, HealthArea } from '@/types';
import { getPostHeaders } from '@/utils';

interface Params {
  clientId: number;
}

export const getHealthAreas = async (
  { clientId }: Params,
  { email, apiKey }: Credentials,
): Promise<HealthArea[]> =>
  await axios
    .get(
      `${window.location.origin}/provider/api/clients/${clientId}/health_data`,
      {
        headers: {
          ...getPostHeaders(),
          Authorization: `Basic ${btoa(`${email}:${apiKey}`)}`,
        },
        params: {
          show_all: true,
        },
      },
    )
    .then(({ data }) => data.results);
