import { Link, usePage } from '@inertiajs/react';
import { Button } from '@mantine/core';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { DataGrid } from '@/components';
import { usePermissions, useTrackProviderEvent } from '@/hooks';

import { Card } from './_Card';

interface PageProps {
  client: {
    id: number;
    assays: {
      id: number;
      collected_on: string;
      report?: {
        id: number;
        state: string;
      };
      active_kit: {
        barcode_id: string;
      };
    }[];
  };
  links: {
    report_summaries: Record<number, string>;
  };
}

export function Tests() {
  const { hasPrivilege } = usePermissions();
  const { t } = useTranslation();

  const { client, links } = usePage<PageProps>().props;
  const trackEvent = useTrackProviderEvent();

  return (
    <Card
      title={`Tests (${client.assays.length})`}
      buttonLabel="New Test"
      buttonLink={
        hasPrivilege('change_assays')
          ? `/provider/clients/${client.id}/assays/new`
          : null
      }
      padding={false}
      component={
        <DataGrid
          data={client.assays}
          columns={[
            {
              header: 'Barcode',
              cell: ({ row }) =>
                row.original.active_kit != null
                  ? row.original.active_kit.barcode_id
                  : '---',
            },
            {
              header: 'Collected On',
              cell: ({ row }) =>
                row.original.collected_on != null
                  ? dayjs(row.original.collected_on).format('YYYY-MM-DD')
                  : '---',
            },
            {
              header: 'Test Status',
              cell: ({ row }) => {
                const reportState = row.original.report?.state;
                return reportState === 'released' ? (
                  'Report Released'
                ) : reportState === 'provider_review' &&
                  hasPrivilege('change_reports') ? (
                  <Link
                    href={`/provider/reports/${row.original.report?.id ?? ''}/release`}
                    method="post"
                  >
                    <Button
                      onClick={e => {
                        if (!confirm(t('provider.confirm.release'))) {
                          e.preventDefault();
                        } else {
                          trackEvent('release_report_provider');
                        }
                      }}
                    >
                      Release Report
                    </Button>
                  </Link>
                ) : (
                  '---'
                );
              },
            },
            {
              id: 'download_report',
              cell: ({ row }) =>
                row.original.report?.id != null &&
                links.report_summaries[row.original.report.id] != null ? (
                  <a
                    href={links.report_summaries[row.original.report.id]}
                    onClick={() => {
                      trackEvent('pdf_download_provider');
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download PDF
                  </a>
                ) : (
                  '---'
                ),
            },
          ]}
        />
      }
    />
  );
}
