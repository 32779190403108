import { useForm } from '@inertiajs/react';
import {
  Button,
  Card,
  Group,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { Dropzone } from '@mantine/dropzone';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiFileText, FiUpload } from 'react-icons/fi';

import { Field } from '@/components';
import type { Address, Assay, Kit, KitBatch, Site } from '@/types';
import { resetTime } from '@/utils';

import { KitsAdd } from './_KitsAdd';

interface Props {
  kitBatch: KitBatch & {
    kits?: Kit[];
  };
  sites: (Site & {
    addresses: Address[];
  })[];
  assays: Assay[];
  batchType: string;
}

export function Form({ kitBatch, sites, assays, batchType }: Props) {
  const { t } = useTranslation();

  const ADDRESS_TYPE = {
    SHIPPING: 'shipping',
  };

  const [shippingAddress, setShippingAddress] = useState('');

  const handleShippingAddress = (selectedSiteId: number) => {
    const selectedSite = sites.find(site => site.id === selectedSiteId);
    const shippingAddress = selectedSite?.addresses.find(
      address => address.address_type === ADDRESS_TYPE.SHIPPING,
    );
    if (shippingAddress != null) {
      setShippingAddress(shippingAddress.full_address);
    } else {
      setShippingAddress('');
    }
  };

  const { submit, data, setData, errors, transform, processing } =
    useForm(kitBatch);

  transform(data => ({
    component_kit_batch: {
      ...data,
      batch_type: batchType,
      kits_attributes: data.kits,
    },
    batch_type: batchType,
  }));

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    submit(
      kitBatch.id != null ? 'patch' : 'post',
      `/admin/component_batches/${kitBatch.id ?? ''}`,
    );
  };

  return (
    <form onSubmit={save}>
      <Stack gap="xl">
        <Card>
          <Card.Section>
            <Field label={t('kit_batch.column.name')} required>
              <TextInput
                required
                value={data.name}
                onChange={e => {
                  setData('name', e.target.value);
                }}
                error={errors.name}
              />
            </Field>
            <Field label={t('kit_batch.column.date_assembled')} required>
              <DateInput
                required
                value={
                  data.date_assembled != null
                    ? new Date(data.date_assembled)
                    : null
                }
                onChange={value => {
                  if (value instanceof Date) {
                    setData('date_assembled', resetTime(value));
                  }
                }}
                error={errors.date_assembled}
              />
            </Field>
            <Field label={t('kit_batch.column.expiry_date')} required>
              <DateInput
                required
                value={
                  data.expiry_date != null ? new Date(data.expiry_date) : null
                }
                onChange={value => {
                  if (value instanceof Date) {
                    setData('expiry_date', resetTime(value));
                  }
                }}
                error={errors.expiry_date}
              />
            </Field>

            <Field label={t('kit_batch.column.assembled_by')} required>
              <TextInput
                required
                value={data.assembled_by}
                onChange={e => {
                  setData('assembled_by', e.target.value);
                }}
                error={errors.assembled_by}
              />
            </Field>

            <Field label={t('kit_batch.column.shipped_by')}>
              <TextInput
                value={data.shipped_by}
                onChange={e => {
                  setData('shipped_by', e.target.value);
                }}
                error={errors.shipped_by}
              />
            </Field>

            <Field label={t('site.column.shipping_address')}>
              <Select
                value={data.site_id?.toString()}
                onChange={value => {
                  const selectedSiteId = Number(value);
                  setData('site_id', selectedSiteId);
                  handleShippingAddress(selectedSiteId);
                }}
                data={sites.map(site => ({
                  value: site.id.toString(),
                  label: site.description,
                }))}
              />
              {shippingAddress !== null ? (
                <div>
                  <p>{shippingAddress}</p>
                </div>
              ) : (
                <p>{t('site.message.address_not_found')}</p>
              )}
            </Field>
          </Card.Section>
        </Card>
        <KitsAdd
          errors={errors}
          value={data.kits ?? []}
          assays={assays}
          onChange={value => {
            setData('kits', value);
          }}
        />
        <Card title="upload_file">
          <Card.Section p="md">
            <Dropzone
              name="upload_file"
              onDrop={file => {
                setData('upload_file', file[0]);
              }}
              disabled={processing}
              multiple={false}
            >
              <Group
                justify="center"
                gap="xl"
                mih={100}
                style={{ pointerEvents: 'none' }}
              >
                <Dropzone.Accept>
                  <FiUpload size={52} />
                </Dropzone.Accept>
                <Dropzone.Idle>
                  <FiFileText size={52} />
                </Dropzone.Idle>
                <div>
                  <Text size="xl">
                    {t('kit_batch.message.drag_and_drop_file')}
                  </Text>
                  <p>{t('kit_batch.message.expected_column')}</p>
                  <Text size="sm" c="dimmed" inline mt={7}>
                    {data.upload_file?.name ?? kitBatch.upload_file_name}
                  </Text>
                </div>
              </Group>
            </Dropzone>
            <Group p="md" justify="end">
              <Button type="submit">{t('common.action.save')}</Button>
            </Group>
          </Card.Section>
        </Card>
      </Stack>
    </form>
  );
}
