import { Card } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Field, FormatText } from '@/components';
import type { CollectionSite } from '@/types';

interface Props {
  collectionSite: CollectionSite;
  requisitionForms: string;
}

export function Details({ collectionSite, requisitionForms }: Props) {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Section>
        <Field label={t('collection_site.column.name')}>
          {collectionSite.name}
        </Field>
        <Field label={t('collection_site.column.address')}>
          {collectionSite.full_address}
        </Field>
        <Field label={t('collection_site.column.phone')}>
          {collectionSite.phone}
        </Field>
        <Field label={t('collection_site.column.email')}>
          {collectionSite.email}
        </Field>
        <Field label={t('collection_site.column.opening_hours')}>
          {collectionSite.opening_hours != null ? (
            <FormatText character="\n" string={collectionSite.opening_hours} />
          ) : null}
        </Field>
        <Field label={t('collection_site.column.booking_preference')}>
          {collectionSite.booking_preference}
        </Field>
        <Field label={t('collection_site.column.required_forms')}>
          {requisitionForms}
        </Field>
      </Card.Section>
    </Card>
  );
}
