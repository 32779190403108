import { Button, Card, Stack, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { useTrackClientEvent } from '@/hooks';

interface Props {
  bookingUrl: string;
  siteName: string;
  bookingDescription: string;
}

export const BloodSamplePrompt = ({
  bookingUrl,
  siteName,
  bookingDescription,
}: Props) => {
  const { t } = useTranslation();
  const trackEvent = useTrackClientEvent();
  return (
    <Card p="xl">
      <Stack gap="lg">
        <Title size="h2">
          {t('onboarding.blood_draw_booking.title', { name: siteName })}
        </Title>
        <Text>{bookingDescription}</Text>
        {bookingUrl != null && bookingUrl !== '' && (
          <Button
            component="a"
            href={`https://${bookingUrl}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: 'fit-content' }}
            onClick={() => {
              trackEvent('patients_blood_draw_booking', {});
            }}
          >
            {t('onboarding.blood_draw_booking.cta')}
          </Button>
        )}
      </Stack>
    </Card>
  );
};
