import { Page } from '@/components';
import type { Provider, Site } from '@/types';

import { Form } from './_Form';

interface Props {
  sites: Site[];
  provider?: Provider;
}

export default function New({ provider, sites }: Props) {
  return (
    <Page title="Create Staff">
      <Form sites={sites} provider={provider} />
    </Page>
  );
}
