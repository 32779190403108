import { Link } from '@inertiajs/react';
import { Group } from '@mantine/core';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Base } from './_Base';
import { Help } from './_Help';
import { Logo } from './_Logo';
import { UserMenu } from './_UserMenu';

interface Props {
  page: ReactNode;
}

export function Vendor({ page }: Props) {
  const { t } = useTranslation();

  return (
    <Base
      brand={
        <Link
          style={{ textDecoration: 'none', color: 'black' }}
          href="/vendor_representative"
        >
          <Logo />
        </Link>
      }
      links={[
        {
          label: t('batch.plural'),
          href: '/vendor_representative/batches',
        },
      ]}
      menu={
        <Group gap="lg">
          <Help href="https://molecularyouvendors.zendesk.com/hc/en-ca" />
          <UserMenu root="/vendor_representatives" />
        </Group>
      }
    >
      {page}
    </Base>
  );
}
