import { useForm } from '@inertiajs/react';
import {
  Autocomplete,
  Button,
  Card,
  Group,
  NumberInput,
  Select,
  Table,
  Text,
  TextInput,
  UnstyledButton,
} from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { set } from 'lodash';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';

import { SAMPLE_PROFILE, SAMPLE_STATUS, SAMPLE_TYPE } from '@/constants';
import type { Assay, Batch, Sample, Vendor } from '@/types';
import { nestedAttributes } from '@/utils';

interface Props {
  assay: Assay & {
    samples?: (Sample & {
      batch?: Batch;
      vendor?: Vendor;
    })[];
  };
  batches: Batch[];
}

export function Samples({ assay, batches }: Props) {
  const { t } = useTranslation();

  const { data, setData, patch, transform, errors } = useForm({
    samples: assay.samples ?? [],
  });

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    patch(`/admin/assays/${assay.id}/update_samples`, {
      preserveScroll: page => page.props.errors != null,
      preserveState: page => page.props.errors != null,
    });
  };

  // Rename `samples` to `samples_attributes` before submitting.
  transform(nestedAttributes(['samples']));

  const addSample = () => {
    setData(data => ({
      samples: [...data.samples, {}] as Sample[],
    }));
  };

  const updateSample = (index: number, path: keyof Sample, value: unknown) => {
    setData(data => ({
      samples: data.samples.map((item, i) =>
        i === index ? set(item, path, value) : item,
      ),
    }));
  };

  const removeSample = (index: number) => {
    const selectedSample = data.samples[index];
    if (selectedSample.id != null) {
      setData(data => ({
        samples: data.samples.map((item, i) =>
          i === index
            ? {
                ...item,
                _destroy: true,
              }
            : item,
        ),
      }));
    } else {
      setData(data => ({
        samples: data.samples.filter((_, i) => i !== index),
      }));
    }
  };

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section withBorder>
          <Group p="md" justify="space-between">
            <Text>{t('sample.plural')}</Text>
            <Button size="xs" px="xs" onClick={addSample}>
              <FaPlus />
            </Button>
          </Group>
        </Card.Section>
        <Card.Section withBorder>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{t('sample.column.id')}</Table.Th>
                <Table.Th>{t('sample.column.state')}</Table.Th>
                <Table.Th>{t('sample.column.sample_id')}</Table.Th>
                <Table.Th>{t('sample.column.sample_status')}</Table.Th>
                <Table.Th>{t('sample.column.sample_barcode')}</Table.Th>
                <Table.Th>{t('sample.column.sample_type')}</Table.Th>
                <Table.Th>{t('sample.column.profile')}</Table.Th>
                <Table.Th>{t('vendor.singular')}</Table.Th>
                <Table.Th>{t('batch.singular')}</Table.Th>
                <Table.Th>{t('sample.column.amount')}</Table.Th>
                <Table.Th>{t('sample.column.received_at')}</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data.samples
                .filter(sample => sample._destroy == null)
                .map((sample, index) => (
                  <Table.Tr key={index}>
                    <Table.Td>{sample.id ?? '—'}</Table.Td>
                    <Table.Td>
                      {sample.state != null
                        ? t(`sample.state.${sample.state}`)
                        : '—'}
                    </Table.Td>
                    <Table.Td>
                      <TextInput
                        value={sample.sample_id ?? ''}
                        onChange={e => {
                          updateSample(index, 'sample_id', e.target.value);
                        }}
                        error={errors?.[`samples[${index}].sample_id`]}
                      />
                    </Table.Td>
                    <Table.Td>
                      <Select
                        value={sample.sample_status}
                        onChange={value => {
                          updateSample(index, 'sample_status', value);
                        }}
                        data={SAMPLE_STATUS}
                        error={errors?.[`samples[${index}].sample_status`]}
                      />
                    </Table.Td>
                    <Table.Td>
                      <TextInput
                        value={sample.sample_barcode ?? ''}
                        onChange={e => {
                          updateSample(index, 'sample_barcode', e.target.value);
                        }}
                        error={errors?.[`samples[${index}].sample_barcode`]}
                      />
                    </Table.Td>
                    <Table.Td>
                      <Select
                        value={sample.sample_type}
                        onChange={value => {
                          updateSample(index, 'sample_type', value);
                        }}
                        data={SAMPLE_TYPE}
                        error={errors?.[`samples[${index}].sample_type`]}
                      />
                    </Table.Td>
                    <Table.Td>
                      <Select
                        value={sample.profile}
                        onChange={value => {
                          updateSample(index, 'profile', value);
                        }}
                        data={SAMPLE_PROFILE}
                        error={errors?.[`samples[${index}].profile`]}
                      />
                    </Table.Td>
                    <Table.Td>{sample.vendor?.name ?? '—'}</Table.Td>
                    <Table.Td>
                      <Autocomplete
                        defaultValue={sample.batch?.name}
                        onOptionSubmit={value => {
                          updateSample(index, 'batch_id', Number(value));
                        }}
                        data={batches.map(batch => ({
                          value: batch.id.toString(),
                          label: batch.name,
                        }))}
                      />
                    </Table.Td>
                    <Table.Td>
                      <NumberInput
                        value={sample.amount}
                        onChange={e => {
                          updateSample(index, 'amount', e);
                        }}
                        error={errors?.[`samples[${index}].amount`]}
                      />
                    </Table.Td>
                    <Table.Td>
                      <DateTimePicker
                        value={
                          sample.received_at != null
                            ? new Date(sample.received_at)
                            : null
                        }
                        onChange={e => {
                          setData(data => ({
                            samples: data.samples.map((s, i) =>
                              s.received_at != null && i !== index
                                ? s
                                : {
                                    ...s,
                                    received_at: e != null ? e.toString() : '',
                                  },
                            ),
                          }));
                        }}
                        error={errors?.[`samples[${index}].received_at`]}
                      />
                    </Table.Td>
                    <Table.Td>
                      <UnstyledButton
                        onClick={() => {
                          if (
                            confirm(
                              t('sample.confirm.delete', { id: sample.id }),
                            )
                          ) {
                            removeSample(index);
                          }
                        }}
                      >
                        <FaTrashAlt />
                      </UnstyledButton>
                    </Table.Td>
                  </Table.Tr>
                ))}
              {data.samples.filter(sample => sample._destroy == null).length ===
                0 && (
                <Table.Tr>
                  <Table.Td align="center" colSpan={12}>
                    {t('common.no_data')}
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Tbody>
          </Table>
        </Card.Section>
        <Card.Section>
          <Group justify="end" p="md">
            <Button type="submit">{t('common.action.save')}</Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
