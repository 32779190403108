import type { Color } from '@/types';

export const getBatchStateColor = (state: string): Color => {
  switch (state) {
    case 'qc_complete':
      return 'green';
    case 'qc_pending':
    case 'qc_requests_pending':
    case 'qc_vendor_sign_off_needed':
    case 'qc_review_needed':
      return 'yellow';
    default:
      return 'red';
  }
};
