import { Link } from '@inertiajs/react';
import { Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Page } from '@/components';
import type { Vendor } from '@/types';

import { Details } from './_Details';

interface Props {
  vendor: Vendor;
}

function New({ vendor }: Props) {
  const { t } = useTranslation();

  return (
    <Page
      title={`${t('vendor.singular')} ${t('common.action.details')}`}
      actions={
        <Button component={Link} href={`/admin/vendors/${vendor.id}/edit`}>
          {t('common.action.edit')}
        </Button>
      }
    >
      <Details vendor={vendor} />
    </Page>
  );
}

export default New;
