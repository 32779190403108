import { Page } from '@/components';
import type { Assay, Batch, CollectionSite, Kit } from '@/types';

import { Form } from './_Form';
import { Kits } from './_Kits';
import { Samples } from './_Samples';

interface Props {
  assay: Assay;
  collection_sites: CollectionSite[];
  batches: Batch[];
  kits: Kit[];
}

export default function Edit(props: Props) {
  return (
    <Page title="Edit Assay">
      <Form assay={props.assay} collectionSites={props.collection_sites} />
      <Samples assay={props.assay} batches={props.batches} />
      <Kits assay={props.assay} kits={props.kits} />
    </Page>
  );
}
