import { Box, Card, Container, Grid, Stack, Text, Title } from '@mantine/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SampleConcentrationHistory,
  SampleConcentrationLegend,
} from '@/charts';
import { Back } from '@/components';
import { client_reports_sample_concentrations_path } from '@/routes';
import { selectSampleConcentrationHistory } from '@/selectors';
import type { Measure, SampleConcentration } from '@/types';
import { formatRange, formatValue } from '@/utils';

interface Props {
  sample_concentration: SampleConcentration & {
    biomarker_table: (Measure | null)[];
  };
}

export default function Show({
  sample_concentration: sampleConcentration,
}: Props) {
  const { t } = useTranslation();

  const data = useMemo(
    () =>
      selectSampleConcentrationHistory(
        sampleConcentration.biomarker_table,
      ).toReversed(),
    [sampleConcentration.biomarker_table],
  );

  return (
    <Container size="sm">
      <Stack gap="xl">
        <Back
          label={sampleConcentration.measure_name}
          to={client_reports_sample_concentrations_path()}
        />
        <Card padding="xl" style={{ overflow: 'visible' }}>
          <Grid gutter="xl">
            <Grid.Col span={{ base: 12, md: 8 }}>
              <Stack gap="lg">
                <Grid>
                  <Grid.Col span={6}>
                    <Text fw={700}>
                      {t('sample_concentration.column.value')}
                    </Text>
                    {formatValue(
                      sampleConcentration.value,
                      sampleConcentration.unit,
                      sampleConcentration.percentile,
                    )}
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Text fw={700}>
                      {t('sample_concentration.column.range')}
                    </Text>
                    <Text>
                      {formatRange(
                        sampleConcentration.range,
                        sampleConcentration.unit,
                        sampleConcentration.percentile,
                      )}
                    </Text>
                  </Grid.Col>
                </Grid>
                <Stack hiddenFrom="md" gap="xs">
                  <Box h={200}>
                    <SampleConcentrationHistory data={data} />
                  </Box>
                  <SampleConcentrationLegend />
                </Stack>
                <Box>
                  <Title size="h4">
                    {t('sample_concentration.column.normal_description')}
                  </Title>
                  <Text>{sampleConcentration.normal_description}</Text>
                </Box>
                <Box>
                  <Title size="h4">
                    {t('sample_concentration.column.nutrition_description')}
                  </Title>
                  <Text>{sampleConcentration.nutrition_description}</Text>
                </Box>
              </Stack>
            </Grid.Col>
            <Grid.Col span={4} visibleFrom="md">
              <Stack gap="xs">
                <Box h="200">
                  <SampleConcentrationHistory data={data} />
                </Box>
                <SampleConcentrationLegend />
              </Stack>
            </Grid.Col>
          </Grid>
        </Card>
      </Stack>
    </Container>
  );
}
