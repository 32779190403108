import {
  Box,
  Card,
  ColorSwatch,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getBiomarkerDetail } from '@/api/provider/getBiomarkerDetail';
import { SampleConcentrationHistory } from '@/charts';
import { useUser } from '@/hooks';
import { selectSampleConcentrationHistory } from '@/selectors';
import type { Provider } from '@/types';
import { formatRange, formatValue, getColor, getLabel } from '@/utils';

interface Props {
  id: number;
  reportId: number;
}

export function BiomarkerDetails({ id, reportId }: Props) {
  const provider = useUser<Provider>();
  const { t } = useTranslation();

  const {
    data: SampleConcentration,
    isLoading,
    isFetching,
    isSuccess,
  } = useQuery(
    ['SampleConcentration', id],
    async () =>
      await getBiomarkerDetail(
        id,
        { reportId },
        {
          email: provider.email,
          apiKey: provider.api_key,
        },
      ),
    {
      enabled: !!id,
      initialData: () => null,
    },
  );

  const data = SampleConcentration?.sample_concentration;
  const processedData = useMemo(
    () => selectSampleConcentrationHistory(data?.biomarker_table || []),
    [data?.biomarker_table],
  );

  if (isLoading || isFetching) {
    return (
      <Text ta="center" p="xl">
        {t('common.loading')}
      </Text>
    );
  }

  if (isSuccess && data) {
    return (
      <>
        <Title tt="capitalize" lineClamp={1} mb="md" size="h4">
          {data.measure_name}
        </Title>

        <Card style={{ overflow: 'visible' }}>
          <Stack gap="md">
            <Group justify="space-between">
              <Box>
                <Text fw={700}>{t('biofunctions.biomarker.your_result')}</Text>
                <Text>
                  {formatValue(data.value, data.unit, data.percentile)}
                </Text>
              </Box>
              <Box>
                <Text fw={700}>{t('biofunctions.biomarker.ref_range')}</Text>
                <Text>
                  {formatRange(data.range, data.unit, data.percentile)}
                </Text>
              </Box>
            </Group>

            <Box h="200">
              <SampleConcentrationHistory data={processedData.toReversed()} />
            </Box>
            <Group gap="xs" justify="space-between">
              {['green', 'yellow', 'red'].map((color, i) => (
                <Group gap="xs" key={i}>
                  <ColorSwatch
                    size={8}
                    color={getColor(color)}
                    withShadow={false}
                  />
                  <Text size="sm">{getLabel(color)}</Text>
                </Group>
              ))}
            </Group>
            <Box>
              <Text fw={700}>{t('biofunctions.biomarker.overview')}</Text>
              <Text>{data.normal_description}</Text>
            </Box>
            <Box>
              <Text fw={700}>{t('biofunctions.biomarker.how_to_improve')}</Text>
              <Text>{data.nutrition_description}</Text>
            </Box>
          </Stack>
        </Card>
      </>
    );
  }
}
