import { Stack } from '@mantine/core';
import type { ReactNode } from 'react';

import { Auth } from '@/layouts';
import { client_onboarding_update_client_path } from '@/routes';
import type { Client } from '@/types';

import { PersonalInfoForm } from '../_PersonalInfoForm';
import { Progress } from './_Progress';

interface Props {
  client: Client;
  from_summary: boolean;
}

function PersonalInfo({ client, from_summary: fromSummary }: Props) {
  return (
    <Stack gap="xl">
      <Progress active={0} />
      <PersonalInfoForm
        action={client_onboarding_update_client_path()}
        client={client}
        fromSummary={fromSummary}
      />
    </Stack>
  );
}

PersonalInfo.layout = (page: ReactNode) => <Auth page={page} />;

export default PersonalInfo;
