import { Link } from '@inertiajs/react';
import { Button, Card, Menu } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaEllipsisV } from 'react-icons/fa';

import { DataGrid, Page, Pagination, Toolbar } from '@/components';
import type { CollectionSite, CollectionSiteGroup, Site } from '@/types';
import { formatDate } from '@/utils';

interface Props {
  collection_site_groups: (CollectionSiteGroup & {
    sites: Site[];
    collection_sites: CollectionSite[];
  })[];
}

export default function Index(props: Props) {
  const { t } = useTranslation();

  const searchCols = ['name'];

  return (
    <Page
      title="Collection Site Groups"
      actions={
        <Button component={Link} href="/admin/collection_site_groups/new">
          Create Collection Site Group
        </Button>
      }
    >
      <Card>
        <Card.Section>
          <Toolbar searchCols={searchCols} />
          <DataGrid
            data={props.collection_site_groups}
            columns={[
              {
                header: t('common.column.id'),
                accessorFn: row => row.id,
              },
              {
                header: t('collection_site_group.column.name'),
                accessorFn: row => row.name,
              },
              {
                header: t('collection_site_group.column.has_collection_sites'),
                accessorFn: row => row.collection_sites.length,
              },
              {
                header: t('collection_site_group.column.has_sites'),
                accessorFn: row => row.sites.length,
              },
              {
                header: t('common.column.created_at'),
                accessorFn: row => formatDate(row.created_at),
              },
              {
                header: t('common.column.updated_at'),
                accessorFn: row => formatDate(row.updated_at),
              },
              {
                id: 'actions',
                cell: ({ row }) => (
                  <Menu>
                    <Menu.Target>
                      <span>
                        <FaEllipsisV />
                      </span>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        component={Link}
                        href={`/admin/collection_site_groups/${row.original.id}`}
                      >
                        {t(`common.action.show`)}
                      </Menu.Item>
                      <Menu.Item
                        component={Link}
                        href={`/admin/collection_site_groups/${row.original.id}/edit`}
                      >
                        {t(`common.action.edit`)}
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
          <Pagination />
        </Card.Section>
      </Card>
    </Page>
  );
}
