import type { PageProps } from '@inertiajs/core';
import { usePage } from '@inertiajs/react';

import type { Site } from '@/types';

interface Props extends PageProps {
  current_site?: Site;
}

export function useSite(): Site | null {
  const page = usePage<Props>();

  if (page.props.current_site != null) {
    return page.props.current_site;
  }

  return null;
}
