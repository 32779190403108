import type { Color } from '@/types';

export const getLabel = (color: Color): string => {
  switch (color) {
    case 'green':
      return 'In-range';
    case 'yellow':
      return 'Borderline';
    case 'red':
      return 'Out of range';
  }
};
