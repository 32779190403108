import { Group, Stack, Title } from '@mantine/core';

import { Risk } from '@/components';
import type { Color } from '@/types';

interface Props {
  name: string;
  risk: number;
  color: Color;
}

export function HealthAreaRisk({ name, risk, color }: Props) {
  return (
    <Stack gap="lg">
      <Group justify="space-between">
        <Title order={3} size="h5">
          {name}
        </Title>
      </Group>
      <Risk color={color} score={risk} />
    </Stack>
  );
}
