import { useMemo } from 'react';

import { validatePassword } from '@/utils';

interface PasswordValidation {
  isPasswordValid: boolean;
  passwordRequirements: {
    requirement: string;
    message: string;
    pass: boolean;
  }[];
}

export const useValidatePassword = (
  password: string,
  passwordConfirmation: string,
): PasswordValidation =>
  useMemo(
    () => validatePassword(password, passwordConfirmation),
    [password, passwordConfirmation],
  );
