import { Button, Group, Input } from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import { BiSearch } from 'react-icons/bi';

interface Props {
  name: string;
  value: string;
  onSubmit: (value: string) => void;
}

export function SearchInput({ name, value, onSubmit }: Props) {
  const [searchValue, setSearchValue] = useState(value);

  const search = (e: SyntheticEvent) => {
    e.preventDefault();
    onSubmit(searchValue);
  };

  return (
    <form onSubmit={search}>
      <Group gap="xs">
        <Input
          placeholder="Search"
          id={name}
          name={name}
          defaultValue={searchValue}
          onChange={e => {
            setSearchValue(e.target.value);
          }}
          type="search"
        />
        <Button px="sm" type="submit">
          <BiSearch />
        </Button>
      </Group>
    </form>
  );
}
