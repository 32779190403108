import { Stack } from '@mantine/core';
import type { ReactNode } from 'react';

import { Auth } from '@/layouts';
import { client_onboarding_update_health_profile_path } from '@/routes';
import type { Questionnaire, QuestionnaireOptions } from '@/types';

import { HealthProfileForm } from '../_HealthProfileForm';
import { Progress } from './_Progress';

interface Props {
  health_profile: Questionnaire;
  response_options: QuestionnaireOptions;
  from_summary: boolean;
}

function HealthProfile({
  health_profile: healthProfile,
  response_options: responseOptions,
}: Props) {
  return (
    <Stack gap="xl">
      <Progress active={1} />
      <HealthProfileForm
        action={client_onboarding_update_health_profile_path()}
        healthProfile={healthProfile}
        responseOptions={responseOptions}
      />
    </Stack>
  );
}

HealthProfile.layout = (page: ReactNode) => <Auth page={page} />;

export default HealthProfile;
