import { Box, Card, Group, Text } from '@mantine/core';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';

interface Props {
  title: string;
  onToggle: () => void;
  isOpen: boolean;
  children: React.ReactNode;
}

export function CollapsibleCard({ title, isOpen, onToggle, children }: Props) {
  interface ArrowIndicatorProps {
    isOpen: boolean;
  }

  function ArrowIndicator({ isOpen }: ArrowIndicatorProps) {
    const size = 25;
    return isOpen ? (
      <RiArrowUpSLine size={size} />
    ) : (
      <RiArrowDownSLine size={size} />
    );
  }

  return (
    <Card style={{ overflow: 'visible' }}>
      <Card.Section>
        <Group
          onClick={() => {
            onToggle();
          }}
          style={{ cursor: 'pointer' }}
          p="md"
          justify="space-between"
        >
          <Text fw={700} tt="capitalize">
            {title}
          </Text>
          <ArrowIndicator isOpen={isOpen} />
        </Group>
      </Card.Section>

      <Card.Section>
        {isOpen && children && <Box pb="md">{children}</Box>}
      </Card.Section>
    </Card>
  );
}
