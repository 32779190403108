import { Page } from '@/components';
import type {
  Address,
  Carmen,
  CollectionSiteGroup,
  ProductBundle,
  Site,
} from '@/types';

import { Form } from './_Form';

interface Props {
  site: Site & {
    addresses: Address[];
  };
  collection_site_group: CollectionSiteGroup[];
  carmen: Carmen;
  addresses: Address[];
  product_bundles: ProductBundle[];
}

export default function Edit(props: Props) {
  const shippingAddresses = props.site.addresses.filter(
    addr => addr.address_type === 'shipping',
  );

  return (
    <Page title="Update Site">
      <Form
        site={props.site}
        collectionSiteGroups={props.collection_site_group}
        carmen={props.carmen}
        address={
          shippingAddresses.length > 0
            ? shippingAddresses
            : props.site.addresses
        }
        productBundle={props.product_bundles}
      />
    </Page>
  );
}
