import { useForm } from '@inertiajs/react';
import {
  Button,
  Card,
  Group,
  Input,
  PasswordInput,
  TextInput,
} from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Page } from '@/components';
import type { Admin } from '@/types';

interface Props {
  admin: Admin;
}

export default function Edit({ admin }: Props) {
  const { t } = useTranslation();

  const { data, setData, patch, errors } = useForm(admin);

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    patch('/admin/account');
  };

  return (
    <Page title="Update Account">
      <form onSubmit={save}>
        <Card>
          <Card.Section>
            <Field label={t('user.column.first_name')}>
              <TextInput
                required
                name="first_name"
                value={data.first_name}
                onChange={e => {
                  setData('first_name', e.target.value);
                }}
                error={errors.first_name}
              />
            </Field>
            <Field label={t('user.column.last_name')}>
              <TextInput
                required
                name="last_name"
                value={data.last_name}
                onChange={e => {
                  setData('last_name', e.target.value);
                }}
                error={errors.last_name}
              />
            </Field>
            <Field label={t('user.column.email')}>
              <Input
                required
                name="email"
                value={data.email}
                onChange={e => {
                  setData('email', e.target.value);
                }}
                error={errors.email}
              />
            </Field>
            <Field label={t('account.column.current_password')}>
              <PasswordInput
                name="current_password"
                value={
                  data.current_password != null ? data.current_password : ''
                }
                onChange={e => {
                  setData('current_password', e.target.value);
                }}
                error={errors.current_password}
              />
            </Field>
            <Field label={t('account.column.password')}>
              <PasswordInput
                name="password"
                value={data.password != null ? data.password : ''}
                onChange={e => {
                  setData('password', e.target.value);
                }}
                error={errors.password}
              />
            </Field>
            <Field label={t('account.column.password_confirmation')}>
              <PasswordInput
                name="password_confirmation"
                value={
                  data.password_confirmation != null
                    ? data.password_confirmation
                    : ''
                }
                onChange={e => {
                  setData('password_confirmation', e.target.value);
                }}
                error={errors.password_confirmation}
              />
            </Field>
          </Card.Section>
          <Card.Section>
            <Group justify="end" p="md">
              <Button type="submit">{t('common.action.save')}</Button>
            </Group>
          </Card.Section>
        </Card>
      </form>
    </Page>
  );
}
