interface Predicate {
  biomarker_table: {
    color: string;
  }[];
}

export const filterByChange =
  (change: string) =>
  (a: Predicate): boolean => {
    const [first, second] = a.biomarker_table;

    if (first != null && second != null && change === 'Worsened') {
      return (
        (first.color === 'green' && ['yellow', 'red'].includes(second.color)) ||
        (first.color === 'yellow' && second.color === 'red')
      );
    }

    if (first != null && second != null && change === 'Improved') {
      return (
        (first.color === 'red' && ['yellow', 'green'].includes(second.color)) ||
        (first.color === 'yellow' && second.color === 'green')
      );
    }

    return true;
  };
