import type { PropsWithChildren } from 'react';

import { usePermissions } from '@/hooks';

type Props = PropsWithChildren<{
  privilege: string;
}>;

export function IfHasPrivilege({ privilege, children }: Props) {
  const { hasPrivilege } = usePermissions();

  return hasPrivilege(privilege) ? <>{children}</> : null;
}
