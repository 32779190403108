import { Radio, Table } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface Props {
  value?: Record<string, unknown>;
  onChange: (value: Record<string, unknown>) => void;
  data: {
    value: string;
    label: string;
  }[];
}

export function PrivilegeSetInput({ value, onChange, data }: Props) {
  const { t } = useTranslation();
  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Privilege</Table.Th>
          <Table.Th align="center">{t('common.action.no')}</Table.Th>
          <Table.Th align="center">{t('common.action.yes')}</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {data.map(item => (
          <Table.Tr key={item.value}>
            <Table.Td>{item.label}</Table.Td>
            <Table.Td align="center">
              <Radio
                onChange={() => {
                  onChange({ ...value, [item.value]: false });
                }}
                checked={value?.[item.value] === false}
              />
            </Table.Td>
            <Table.Td align="center">
              <Radio
                onChange={() => {
                  onChange({ ...value, [item.value]: true });
                }}
                checked={value?.[item.value] !== false}
              />
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
}
