import { Button, Card, NativeSelect, Stack, TextInput } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import dayjs from 'dayjs';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { SEX } from '@/constants';
import { useForm } from '@/hooks';
import type { Client } from '@/types';

interface Props {
  action: string;
  client: Client;
  fromSummary?: boolean;
}

export function PersonalInfoForm({ action, client, fromSummary }: Props) {
  const { t } = useTranslation();

  const { getPath, setPath, patch, errors } = useForm(client);

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    patch(action);
  };

  return (
    <form onSubmit={save}>
      <Card padding="xl">
        <Card.Section withBorder p="xl">
          <Stack gap="lg">
            <Stack gap="sm">
              <TextInput
                required
                label={t('client.column.first_name')}
                name="first_name"
                value={getPath('first_name', '')}
                onChange={e => setPath('first_name', e.target.value)}
                error={errors.first_name}
              />
              <TextInput
                required
                label={t('client.column.last_name')}
                name="last_name"
                value={getPath('last_name', '')}
                onChange={e => setPath('last_name', e.target.value)}
                error={errors.last_name}
              />
              <TextInput
                name="preferred_name"
                label={t('client.column.preferred_name')}
                value={getPath('preferred_name', '')}
                onChange={e => setPath('preferred_name', e.target.value)}
                error={errors.preferred_name}
              />
              <DateInput
                required
                label={t('client.column.date_of_birth')}
                name="date_of_birth"
                placeholder="YYYY-MM-DD"
                value={
                  getPath('date_of_birth')
                    ? dayjs(getPath('date_of_birth'), 'YYYY-MM-DD').toDate()
                    : null
                }
                defaultLevel="decade"
                onChange={value =>
                  setPath('date_of_birth', dayjs(value).format('YYYY-MM-DD'))
                }
                error={errors.date_of_birth}
              />
              <NativeSelect
                required
                label={t('client.column.sex')}
                name="sex"
                value={getPath('sex', '')}
                onChange={e => setPath('sex', e.target.value)}
                data={[
                  '',
                  ...SEX.map(sex => ({
                    value: sex,
                    label: t(`client.sex.${sex}`),
                  })),
                ]}
                error={errors.sex}
              />
              <TextInput
                label={t('client.column.phone')}
                required
                name="phone"
                value={getPath('phone', '')}
                onChange={e => setPath('phone', e.target.value)}
                error={errors.phone}
              />
            </Stack>
            <Button type="submit">
              {t(
                `common.action.${fromSummary != null ? 'submit' : 'continue'}`,
              )}
            </Button>
          </Stack>
        </Card.Section>
      </Card>
    </form>
  );
}
