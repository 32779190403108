import { Link } from '@inertiajs/react';
import { Anchor, Badge, Card, Menu } from '@mantine/core';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FaEllipsisV, FaPen, FaTimes, FaTrashAlt } from 'react-icons/fa';

import { DataGrid, Order, Page, Pagination, Toolbar } from '@/components';
import type {
  Assay,
  Client,
  CollectionSite,
  Kit,
  Project,
  Site,
} from '@/types';
import { formatDate } from '@/utils';

import { State } from './_State';

interface Props {
  assays: (Assay & {
    client?: Client;
    active_kit?: Kit;
    collection_site?: CollectionSite;
    project_timepoint: Project;
    site: Site;
  })[];
}

export default function Index({ assays }: Props) {
  const { t } = useTranslation();

  return (
    <Page title="Assays">
      <Card>
        <Card.Section>
          <Toolbar
            filters={
              <>
                <State />
                <Order
                  data={[
                    { value: 'assays.id', label: t('common.column.id') },
                    {
                      value: 'collection_sites.name',
                      label: t('collection_site.singular'),
                    },
                    {
                      value: 'clients.identifier',
                      label: t('client.column.identifier'),
                    },
                    {
                      value: 'barcodes.content',
                      label: t('assay.column.code'),
                    },
                    {
                      value: 'assays.collected_on',
                      label: t('assay.column.collected_on'),
                    },
                  ]}
                />
              </>
            }
          />
          <DataGrid
            data={assays}
            columns={[
              {
                header: t('common.column.id'),
                accessorFn: row => row.id,
                cell: ({ getValue }) => (
                  <Anchor
                    component={Link}
                    href={`/admin/assays/${getValue<number>()}`}
                  >
                    {getValue()}
                  </Anchor>
                ),
              },
              {
                header: t('client.column.identifier'),
                cell: ({ row }) => (
                  <Badge color="blue">{row.original.client?.identifier}</Badge>
                ),
              },
              {
                header: t('assay.column.code'),
                cell: ({ row }) => (
                  <Badge color="blue">{row.original.code}</Badge>
                ),
              },
              {
                header: t('kit.column.barcode_id'),
                accessorFn: row => row.active_kit?.barcode_id,
              },
              {
                header: t('collection_site.singular'),
                accessorFn: row => row.collection_site?.name,
              },
              {
                header: t('assay.column.collected_on'),
                accessorFn: row => formatDate(row.collected_on),
              },
              {
                header: t('assay.column.all_samples_received_at_date'),
                accessorFn: row => formatDate(row.all_samples_received_at_date),
              },
              {
                header: t('site.singular'),
                accessorFn: row => row.site?.description,
              },
              {
                header: t('project_timepoint.singular'),
                accessorFn: row => row.project_timepoint?.description,
              },
              {
                header: t('assay.column.product_name'),
                accessorFn: row => row.product_name,
              },
              {
                header: t('assay.column.fasting'),
                accessorFn: row => (row.fasting ? 'Yes' : 'No'),
              },
              {
                header: t('client.column.date_of_birth'),
                accessorFn: row => row.client?.date_of_birth,
              },
              {
                header: t('client.column.sex'),
                accessorFn: row => capitalize(row.client?.sex),
              },
              {
                header: t('assay.column.general_notes'),
                accessorFn: row => row.general_notes,
              },
              {
                header: t('assay.column.state'),
                accessorFn: row => t(`assay.state.${row.state}`),
              },
              {
                id: 'actions',
                cell: ({ row }) => (
                  <Menu>
                    <Menu.Target>
                      <span>
                        <FaEllipsisV />
                      </span>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        leftSection={<FaPen />}
                        component={Link}
                        href={`/admin/assays/${row.original.id}`}
                      >
                        {t('common.action.show')}
                      </Menu.Item>
                      {['analysis', 'collection'].includes(
                        row.original.state,
                      ) && (
                        <>
                          <Menu.Item
                            leftSection={<FaTimes />}
                            component={Link}
                            href={`/admin/assays/${row.original.id}/compromised`}
                            method="patch"
                            as="button"
                            onClick={e => {
                              if (
                                !confirm(
                                  t('assay.confirm.compromise', {
                                    id: row.original.id,
                                  }),
                                )
                              ) {
                                e.preventDefault();
                              }
                            }}
                          >
                            {t('assay.action.compromised')}
                          </Menu.Item>
                          <Menu.Item
                            leftSection={<FaTrashAlt />}
                            component={Link}
                            href={`/admin/assays/${row.original.id}/cancel`}
                            method="patch"
                            as="button"
                            onClick={e => {
                              if (
                                !confirm(
                                  t('assay.confirm.cancel', {
                                    id: row.original.id,
                                  }),
                                )
                              ) {
                                e.preventDefault();
                              }
                            }}
                          >
                            {t('common.action.cancel')}
                          </Menu.Item>
                        </>
                      )}
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
          <Pagination />
        </Card.Section>
      </Card>
    </Page>
  );
}
