import { Link } from '@inertiajs/react';
import { Button } from '@mantine/core';

import { Page } from '@/components';
import type { CollectionSite } from '@/types';

import { Details } from './_Details';

interface Props {
  collection_site: CollectionSite;
  requisition_forms: string;
}

export default function New(props: Props) {
  return (
    <Page
      title="Collection Site Details"
      actions={
        <Button
          component={Link}
          href={`/admin/collection_sites/${
            props.collection_site.id as number
          }/edit`}
        >
          Edit
        </Button>
      }
    >
      <Details
        collectionSite={props.collection_site}
        requisitionForms={props.requisition_forms}
      />
    </Page>
  );
}
