import { Link } from '@inertiajs/react';
import { Anchor, Button, Card, Menu } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaEllipsisV } from 'react-icons/fa';

import { DataGrid, Page, Pagination, Toolbar } from '@/components';
import type { Client, Site } from '@/types';
import { formatDate } from '@/utils';

interface Props {
  clients: (Client & {
    site: Site;
  })[];
}

export default function Index(props: Props) {
  const { t } = useTranslation();

  const searchCols = [
    'first_name',
    'last_name',
    'email',
    'site_description',
    'identifier',
  ];

  const downloadClientsCsv = (
    <Button
      component="a"
      href="/admin/clients/build_clients_csv"
      download="clients.csv"
    >
      {t('client.actions.download_csv')}
    </Button>
  );

  return (
    <Page
      title="Clients"
      actions={
        <Button component={Link} href="/admin/clients/new">
          Create Client
        </Button>
      }
    >
      <Card>
        <Card.Section>
          <Toolbar actions={downloadClientsCsv} searchCols={searchCols} />
          <DataGrid
            data={props.clients}
            columns={[
              {
                header: t('client.column.identifier'),
                accessorFn: row => (
                  <Anchor href={`/admin/clients/${row.identifier}`}>
                    {row.identifier}
                  </Anchor>
                ),
              },
              {
                header: t('client.column.first_name'),
                accessorFn: row => row.first_name,
              },
              {
                header: t('client.column.last_name'),
                accessorFn: row => row.last_name,
              },
              {
                header: t('user.column.email'),
                accessorFn: row => row.email,
              },
              {
                header: t('client.column.date_of_birth'),
                accessorFn: row => row.date_of_birth,
              },
              {
                header: t('site.singular'),
                accessorFn: row => row.site?.description,
              },
              {
                header: t('common.column.created_at'),
                accessorFn: row => formatDate(row.created_at),
              },
              {
                header: t('common.column.updated_at'),
                accessorFn: row => formatDate(row.updated_at),
              },
              {
                header: t('client.column.beta_features_enabled'),
                accessorFn: row =>
                  t(
                    `common.boolean.${
                      row.beta_features_enabled ? 'true' : 'false'
                    }`,
                  ),
              },
              {
                id: 'actions',
                cell: ({ row }) => (
                  <Menu>
                    <Menu.Target>
                      <span>
                        <FaEllipsisV />
                      </span>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        component={Link}
                        href={`/admin/clients/${row.original.identifier}`}
                      >
                        {t(`common.action.show`)}
                      </Menu.Item>
                      <Menu.Item
                        component={Link}
                        href={`/admin/clients/${row.original.identifier}/edit`}
                      >
                        {t('common.action.edit')}
                      </Menu.Item>
                      <Menu.Item
                        component={Link}
                        method="delete"
                        as="button"
                        href={`/admin/clients/${row.original.identifier}`}
                      >
                        {t('common.action.delete')}
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
          <Pagination />
        </Card.Section>
      </Card>
    </Page>
  );
}
