import { Box, Card, Grid, Group, Stack, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaCircle } from 'react-icons/fa';

import { Icon } from '@/components/Icon';
import type { Action } from '@/types';
import { getColor } from '@/utils';

interface Props {
  action: Action | null;
}

export function ActionPlanContent({ action }: Props) {
  const { t } = useTranslation();
  if (action) {
    return (
      <Card p="xl">
        <Grid gutter="xl">
          <Grid.Col span={{ base: 12, md: 7, lg: 6 }}>
            <Stack>
              <Title size="h2" tt="capitalize">
                {action.name}
              </Title>
              <Text>{action.description}</Text>
              {action.options &&
                (() => {
                  const options = JSON.parse(action.options);
                  return options.length > 0 ? (
                    <Box>
                      <Stack>
                        <Title size="h4" p="sm">
                          {t('action_plan.examples.header')}
                        </Title>
                        {options.map((option, index) => (
                          <Box key={index} p="sm">
                            <Group>
                              <Icon iconId={option.icon_id} />
                              <Text>{option.name}</Text>
                            </Group>
                            <Text c="dimmed">{option.note}</Text>
                          </Box>
                        ))}
                      </Stack>
                    </Box>
                  ) : null;
                })()}
            </Stack>
          </Grid.Col>

          <Grid.Col span={{ base: 12, md: 7, lg: 6 }}>
            {action.action && (
              <Stack>
                <Stack>
                  <Title size="h4">{t('action_plan.targets.header')}</Title>
                  <Text>{t('action_plan.targets.sub_text')}</Text>
                  {action.target_biomarkers.length > 0 ? (
                    <Stack gap="sm">
                      <Title size="h5">
                        {t('action_plan.biomarkers.header')}
                      </Title>
                      {action.target_biomarkers
                        .sort((a, b) => {
                          if (a.color === 'red' && b.color === 'yellow')
                            return -1;
                          if (a.color === 'yellow' && b.color === 'red')
                            return 1;
                          return 0;
                        })
                        .map((area, index) => (
                          <Group key={index}>
                            <FaCircle size={15} color={getColor(area.color)} />
                            <Text>{area.name}</Text>
                          </Group>
                        ))}
                    </Stack>
                  ) : null}
                </Stack>
                {action.target_health_areas.length > 0 ? (
                  <Stack gap="sm">
                    <Title size="h5">
                      {t('action_plan.health_areas.header')}
                    </Title>
                    {action.target_health_areas.map((area, index) => (
                      <Group key={index}>
                        <FaCircle size={15} color={getColor(area.color)} />
                        <Text>{area.name}</Text>
                      </Group>
                    ))}
                  </Stack>
                ) : null}
              </Stack>
            )}
          </Grid.Col>
        </Grid>
      </Card>
    );
  }
  return (
    <Text p="xl" ta="center">
      {t('action_plan.no_actions')}
    </Text>
  );
}
