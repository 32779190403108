import { Button, Card, Container, PasswordInput, Stack } from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Back } from '@/components';
import { useForm } from '@/hooks';
import {
  client_account_index_path,
  client_account_passwords_path,
} from '@/routes';

export default function Password() {
  const { t } = useTranslation();

  const { getPath, setPath, patch, errors } = useForm<{
    current_password: string;
    password: string;
    password_confirmation: string;
  }>();

  const submit = (event: SyntheticEvent) => {
    event.preventDefault();
    patch(client_account_passwords_path());
  };

  return (
    <Container size="xs">
      <Stack gap="xl">
        <Back label="Change password" to={client_account_index_path()} />
        <form onSubmit={submit}>
          <Card p="xl">
            <Stack gap="lg">
              <Stack gap="sm">
                <PasswordInput
                  label={t('account.column.current_password')}
                  name="current_password"
                  value={getPath('current_password', '')}
                  onChange={event =>
                    setPath('current_password', event.target.value)
                  }
                  error={errors.current_password}
                  required
                />
                <PasswordInput
                  label={t('account.column.password')}
                  name="password"
                  value={getPath('password', '')}
                  onChange={event => setPath('password', event.target.value)}
                  error={errors.password}
                  required
                />
                <PasswordInput
                  label={t('account.column.password_confirmation')}
                  name="password_confirmation"
                  value={getPath('password_confirmation', '')}
                  onChange={event =>
                    setPath('password_confirmation', event.target.value)
                  }
                  error={errors.password_confirmation}
                  required
                />
              </Stack>
              <Button type="submit">{t('common.action.save')}</Button>
            </Stack>
          </Card>
        </form>
      </Stack>
    </Container>
  );
}
