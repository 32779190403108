import { Link } from '@inertiajs/react';
import { Group } from '@mantine/core';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { usePermissions } from '@/hooks';

import { Base } from './_Base';
import { ClinicSettings } from './_ClinicSettings';
import { Help } from './_Help';
import { Logo } from './_Logo';
import { Sites } from './_Sites';
import { UserMenu } from './_UserMenu';

interface Props {
  title?: string;
  actions?: ReactNode;
  subHeader?: ReactNode;
  page: ReactNode;
}

export function Provider({ page, ...props }: Props) {
  const { t } = useTranslation();
  const { hasPrivilege } = usePermissions();

  return (
    <Base
      brand={
        <Link
          style={{ textDecoration: 'none', color: 'black' }}
          href="/provider"
        >
          <Logo />
        </Link>
      }
      links={[
        {
          label: t('patient.plural'),
          href: '/provider/clients',
        },
        ...(hasPrivilege('change_providers')
          ? [
              {
                label: t('staff.plural'),
                href: '/provider/providers',
              },
            ]
          : []),
      ]}
      menu={
        <Group gap="lg">
          <Sites />
          <ClinicSettings />
          <Help href="https://molecularyouproviders.zendesk.com/hc/en-ca" />
          <UserMenu root="/providers" />
        </Group>
      }
      {...props}
    >
      {page}
    </Base>
  );
}
