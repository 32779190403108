import { Link } from '@inertiajs/react';
import { Button, Card, Menu } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaEllipsisV } from 'react-icons/fa';

import { DataGrid, Page, Pagination, Toolbar } from '@/components';
import type { Vendor } from '@/types';
import { formatDate } from '@/utils';

interface Props {
  vendors: Vendor[];
}

function Index(props: Props) {
  const { t } = useTranslation();

  const searchCols = ['name', 'display_name'];

  return (
    <Page
      title={t('vendor.plural')}
      actions={
        <Button component={Link} href="/admin/vendors/new">
          {t('common.action.create')} {t('vendor.singular')}
        </Button>
      }
    >
      <Card>
        <Card.Section>
          <Toolbar searchCols={searchCols} />
          <DataGrid
            data={props.vendors}
            columns={[
              {
                header: t('common.column.id'),
                accessorFn: row => row.id,
              },
              {
                header: t('vendor.column.display_name'),
                accessorFn: row => row.display_name,
              },
              {
                header: t('vendor.column.name'),
                accessorFn: row => row.name,
              },
              {
                header: t('common.column.created_at'),
                accessorFn: row => formatDate(row.created_at),
              },
              {
                header: t('common.column.updated_at'),
                accessorFn: row => formatDate(row.updated_at),
              },
              {
                id: 'actions',
                cell: ({ row }) => (
                  <Menu>
                    <Menu.Target>
                      <span>
                        <FaEllipsisV />
                      </span>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        component={Link}
                        href={`/admin/vendors/${row.original.id}`}
                      >
                        {t(`common.action.show`)}
                      </Menu.Item>
                      <Menu.Item
                        component={Link}
                        href={`/admin/vendors/${row.original.id}/edit`}
                      >
                        {t(`common.action.edit`)}
                      </Menu.Item>
                      <Menu.Item
                        component={Link}
                        method="delete"
                        as="button"
                        href={`/admin/vendors/${row.original.id}`}
                      >
                        {t('common.action.delete')}
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
          <Pagination />
        </Card.Section>
      </Card>
    </Page>
  );
}

export default Index;
