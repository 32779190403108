import { Link } from '@inertiajs/react';
import { Button, Card, Menu } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaEllipsisV, FaPen, FaRegEye } from 'react-icons/fa';

import {
  BatchStateField,
  DataGrid,
  Page,
  Pagination,
  Toolbar,
} from '@/components';
import type { Batch, Vendor } from '@/types';
import { formatDate } from '@/utils';

interface Props {
  batches: (Batch & {
    vendor?: Vendor;
  })[];
}

export default function Index(props: Props) {
  const { t } = useTranslation();

  const searchCols = ['vendor_batch_number', 'name', 'state'];

  return (
    <Page
      title="Batches"
      actions={
        <Button component="a" href="/admin/batches/new">
          Create Batch
        </Button>
      }
    >
      <Card>
        <Card.Section>
          <Toolbar searchCols={searchCols} />
          <DataGrid
            data={props.batches}
            columns={[
              {
                header: t('batch.column.vendor_batch_number'),
                accessorFn: row => row.vendor_batch_number,
              },
              {
                header: t('vendor.singular'),
                accessorFn: row => row.vendor?.name,
              },
              {
                header: t('batch.column.name'),
                accessorFn: row => row.name,
              },
              {
                header: t('batch.column.state'),
                cell: ({ row }) => (
                  <BatchStateField value={row.original.state} />
                ),
              },
              {
                header: t('batch.column.received_by_vendor_at'),
                accessorFn: row => row.received_by_vendor_at,
              },
              {
                header: t('batch.column.vendor_sign_off_date'),
                accessorFn: row => formatDate(row.vendor_sign_off_date),
              },
              {
                id: 'actions',
                cell: ({ row }) => (
                  <Menu>
                    <Menu.Target>
                      <span>
                        <FaEllipsisV />
                      </span>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        leftSection={<FaPen />}
                        component="a"
                        href={`/admin/batches/${row.original.id}/edit`}
                      >
                        {t('common.action.edit')}
                      </Menu.Item>
                      <Menu.Item
                        leftSection={<FaCheck />}
                        component={Link}
                        href={`/admin/batches/${row.original.id}/review`}
                      >
                        {t('batch.action.review')}
                      </Menu.Item>
                      <Menu.Item
                        leftSection={<FaRegEye />}
                        component="a"
                        href={`/admin/batches/${row.original.id}`}
                      >
                        {t('common.action.show')}
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
          <Pagination />
        </Card.Section>
      </Card>
    </Page>
  );
}
