import { Page } from '@/components';
import type { ProductBundle, Profile } from '@/types';

import { Form } from './_Form';

interface Props {
  product_bundle: ProductBundle;
  profiles: Profile[];
  sample_statuses: string[];
}

export default function New(props: Props) {
  return (
    <Page title="Create Product Bundle">
      <Form
        productBundle={props.product_bundle}
        profiles={props.profiles}
        sampleStatuses={props.sample_statuses}
      />
    </Page>
  );
}
