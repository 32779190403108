import { Link } from '@inertiajs/react';
import { AppShell, Divider } from '@mantine/core';
import { FiDownload } from 'react-icons/fi';
import { MdLineAxis } from 'react-icons/md';
import {
  RiBarChartLine,
  RiDropLine,
  RiLogoutCircleRLine,
  RiUserLine,
} from 'react-icons/ri';

import {
  client_account_index_path,
  client_actions_path,
  client_reports_downloads_path,
  client_reports_root_path,
  client_tests_index_path,
  destroy_client_session_path,
} from '@/routes';

import classes from './_NavBar.module.css';

const navigationLinks = [
  {
    link: client_tests_index_path(),
    label: 'Take a test',
    icon: RiDropLine,
  },
  {
    link: client_reports_root_path(),
    label: 'Your results',
    icon: RiBarChartLine,
  },
  {
    link: client_actions_path(),
    label: 'Action plan',
    icon: MdLineAxis,
  },
  {
    link: client_reports_downloads_path(),
    label: 'Download',
    icon: FiDownload,
  },
];

const userMenuLinks = [
  {
    link: client_account_index_path(),
    label: 'Account',
    icon: RiUserLine,
  },
  {
    link: destroy_client_session_path(),
    label: 'Logout',
    icon: RiLogoutCircleRLine,
    method: 'delete',
  },
];

interface Props {
  url: string;
  setCollapsed: (collapsed: boolean) => void;
}

export function NavBar({ url, setCollapsed }: Props) {
  const processedUrls = navigationLinks.map(linkItem => {
    const isActive =
      linkItem.link === client_reports_root_path()
        ? url.startsWith(client_reports_root_path()) &&
          !url.startsWith(client_reports_downloads_path())
        : url === linkItem.link;
    return {
      ...linkItem,
      isActive,
    };
  });

  return (
    <AppShell.Navbar className={classes.navbar}>
      <div className={classes.links}>
        <div className={classes.navigation}>
          {processedUrls.map(item => (
            <Link
              className={classes.link}
              data-active={item.isActive || undefined}
              onClick={() => {
                setCollapsed(true);
              }}
              href={item.link}
              key={item.label}
            >
              <item.icon className={classes.linkIcon} />
              <span>{item.label}</span>
            </Link>
          ))}
        </div>
      </div>

      <div className={classes.userMenu}>
        <Divider my="md" />
        {userMenuLinks.map((item, index) => (
          <Link
            key={index}
            className={classes.link}
            data-active={url.includes(item.link) || undefined}
            onClick={() => {
              setCollapsed(true);
            }}
            href={item.link}
            method={item.method || undefined}
            as={item.method ? 'button' : 'a'}
          >
            <item.icon className={classes.linkIcon} />
            <span>{item.label}</span>
          </Link>
        ))}
      </div>
    </AppShell.Navbar>
  );
}
