import { useForm } from '@inertiajs/react';
import { Button, Card, Center, Group, Radio, Select } from '@mantine/core';
import {
  getExpandedRowModel,
  getGroupedRowModel,
  getSortedRowModel,
} from '@tanstack/table-core';
import axios from 'axios';
import { capitalize } from 'lodash';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DataGrid, Field } from '@/components';
import { selectRerunSampleCount, selectSetMeasureNRCount } from '@/selectors';
import type { Batch, QCFlag, Vendor } from '@/types';
import { formatBarcode, formatVendorRerunGuidance } from '@/utils';

interface Props {
  batch: Batch & {
    vendor?: Vendor;
    batch_qc_flags?: QCFlag[];
  };
}

export function QCFlags({ batch }: Props) {
  const { t } = useTranslation();

  const [data, setData] = useState(batch.batch_qc_flags ?? []);
  const form = useForm({ rerun: false });

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    if (
      confirm(
        form.data.rerun
          ? t('batch.message.rerun_batch', {
              name: batch.name,
              vendor: batch.vendor?.name,
            })
          : t('batch.message.submit_batch', {
              name: batch.name,
              vendor: batch.vendor?.name,
            }),
      )
    ) {
      form.patch(`/admin/batches/${batch.id}/submit`, {
        preserveScroll: page => page.props.errors != null,
      });
    }
  };

  const rerunSampleCount = selectRerunSampleCount(data);
  const setMeasureNRCount = selectSetMeasureNRCount(data);

  const filter = (
    qcReviewerRequest: string,
    barcode: string,
    biomarker: string,
  ): QCFlag[] => {
    switch (qcReviewerRequest) {
      case 're_run_sample':
        return data.filter(
          item =>
            barcode === item.barcode &&
            item.qc_reviewer_request !== 'set_measure_NR',
        );
      case 'set_measure_NR':
        return data.filter(item => biomarker === item.biomarker);
      case 'pending':
        return data.filter(item =>
          // Batch Level Flag
          barcode == null && biomarker == null
            ? // Return all Batch Level Flags
              item.barcode == null && item.biomarker == null
            : // Marker Level Flag
              barcode == null
              ? // Return all flags with similar biomarkers where qc_reviewer_request is not re_run_sample
                biomarker === item.biomarker &&
                item.qc_reviewer_request !== 're_run_sample'
              : // No Biomarker
                biomarker == null
                ? // Return all flags with similar barcode where qc_reviewer_request is not set_measure_NR
                  barcode === item.barcode &&
                  item.qc_reviewer_request !== 'set_measure_NR'
                : // Otherwise return all flags with similar biomarker where qc_reviewer_request is not re_run_sample
                  biomarker === item.biomarker &&
                  item.qc_reviewer_request !== 're_run_sample',
        );
      default:
        return [];
    }
  };

  const setQCReviewerRequest = async (
    qcFlag: QCFlag,
    value: string,
  ): Promise<void> => {
    const ids = filter(value, qcFlag.barcode, qcFlag.biomarker).map(
      item => item.id,
    );

    setData(data =>
      data.map(item =>
        ids.includes(item.id) ? { ...item, qc_reviewer_request: value } : item,
      ),
    );

    if (ids.length > 0) {
      await axios.patch(`/admin/batches/${batch.id}/qc_flags`, {
        qc_flags: ids.map(id => ({ id, qc_reviewer_request: value })),
      });
    }
  };

  return (
    <>
      <Card>
        <Card.Section p="md" withBorder>
          QC Summary
        </Card.Section>
        <Card.Section>
          <div>
            <Field label="Rerun Sample Count">{rerunSampleCount}</Field>
            <Field label="Set Measure NR Count">{setMeasureNRCount}</Field>
          </div>
        </Card.Section>
      </Card>
      <Card>
        <Card.Section p="md" withBorder>
          {t('qc_flag.plural')}
        </Card.Section>
        <Card.Section withBorder>
          <DataGrid
            data={data}
            columns={[
              {
                id: 'barcode',
                header: t('qc_flag.column.barcode'),
                accessorFn: row => formatBarcode(row.barcode, row.biomarker),
              },
              {
                header: t('qc_flag.column.qc_reviewer_request'),
                cell: ({ row }) => (
                  <Select
                    required
                    value={row.original.qc_reviewer_request}
                    disabled={batch?.state !== 'qc_review_needed'}
                    onChange={value => {
                      void (async () => {
                        await setQCReviewerRequest(row.original, value ?? '');
                      })();
                    }}
                    data={[
                      {
                        label: 'No Action',
                        value: 'pending',
                      },
                      {
                        label: 'Set Measure as NR',
                        value: 'set_measure_NR',
                        disabled: row.original.biomarker == null,
                      },
                      {
                        label: 'Re-run Sample',
                        value: 're_run_sample',
                        disabled:
                          (row.original.biomarker == null &&
                            row.original.barcode == null) ||
                          row.original.barcode == null ||
                          row.original.biomarker != null,
                      },
                      {
                        label: 'Re-run Batch',
                        value: 're_run_batch',
                        disabled: true,
                      },
                    ]}
                  />
                ),
              },
              {
                header: t('qc_flag.column.biomarker'),
                accessorFn: row => row.biomarker,
              },
              {
                header: t('qc_flag.column.issue'),
                accessorFn: row => row.issue,
              },
              {
                header: t('qc_flag.column.severity'),
                accessorFn: row => capitalize(row.severity),
              },
              {
                header: t('qc_flag.column.no_rerun_advised'),
                id: 'no_rerun_advised',
                aggregationFn: formatVendorRerunGuidance,
                aggregatedCell: ({ getValue }) => (
                  <Center>
                    <span>{getValue()}</span>
                  </Center>
                ),
                cell: ({ row }) => (
                  <Center>
                    <Radio
                      defaultChecked={
                        row.original.vendor_rerun_guidance ===
                        'no_rerun_advised'
                      }
                      disabled
                    />
                  </Center>
                ),
              },
              {
                header: t('qc_flag.column.rerun_advised'),
                id: 'rerun_advised',
                aggregationFn: formatVendorRerunGuidance,
                aggregatedCell: ({ getValue }) => (
                  <Center>
                    <span>{getValue()}</span>
                  </Center>
                ),
                cell: ({ row }) => (
                  <Center>
                    <Radio
                      defaultChecked={
                        row.original.vendor_rerun_guidance === 'rerun_advised'
                      }
                      disabled
                    />
                  </Center>
                ),
              },
            ]}
            getGroupedRowModel={getGroupedRowModel()}
            getExpandedRowModel={getExpandedRowModel()}
            getSortedRowModel={getSortedRowModel()}
            enableSorting={true}
            autoResetExpanded={false}
            initialState={{
              grouping: ['barcode'],
              sorting: [
                {
                  id: 'barcode',
                  desc: false,
                },
              ],
              expanded: true,
            }}
          />
        </Card.Section>
        <Card.Section>
          <form onSubmit={save}>
            <Group justify="end" p="md">
              {batch.batch_qc_flags != null &&
                batch.batch_qc_flags.length > 0 && (
                  <Button
                    color="red"
                    onClick={() => {
                      form.setData('rerun', true);
                    }}
                    disabled={batch.state !== 'qc_review_needed'}
                    type="submit"
                  >
                    {t('batch.action.rerun_batch')}
                  </Button>
                )}
              <Button
                onClick={() => {
                  form.setData('rerun', false);
                }}
                disabled={batch.state !== 'qc_review_needed'}
                type="submit"
              >
                {t('batch.action.submit_batch')}
              </Button>
            </Group>
          </form>
        </Card.Section>
      </Card>
    </>
  );
}
