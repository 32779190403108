import type { PageProps } from '@inertiajs/core';
import { usePage } from '@inertiajs/react';

interface Props extends PageProps {
  amplitude_key?: string;
}

export function useAmplitudeKey(): string | null {
  const page = usePage<Props>();

  if (page.props.amplitude_key !== undefined) {
    return page.props.amplitude_key;
  }

  return null;
}
