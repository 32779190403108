import type { PageProps } from '@inertiajs/core';
import { usePage } from '@inertiajs/react';

type PrivilegeSet = Record<string, boolean>;

interface Props extends PageProps {
  current_user?: {
    role?: string;
    privilege_set?: PrivilegeSet;
  };
}

interface Permissions {
  hasRole: (role: string) => boolean;
  hasPrivilege: (privilege: string) => boolean;
  role: string | null;
  privilegeSet: PrivilegeSet;
}

export const usePermissions = (): Permissions => {
  const page = usePage<Props>();

  const hasRole = (role: string): boolean =>
    page.props.current_user?.role != null &&
    page.props.current_user.role === role;

  const hasPrivilege = (privilege: string): boolean =>
    !(page.props.current_user?.privilege_set == null) &&
    page.props.current_user.privilege_set[privilege];

  return {
    hasRole,
    hasPrivilege,
    role: page.props.current_user?.role ?? null,
    privilegeSet: page.props.current_user?.privilege_set ?? {},
  };
};
