import {
  Box,
  Button,
  Select,
  Table,
  TextInput,
  UnstyledButton,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaTrashAlt } from 'react-icons/fa';

import type { ProductSampleTemplate, Profile } from '@/types';

interface Props {
  profiles: Profile[];
  sampleStatuses: string[];
  errors: Record<string, string>;
  value: ProductSampleTemplate[];
  onChange: (value: ProductSampleTemplate[]) => void;
}

export function ProductSampleTemplates({
  profiles,
  sampleStatuses,
  errors,
  value = [],
  onChange,
}: Props) {
  const { t } = useTranslation();

  const add = () => {
    onChange([...value, {}]);
  };

  const update = (idx: number, key: string, nextValue) => {
    onChange(value.map((v, i) => (i === idx ? { ...v, [key]: nextValue } : v)));
  };

  // Autofill fields based on selected profile
  const updateProfile = (idx: number, profileId: string) => {
    const profile = profiles.filter(
      profile => profile.id.toString() === profileId,
    )[0];

    onChange(
      value.map((v, i) =>
        i === idx
          ? {
              ...v,
              profile_id: profileId,
              sample_type: profile.sample_type,
            }
          : v,
      ),
    );
  };

  const remove = (idx: number) => {
    onChange(value.map((v, i) => (i === idx ? { ...v, _destroy: true } : v)));
  };

  return (
    <div>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Profile</Table.Th>
            <Table.Th>
              {t('product_sample_template.column.sample_type')}
            </Table.Th>
            <Table.Th>
              {t('product_sample_template.column.sample_status')}
            </Table.Th>
            <Table.Th>{t('product_sample_template.column.id_prefix')}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {value
            .filter(template => template._destroy == null)
            .map((item, idx) => (
              <Table.Tr key={idx}>
                <Table.Td valign="top">
                  <Select
                    value={item.profile_id}
                    name={`profile_${idx}`}
                    error={errors?.[
                      `product_sample_templates[${idx}].profile`
                    ]?.join(' and ')}
                    onChange={value => {
                      updateProfile(idx, value as string);
                    }}
                    data={profiles.map(profile => ({
                      label: profile.name,
                      value: profile.id.toString(),
                    }))}
                  />
                </Table.Td>
                <Table.Td valign="top">
                  <Select
                    value={item.sample_type}
                    name={`sample_type_${idx}`}
                    error={errors?.[
                      `product_sample_templates[${idx}].sample_type`
                    ]?.join(' and ')}
                    onChange={value => {
                      update(idx, 'sample_type', value);
                    }}
                    data={['Serum', 'Plasma', 'Urine', 'Fecal', 'PAXgene']}
                  />
                </Table.Td>
                <Table.Td valign="top">
                  <Select
                    value={item.sample_status}
                    name={`sample_status_${idx}`}
                    error={errors?.[
                      `product_sample_templates[${idx}].sample_status`
                    ]?.join(' and ')}
                    onChange={value => {
                      update(idx, 'sample_status', value);
                    }}
                    data={sampleStatuses}
                  />
                </Table.Td>
                <Table.Td valign="top">
                  <TextInput
                    value={item.id_prefix ?? ''}
                    name={`id_prefix_${idx}`}
                    error={errors?.[
                      `product_sample_templates[${idx}].id_prefix`
                    ]?.join(' and ')}
                    onChange={e => {
                      update(idx, 'id_prefix', e.target.value);
                    }}
                  />
                </Table.Td>
                {value.length > 1 && (
                  <Table.Td valign="top">
                    <UnstyledButton
                      mt="11"
                      type="button"
                      onClick={() => {
                        remove(idx);
                      }}
                    >
                      <FaTrashAlt />
                    </UnstyledButton>
                  </Table.Td>
                )}
              </Table.Tr>
            ))}
        </Table.Tbody>
      </Table>
      <Box p="md">
        <Button
          type="button"
          onClick={() => {
            add();
          }}
        >
          {t('common.action.add')}
        </Button>
      </Box>
    </div>
  );
}
