import { Grid, Stack, Text } from '@mantine/core';
import { TimeInput } from '@mantine/dates';

interface Item {
  day: string;
  open: string;
  close: string;
}

interface Props {
  value: Item[];
  onChange: (value: Item[]) => void;
  errors: Record<string, string>;
}

export function HoursOfOperation({ value, onChange, errors }: Props) {
  const update = (day: string, key: string, input: string): Item[] =>
    value.map(item => (item.day === day ? { ...item, [key]: input } : item));

  return (
    <Stack>
      {value.map(item => (
        <Grid key={item.day}>
          <Grid.Col span={2}>
            <Text>{item.day}</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <TimeInput
              value={item.open}
              onChange={event => {
                onChange(update(item.day, 'open', event.currentTarget.value));
              }}
              error={errors.opening_hours}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TimeInput
              value={item.close}
              onChange={event => {
                onChange(update(item.day, 'close', event.currentTarget.value));
              }}
              error={errors.opening_hours}
            />
          </Grid.Col>
        </Grid>
      ))}
    </Stack>
  );
}
