import { Link } from '@inertiajs/react';
import { Button } from '@mantine/core';

import { Page } from '@/components';
import type { CollectionSiteGroup } from '@/types';

import { Details } from './_Details';

interface Props {
  collection_site_group: CollectionSiteGroup;
}

export default function New(props: Props) {
  return (
    <Page
      title="Collection Site Group Details"
      actions={
        <Button
          component={Link}
          href={`/admin/collection_site_groups/${
            props.collection_site_group.id as number
          }/edit`}
        >
          Edit
        </Button>
      }
    >
      <Details collectionSiteGroup={props.collection_site_group} />
    </Page>
  );
}
