import { Link } from '@inertiajs/react';
import { Anchor } from '@mantine/core';

import { useQueryParams } from '@/hooks';

interface Props {
  label: string;
  page: number | null;
}

export function PaginationLink({ label, page }: Props) {
  const queryParams = useQueryParams();

  if (page !== null) {
    queryParams.set('page', page.toString());
  }

  return (
    <Anchor
      c={page == null ? 'gray' : 'blue'}
      component={Link}
      href={`?${queryParams.toString()}`}
    >
      {label}
    </Anchor>
  );
}
