import { Card, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';
import type { Address, Kit, KitBatch, Site } from '@/types';
import { formatDate } from '@/utils';

import { KitsDisplay } from './_KitsDisplay';

interface Props {
  kitBatch: KitBatch & {
    site: Site & {
      addresses: Address[];
    };
    kits?: Kit[];
  };
}

export function Details({ kitBatch }: Props) {
  const { t } = useTranslation();
  const ADDRESS_TYPE = {
    SHIPPING: 'shipping',
  };
  return (
    <Stack gap="xl">
      <Card>
        <Card.Section>
          <Field label={t('kit_batch.column.name')}>{kitBatch.name}</Field>
          <Field label={t('kit_batch.column.date_assembled')}>
            {formatDate(kitBatch.date_assembled)}
          </Field>
          <Field label={t('kit_batch.column.expiry_date')}>
            {formatDate(kitBatch.expiry_date)}
          </Field>
          <Field label={t('site.column.shipping_address')}>
            <Text>{kitBatch.site?.description}</Text>
            <Text>
              {
                kitBatch.site?.addresses?.find(
                  address => address.address_type === ADDRESS_TYPE.SHIPPING,
                )?.full_address
              }
            </Text>
          </Field>
        </Card.Section>
      </Card>
      <KitsDisplay value={kitBatch.kits ?? []} />
    </Stack>
  );
}
