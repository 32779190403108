import {
  Box,
  Button,
  Card,
  Group,
  Select,
  Table,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';

import type {
  CollectionSite,
  CollectionSiteCollectionSiteGroup,
} from '@/types';

interface Props {
  value: CollectionSiteCollectionSiteGroup[];
  onChange: (value: CollectionSiteCollectionSiteGroup[]) => void;
  errors: Record<string, string>;
  collectionSites: CollectionSite[];
}

export function CollectionSites({
  value = [],
  onChange,
  errors,
  collectionSites,
}: Props) {
  const { t } = useTranslation();

  const add = () => {
    onChange([...value, {}]);
  };

  const remove = (idx: number) => {
    onChange(value.map((v, i) => (i === idx ? { ...v, _destroy: true } : v)));
  };

  const update = (idx: number, nextValue) => {
    onChange(
      value.map((v, i) =>
        i === idx ? { ...v, collection_site_id: nextValue } : v,
      ),
    );
  };

  return (
    <Card>
      <Card.Section>
        <Group p="md">
          <Text>{t('collection_site.plural')}</Text>
          <Box p="md">
            <Button
              size="xs"
              px="xs"
              onClick={() => {
                add();
              }}
            >
              <FaPlus />
            </Button>
          </Box>
        </Group>
      </Card.Section>
      <Card.Section>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{t('collection_site.column.name')}</Table.Th>
              <Table.Th>Remove</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {value
              .filter(group => group._destroy == null)
              .map((item, idx) => (
                <Table.Tr key={idx}>
                  <Table.Td valign="top">
                    <Select
                      required
                      value={String(item.collection_site_id)}
                      name={`collection_site_${idx}`}
                      error={errors?.[`collection_site_[${idx}]`]?.join(
                        ' and ',
                      )}
                      onChange={value => {
                        update(idx, value as string);
                      }}
                      data={collectionSites.map(collectionSite => ({
                        label: collectionSite.name,
                        value: collectionSite.id.toString(),
                      }))}
                    />
                  </Table.Td>
                  <Table.Td valign="top">
                    <UnstyledButton
                      mt="11"
                      type="button"
                      onClick={() => {
                        remove(idx);
                      }}
                    >
                      <FaTrashAlt />
                    </UnstyledButton>
                  </Table.Td>
                </Table.Tr>
              ))}
          </Table.Tbody>
        </Table>
      </Card.Section>
    </Card>
  );
}
