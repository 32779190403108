import { Link } from '@inertiajs/react';
import { Button } from '@mantine/core';

import { Page } from '@/components';
import type { Company } from '@/types';

import { Details } from './_Details';
import { Sites } from './_Sites';

interface Props {
  company: Company;
}

export default function Show({ company }: Props) {
  return (
    <Page
      title="Company Details"
      actions={
        <Button
          component={Link}
          href={`/admin/companies/${company.id as number}/edit`}
        >
          Edit Company
        </Button>
      }
    >
      <Details company={company} />
      <Sites company={company} />
    </Page>
  );
}
