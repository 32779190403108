import { Container, Stack } from '@mantine/core';

import { Back } from '@/components';
import {
  client_account_index_path,
  client_account_update_health_profile_path,
} from '@/routes';
import type { Questionnaire, QuestionnaireOptions } from '@/types';

import { HealthProfileForm } from '../_HealthProfileForm';

interface Props {
  health_profile: Questionnaire;
  response_options: QuestionnaireOptions;
}

export default function HealthProfile({
  health_profile: healthProfile,
  response_options: responseOptions,
}: Props) {
  return (
    <Container size="xs">
      <Stack gap="xl">
        <Back label="Health profile" to={client_account_index_path()} />
        <HealthProfileForm
          action={client_account_update_health_profile_path()}
          healthProfile={healthProfile}
          responseOptions={responseOptions}
        />
      </Stack>
    </Container>
  );
}
