import { Link } from '@inertiajs/react';
import { Button } from '@mantine/core';

import { Page } from '@/components';
import type { Profile } from '@/types';

import { Details } from './_Details';

interface Props {
  profile: Profile;
}

export default function New({ profile }: Props) {
  return (
    <Page
      title="Profile Details"
      actions={
        <Button component={Link} href={`/admin/profiles/${profile.id}/edit`}>
          Edit
        </Button>
      }
    >
      <Details profile={profile} />
    </Page>
  );
}
