import { useEffect, useState } from 'react';

export const useHash = (initialState: string): string => {
  const [hash, setHash] = useState(
    window.location.hash.replace('#', '') !== ''
      ? window.location.hash.replace('#', '')
      : initialState,
  );

  useEffect(() => {
    const handleMessage = () => {
      setHash(
        window.location.hash.replace('#', '') !== ''
          ? window.location.hash.replace('#', '')
          : initialState,
      );
    };

    window.addEventListener('hashchange', handleMessage);

    return () => {
      window.removeEventListener('hashchange', handleMessage);
    };
  }, []);

  return hash;
};
