import { Box, Card, Grid, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getHealthAreaHistory, getSampleConcentrations } from '@/api/provider';
import { HealthAreaHistory } from '@/charts';
import { FormatText } from '@/components';
import { useUser } from '@/hooks';
import type { HealthArea, Provider } from '@/types';
import { getBiomarkerOrder } from '@/utils';

import { SampleConcentrationCard } from '../_SampleConcentrationCard';

interface Props {
  clientId: number;
  reportId: number;
  healthArea: HealthArea;
}

export function Content({ clientId, reportId, healthArea }: Props) {
  const { t } = useTranslation();

  const provider = useUser<Provider>();

  const sampleConcentrations = useQuery(
    ['SampleConcentrations', reportId, healthArea.myco_id],
    async () =>
      await getSampleConcentrations(
        { reportId, mycoId: healthArea.myco_id },
        {
          email: provider.email,
          apiKey: provider.api_key,
        },
      ),
    {
      initialData: () => [],
    },
  );

  const healthAreaHistory = useQuery(
    ['HealthArea', reportId, healthArea.myco_id],
    async () =>
      await getHealthAreaHistory(
        { clientId, mycoId: healthArea.myco_id },
        {
          email: provider.email,
          apiKey: provider.api_key,
        },
      ),
    {
      initialData: () => [],
    },
  );

  const data = sampleConcentrations.data?.toSorted(
    (a, b) =>
      getBiomarkerOrder(a.color, a.indicated) -
      getBiomarkerOrder(b.color, b.indicated),
  );

  if (sampleConcentrations.isFetching || healthAreaHistory.isFetching) {
    return (
      <Text ta="center" p="xl">
        {t('common.loading')}
      </Text>
    );
  }

  if (sampleConcentrations.isSuccess) {
    return (
      <Box>
        <Text mb="lg" size="28px" fw={700}>
          {healthArea.name}
        </Text>
        <Card p="xl" mb="xl" style={{ overflow: 'visible' }}>
          <Grid gutter="xl">
            <Grid.Col span={{ base: 12, md: 8 }}>
              <Box>
                <Text mb="md" size="22px" fw={500}>
                  {t('report.overview')}
                </Text>
                <Text>
                  <FormatText character="↵" string={healthArea.description} />
                </Text>
              </Box>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4 }}>
              <Box h={200}>
                <HealthAreaHistory
                  data={healthAreaHistory.data?.toReversed()}
                />
              </Box>
            </Grid.Col>
          </Grid>
        </Card>
        <Text mb="lg" size="16px" fw={700}>
          {healthArea.name} {t('biomarker.plural').toLocaleLowerCase()} (
          {data?.length})
        </Text>
        <Stack gap="xs">
          {data?.map((biomarker, i) => (
            <SampleConcentrationCard
              key={biomarker.id}
              defaultIsOpen={i === 0}
              showWarning={true}
              sampleConcentration={biomarker}
            />
          ))}
        </Stack>
      </Box>
    );
  }

  return null;
}
