import { ColorSwatch, Group, Text } from '@mantine/core';

import type { Color } from '@/types';

const getColor = (color: Color): string => {
  switch (color) {
    case 'green':
      return '#3FB6DC';
    case 'yellow':
      return '#FFC800';
    case 'red':
      return '#CF3616';
    default:
      return 'black';
  }
};

const getLabel = (color: Color): string => {
  switch (color) {
    case 'green':
      return 'In-range';
    case 'yellow':
      return 'Borderline';
    case 'red':
      return 'Out of range';
  }
};

export function SampleConcentrationLegend() {
  return (
    <Group gap="xs">
      {['green', 'yellow', 'red'].map((color, i) => (
        <Group key={i} gap="xs">
          <ColorSwatch size={8} color={getColor(color)} withShadow={false} />
          <Text size="sm">{getLabel(color)}</Text>
        </Group>
      ))}
    </Group>
  );
}
