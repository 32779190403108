import { Box, Card, Container, Stack, Text } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import type { Libraries } from '@react-google-maps/api';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getCollectionSites } from '@/api/client';
import { Back, PlacesAutocomplete } from '@/components';
import { MAP_DEFAULT_CENTER } from '@/constants';
import { client_tests_register_kit_path } from '@/routes';

import { CollectionSiteRow } from './_CollectionSiteRow';

interface CollectionSite {
  api_key: string;
  latitude: number;
  longitude: number;
  name: string;
  id: string;
}

const libraries: Libraries = ['places'];

export default function CollectionSites(collectionSite: CollectionSite) {
  const { t } = useTranslation();

  const [center, setCenter] = useState(MAP_DEFAULT_CENTER);

  const { isLoaded } = useJsApiLoader({
    id: 'google-maps-script',
    googleMapsApiKey: collectionSite.api_key,
    libraries,
  });

  const [mapref, setMapRef] = useState<google.maps.Map | null>(null);
  const [userLocation, setUserLocation] = useState<Coordinates | null>(null);
  const handleOnLoad = (map: google.maps.Map) => {
    setMapRef(map);
  };

  const handleCenterChanged = useDebouncedCallback(async () => {
    if (mapref != null) {
      const newCenter = mapref.getCenter();
      if (
        newCenter != null &&
        newCenter.lat() !== center.lat &&
        newCenter.lng() !== center.lng
      ) {
        setCenter({
          lat: newCenter.lat(),
          lng: newCenter.lng(),
        });
      }
    }
  }, 500);

  useEffect(() => {
    const getUserLocation = () => {
      new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      })
        .then(position => {
          const { latitude, longitude } = position.coords;
          setUserLocation(position.coords);
          setCenter({ lat: latitude, lng: longitude });
        })
        .catch(error => {
          console.error('Error getting user location:', error);
        });
    };
    getUserLocation();
  }, []);

  const { data } = useQuery(
    ['CollectionSites', center.lat, center.lng],
    async () => {
      return await getCollectionSites(center);
    },
    {
      initialData: () => [],
    },
  );

  return (
    <Container size="xs">
      <Stack gap="xl">
        <Back
          label={t('onboarding.collection_site.choose_collection_site')}
          to={client_tests_register_kit_path()}
        />
        <Card p="xl">
          <Stack>
            {isLoaded && (
              <Stack gap="xs">
                <PlacesAutocomplete onChange={setCenter} />
                <GoogleMap
                  onLoad={handleOnLoad}
                  onCenterChanged={handleCenterChanged}
                  mapContainerStyle={{
                    width: '100%',
                    height: '400px',
                  }}
                  center={center}
                  zoom={10}
                >
                  {userLocation != null && (
                    <Marker
                      position={{
                        lat: userLocation.latitude,
                        lng: userLocation.longitude,
                      }}
                      icon={{
                        url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
                      }}
                    />
                  )}

                  {data?.map(site => (
                    <Marker
                      key={site.id}
                      position={{
                        lat: Number(site.latitude),
                        lng: Number(site.longitude),
                      }}
                      icon={{
                        url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                        scaledSize: new window.google.maps.Size(30, 30),
                      }}
                    />
                  ))}
                </GoogleMap>
              </Stack>
            )}
            {data == null ||
              (data.length === 0 && (
                <Box my="md">
                  <Text fw={700}>
                    {t(
                      'onboarding.collection_site.collection_site_not_found.title',
                    )}
                  </Text>
                  <Text c="light-gray">
                    {t(
                      'onboarding.collection_site.collection_site_not_found.description',
                    )}
                  </Text>
                </Box>
              ))}
            {data != null && data.length > 0 && (
              <Stack>
                {data.map((collectionSite, i) => (
                  <CollectionSiteRow
                    key={i}
                    collectionSite={collectionSite}
                    onClick={() => {
                      setCenter({
                        lat: Number(collectionSite.latitude),
                        lng: Number(collectionSite.longitude),
                      });
                    }}
                  />
                ))}
              </Stack>
            )}
          </Stack>
        </Card>
      </Stack>
    </Container>
  );
}
