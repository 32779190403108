import { usePage } from '@inertiajs/react';

type StageOfChange = Record<
  string,
  {
    label: string;
    sub_label: string;
  }
>;

interface PageProps {
  response_options: {
    stage_of_change: StageOfChange;
  };
}

export function useStageOfChangeOptions(): { label: string; value: string }[] {
  const {
    response_options: { stage_of_change: stages },
  } = usePage<PageProps>().props;

  const optionsForSelect = [];
  for (const [stageOfChange, { label }] of Object.entries(stages)) {
    optionsForSelect.push({
      label,
      value: stageOfChange,
    });
  }

  return optionsForSelect;
}
