import { Page } from '@/components';
import type { Batch, Vendor } from '@/types';

import { Form } from './_Form';

interface Props {
  batch: Batch;
  vendors: Vendor[];
}

export default function Edit(props: Props) {
  return (
    <Page title="Update Batch">
      <Form batch={props.batch} vendors={props.vendors} />
    </Page>
  );
}
