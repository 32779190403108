import { Combobox, TextInput, useCombobox } from '@mantine/core';
import { useState } from 'react';

interface Props {
  placeholder?: string;
  onOptionSubmit: (optionValue: string) => void;
  data: {
    label: string;
    value: string;
  }[];
}

export function SampleAutocomplete({
  placeholder,
  onOptionSubmit,
  data,
}: Props) {
  const combobox = useCombobox();

  const [value, setValue] = useState('');

  const shouldFilterOptions = !data.some(item => item.label === value);

  const filteredOptions = shouldFilterOptions
    ? data.filter(item =>
        item.label.toLowerCase().includes(value.toLowerCase().trim()),
      )
    : data;

  const options = filteredOptions.map(item => (
    <Combobox.Option value={item.value} key={item.value}>
      {item.label}
    </Combobox.Option>
  ));

  return (
    <Combobox
      onOptionSubmit={optionValue => {
        setValue('');
        onOptionSubmit(optionValue);
        combobox.closeDropdown();
      }}
      store={combobox}
      position="bottom"
    >
      <Combobox.Target>
        <TextInput
          placeholder={placeholder}
          value={value}
          onChange={event => {
            setValue(event.currentTarget.value);
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
          }}
          onClick={() => {
            combobox.openDropdown();
          }}
          onFocus={() => {
            combobox.openDropdown();
          }}
          onBlur={() => {
            combobox.closeDropdown();
          }}
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {options.length === 0 ? (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          ) : (
            options
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
