import { Link } from '@inertiajs/react';
import { Button } from '@mantine/core';

import { Page } from '@/components';
import type { Assay, Client } from '@/types';

import { Assays } from './_Assays';
import { Details } from './_Details';

interface Props {
  assays: Assay[];
  client: Client;
}

export default function New(props: Props) {
  return (
    <Page
      title="Client details"
      actions={
        <Button
          component={Link}
          href={`/admin/clients/${props.client.identifier as string}/edit`}
        >
          Edit
        </Button>
      }
    >
      <Details client={props.client} assay={props.assays} />
      {props.assays != null && props.assays.length > 0 && (
        <Assays value={props.assays} />
      )}
    </Page>
  );
}
