type Data = Record<string, unknown>;

const indexAttributes = <T extends Data[]>(data: T): T =>
  data.map((item, index) => ({ ...item, _index: index }) as unknown) as T;

export const nestedAttributes =
  <T extends Data>(attributes: string[]) =>
  (data: T): T => {
    const keys = Object.keys(data);
    let finalData: T = {} as unknown as T;

    finalData = keys.reduce<T>((acc, key) => {
      if (attributes.includes(key)) {
        if (Array.isArray(data[key])) {
          return {
            ...acc,
            [`${key}_attributes`]: indexAttributes(data[key] as Data[]),
          };
        } else if (typeof data[key] === 'object' && data[key] !== null) {
          return {
            ...acc,
            [`${key}_attributes`]: { ...(data[key] as Data) },
          };
        }
      }
      return {
        ...acc,
        [key]: data[key],
      };
    }, finalData);
    return finalData;
  };
