import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Group,
  Image,
  rem,
  ScrollArea,
  Space,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import congratulations from '@/assets/images/congratulations.png';
import { useHash } from '@/hooks';
import type { Action, ActionType, Effect } from '@/types';

import { ActionPlanContent } from './_Content';
import { NavGroup } from './_NavGroup';

interface Props {
  actions: Record<ActionType, (Action & { effects: Effect[] })[]>;
  action_plan: {
    recommendations: {
      diet: Action[];
      supplement: Action[];
      lifestyle: Action[];
    };
  };
}

export default function Index({ action_plan }: Props) {
  const { t } = useTranslation();

  const hash = useHash('diet') as ActionType;

  const options: (keyof typeof action_plan.recommendations)[] = [
    'diet',
    'supplement',
    'lifestyle',
  ];
  const [selectedOption, setSelectedOption] =
    useState<keyof typeof action_plan.recommendations>('diet');
  const [selectedActionDetail, setSelectedActionDetail] =
    useState<Action | null>(null);
  useEffect(() => {
    const storedOption = localStorage.getItem('selectedOption') as
      | keyof typeof action_plan.recommendations
      | null;
    const optionFromHash = options.includes(hash) ? hash : null;
    const initialOption = optionFromHash || storedOption || 'diet';

    setSelectedOption(initialOption);
    handleOptionChange(initialOption);
  }, []);

  const handleOptionChange = (
    option: keyof typeof action_plan.recommendations,
  ) => {
    setSelectedOption(option);
    if (action_plan && action_plan.recommendations) {
      const recommendations = action_plan.recommendations[option];
      if (recommendations && recommendations.length > 0) {
        setSelectedActionDetail(recommendations[0]);
      } else {
        setSelectedActionDetail(null);
      }
    }
  };

  useEffect(() => {
    if (action_plan && action_plan.recommendations) {
      const recommendations = action_plan.recommendations;
      for (const key of Object.keys(
        recommendations,
      ).sort() as (keyof typeof recommendations)[]) {
        if (recommendations[key] && recommendations[key].length > 0) {
          setSelectedActionDetail(recommendations[key][0]);
          break;
        }
      }
    }
  }, [action_plan]);

  if (action_plan) {
    return (
      <Container size="lg">
        <Stack gap="xl">
          <Stack>
            <Title size="h1">{t('action_plan.recommendations.header')}</Title>
            <Text c="dimmed">{t('action_plan.recommendations.sub_text')}</Text>
            <Space h="sm" />

            <Group gap="xs" wrap="nowrap">
              {options.map(row => (
                <Button<'a'>
                  key={row}
                  size="sm"
                  variant={row === hash ? 'filled' : 'subtle'}
                  color={row === hash ? 'blue' : 'dimmed'}
                  component="a"
                  href={`#${row}`}
                  onClick={() => handleOptionChange(row)}
                >
                  {t(`action_plan.type.${row}`)}
                </Button>
              ))}
            </Group>
          </Stack>
          <Divider size="md" />
          {action_plan &&
          action_plan.recommendations[selectedOption] &&
          action_plan.recommendations[selectedOption].length > 0 ? (
            <Grid gutter={{ base: 'xs', sm: 'md' }}>
              <Grid.Col
                p={0}
                mb="-2rem"
                span={{ base: 12, sm: 3 }}
                style={{
                  maxHeight: rem(250),
                }}
              >
                <ScrollArea h={800} pr={3}>
                  <NavGroup
                    recommendations={
                      action_plan.recommendations[selectedOption]
                    }
                    value={selectedActionDetail}
                    onChange={index => setSelectedActionDetail(index)}
                  />
                </ScrollArea>
              </Grid.Col>
              <Grid.Col span={{ base: 12, sm: 9 }}>
                <ActionPlanContent action={selectedActionDetail} />
              </Grid.Col>
            </Grid>
          ) : (
            <Stack>
              <Image mah={400} fit="contain" src={congratulations} />
              <Title order={4} ta="center">
                {t('action_plan.no_plan.header')}
              </Title>
              <Box
                ta="center"
                w="100%"
                maw={500}
                p="xl"
                style={{
                  alignSelf: 'center',
                }}
              >
                <Text c="dimmed" ta="center" component="span">
                  {t('action_plan.no_plan.tagline', {
                    category: selectedOption,
                  })}
                </Text>
              </Box>
            </Stack>
          )}
        </Stack>
      </Container>
    );
  }
  return (
    <Text p="xl" ta="center">
      {t('action_plan.no_plan.no_plan_found')}
    </Text>
  );
}
